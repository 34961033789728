import { Component } from "react";

import {
  BattingAttackData,
  BattingAttackModuleType,
  battingAttackDataProperties,
  battingAttackModuleNames,
} from "../../types/simulator/modules/batting-attack-modules";
import { propsEqual } from "../component-utils";
import { NumberSelector } from "../entity-management/entity-selectors/number-selector";
import { CreationDialog } from "../my-matches/match-creation-modals/creation-dialog";

interface Props {
  open: boolean;
  moduleType: BattingAttackModuleType;
  initialModuleData: BattingAttackData;
  onCancel: () => void;
  onProceed: (
    moduleData: BattingAttackData,
    moduleType: BattingAttackModuleType
  ) => void;
}

interface State {
  moduleData: BattingAttackData;
  propertiesValid: any;
}

export class BattingAttackModuleSettingsModal extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      moduleData: null,
      propertiesValid: {},
    };
  }

  componentDidMount(): void {
    this.updateSettings();
  }

  componentDidUpdate(prevProps): void {
    if (!propsEqual(prevProps, this.props)) {
      this.updateSettings();
    }
  }

  private updateSettings() {
    this.setState({
      moduleData: this.cloneModuleData(this.props.initialModuleData),
      propertiesValid: {},
    });
  }

  private cloneModuleData(
    initialModuleData: BattingAttackData
  ): BattingAttackData {
    return JSON.parse(JSON.stringify(initialModuleData));
  }

  private invalid(): boolean {
    return !Object.values(this.state.propertiesValid).every((value) => value);
  }

  public render() {
    return (
      <CreationDialog
        open={this.props.open}
        label={battingAttackModuleNames[this.props.moduleType] + " settings"}
        invalid={this.invalid()}
        disabled={false}
        onCancel={() => this.props.onCancel()}
        onProceed={() =>
          this.props.onProceed(this.state.moduleData, this.props.moduleType)
        }
        proceedText="OK"
        colour="#535455"
      >
        {this.state.moduleData && (
          <div>
            {battingAttackDataProperties[this.props.moduleType].map(
              (property) => {
                if (property.type === "number") {
                  return (
                    <div key={property.propertyName}>
                      <NumberSelector
                        label={property.humanReadableName}
                        min={property.constraints.min}
                        max={property.constraints.max}
                        step={property.constraints.step}
                        decimalPlaces={property.constraints.decimalPlaces}
                        initial={this.state.moduleData[property.propertyName]}
                        onValid={(valid) =>
                          this.setState({
                            moduleData: {
                              ...this.state.moduleData,
                              [property.propertyName]: valid,
                            },
                            propertiesValid: {
                              ...this.state.propertiesValid,
                              [property.propertyName]: true,
                            },
                          })
                        }
                        onInvalid={() =>
                          this.setState({
                            propertiesValid: {
                              ...this.state.propertiesValid,
                              [property.propertyName]: false,
                            },
                          })
                        }
                        className="simulator-number-selector"
                      />
                      <div className="italic module-description">
                        {property.description}
                      </div>
                    </div>
                  );
                } else {
                  return <div></div>;
                }
              }
            )}
          </div>
        )}
      </CreationDialog>
    );
  }
}
