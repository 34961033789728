import {
  InningsRunsBetData,
  MatchOddsBetData,
} from "../../types/stored-bets/bet-data";
import { BetType } from "../../types/stored-bets/bet-type";
import { StoredBet } from "../../types/stored-bets/stored-bet";
import { StoredGameStateOdds } from "../../types/stored-bets/stored-odds";
import { UUID } from "../../types/uuid";
import { format } from "../simulator-page/simulator-utils";

export function createCSVFilename(): string {
  return `my-bets-${new Date().toLocaleString()}.csv`;
}

const headers: string[] = [
  "Market Name",
  "Match Name",
  "Placed Time",
  "Matched Time",
  "Matched Odds",
  "Sim Odds",
  "Original Stake",
  "Matched Stake",
  "Source",
  "Bet Direction",
  "Bet Result",
  "Bet Type",
  "Team Name",
  "Innings",
  "Runs",
  "Wickets",
  "Over Low",
  "Over High",
  "Player Name",
  "Balls Faced",
  "Boundaries",
  "Tied",
];

function getUserOdds(
  matchId: UUID,
  bet: StoredBet,
  simOdds: { [matchId: string]: StoredGameStateOdds[] }
): number {
  if (!matchId || !simOdds) {
    return null;
  }

  const gameStateOdds: StoredGameStateOdds = simOdds[matchId.value]?.find(
    (odds: StoredGameStateOdds) =>
      odds.matchId === matchId.value &&
      odds.gameStateId === bet.gameStateId.value
  );

  if (!gameStateOdds) {
    return null;
  }

  switch (bet.betType) {
    case BetType.MATCH_ODDS: {
      const teamId = (bet.betData as MatchOddsBetData).teamId;
      return gameStateOdds.simulatorMatchOdds?.find(
        (matchOdds) => matchOdds.teamId === teamId.value
      ).price;
    }
    case BetType.INNINGS_RUNS: {
      const inningsRunsBetData = bet.betData as InningsRunsBetData;
      const innings = inningsRunsBetData.innings;
      return gameStateOdds.simulatorRunsLineOdds
        ?.get(innings)
        .find((runsLineOdds) => runsLineOdds.overs === 20).price;
    }
    default:
      return null;
  }
}

export function createCSVContent(
  bets: {
    [matchId: string]: StoredBet[];
  },
  simOdds: { [matchId: string]: StoredGameStateOdds[] }
): string {
  let csvContent: string = headers.join(",") + "\n";

  const rows = Object.keys(bets).flatMap((sourceEventId) =>
    bets[sourceEventId].map((bet) => {
      const {
        marketName,
        matchName,
        placedTime,
        matchedTime,
        odds,
        originalStake,
        matchedStake,
        source,
        betDirection,
        betResult,
        betType,
        betData,
      } = bet;

      const userOdds = getUserOdds(bet.matchId, bet, simOdds);

      const formattedUserOdds = format(userOdds) || "";
      const formattedBetOdds = format(odds) || "";
      const formattedOriginalStake = format(originalStake) || "";
      const formattedMatchedStake = format(matchedStake) || "";

      const row = [
        marketName,
        matchName,
        new Date(placedTime).toISOString(),
        new Date(matchedTime).toISOString(),
        formattedBetOdds.replace(",", ""),
        formattedUserOdds.replace(",", ""),
        formattedOriginalStake.replace(",", ""),
        formattedMatchedStake.replace(",", ""),
        source,
        betDirection,
        betResult,
        betType,
        (betData as any)?.teamName,
        (betData as any)?.innings,
        (betData as any)?.runs,
        (betData as any)?.wickets,
        (betData as any)?.overLow,
        (betData as any)?.overHigh,
        (betData as any)?.playerName,
        (betData as any)?.ballsFaced,
        (betData as any)?.boundaries,
        (betData as any)?.tied,
      ];

      return row.join(",");
    })
  );

  csvContent += rows.join("\n") + "\n";

  return csvContent;
}
