import { Component, ReactNode } from "react";
import { Subscription } from "rxjs";
import {
  BetfairPrice,
  MarketType,
} from "../../../types/betfair/betfair-message";
import { GameState } from "../../../types/entities/game-state";
import { services } from "../../../types/services";
import TooltipIconButton from "../../navigation-bar/tooltip-icon-button";

interface Props {
  onShowMarketHistory: (marketType: MarketType, selection: string) => void;
}

interface State {
  latestPrices: Map<MarketType, Map<string, BetfairPrice>>;
  innings: number;
}

export class BetfairPriceDisplay extends Component<Props, State> {
  private subscriptions: Subscription[];

  constructor(props) {
    super(props);
    this.subscriptions = [];
    this.state = {
      innings: null,
      latestPrices: null,
    };
  }

  componentDidMount(): void {
    this.subscriptions.push(
      services.currentGameService.currentStateSubject.subscribe(
        (state: GameState) => {
          if (!!state) {
            this.setState({ innings: state.innings });
          }
        }
      )
    );
    this.subscriptions.push(
      services.betfairService.priceSubject.subscribe(
        (latestPrices: Map<MarketType, Map<string, BetfairPrice>>) => {
          this.setState({ latestPrices });
        }
      )
    );
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }

  private buildMarketDisplay(marketType: MarketType): ReactNode {
    const marketOddsRows: ReactNode[] = [];
    if (!!this.state.latestPrices) {
      const marketOdds = this.state.latestPrices.get(marketType);
      if (!!marketOdds) {
        const keys: string[] = [...marketOdds.keys()];
        keys.sort();
        for (let selection of keys) {
          const price = marketOdds.get(selection);
          marketOddsRows.push(
            <div className="betfair-price-row" key={`${selection}`}>
              <div className="betfair-selection">{selection}</div>
              <div className="betfair-back">
                <div className="betfair-price">
                  {this.formatPrice(1 / price.backPrice)}
                </div>
                <div className="betfair-size">
                  {this.formatSize(price.backSize)}
                </div>
              </div>
              <div className="betfair-lay">
                <div className="betfair-price">
                  {this.formatPrice(1 / price.layPrice)}
                </div>
                <div className="betfair-size">
                  {this.formatSize(price.laySize)}
                </div>
              </div>
              <div>
                <TooltipIconButton
                  onClick={() =>
                    this.props.onShowMarketHistory(marketType, selection)
                  }
                  title={"Display market history"}
                  disabled={false}
                  icon="timeline"
                />
              </div>
            </div>
          );
        }
      } else {
        marketOddsRows.push(<div className="italic">No markets available</div>);
      }
    }

    return <div className="betfair-market">{marketOddsRows}</div>;
  }

  private formatPrice(price: number): string {
    if (Number.isNaN(price) || !Number.isFinite(price)) {
      return "";
    }
    return (
      price &&
      price.toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })
    );
  }

  private formatSize(size: number): string {
    if (size > 0) {
      return (
        "£" +
        size.toLocaleString("en-US", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        })
      );
    }
    return "";
  }

  public render() {
    const matchOdds = this.buildMarketDisplay(MarketType.MATCH_ODDS);
    const runsLine1 = this.buildMarketDisplay(MarketType.INNINGS_RUNS_1);
    const runsLine2 = this.buildMarketDisplay(MarketType.INNINGS_RUNS_2);
    const runsLine1Powerplay = this.buildMarketDisplay(
      MarketType.POWERPLAY_RUNS_1
    );
    const runsLine2Powerplay = this.buildMarketDisplay(
      MarketType.POWERPLAY_RUNS_2
    );
    return (
      <div className="betfair-display">
        <div className="separator-title">Prices:</div>
        {this.state.latestPrices && this.state.innings && (
          <div>
            <div className="separator-title">Match Odds</div>
            {matchOdds}
            <div className="separator-title">20 Overs Runs Line</div>
            {this.state.innings === 1
              ? runsLine1
              : this.state.innings === 2
              ? runsLine2
              : null}
            <div className="separator-title">6 Overs Runs Line</div>
            {this.state.innings === 1
              ? runsLine1Powerplay
              : this.state.innings === 2
              ? runsLine2Powerplay
              : null}
          </div>
        )}
      </div>
    );
  }
}
