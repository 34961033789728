import { Button } from "@mui/material";
import { Component } from "react";
import { Subscription } from "rxjs";

import { AutoBallEntryMessage } from "../../types/betfair/bowl-entry";
import { GameState } from "../../types/entities/game-state";
import { Ground } from "../../types/entities/ground";
import { Match } from "../../types/entities/match";
import { MatchFormat } from "../../types/entities/match-format";
import { MatchInfo } from "../../types/entities/match-info";
import { Squad } from "../../types/entities/squad";
import { Team } from "../../types/entities/team";
import {
  BetfairSubscription,
  SubscriptionStatus,
} from "../../types/enums/betfair-status";
import { TossChoice } from "../../types/enums/toss-choice";
import { BetfairRoute, UserRoute } from "../../types/route-helpers";
import { services } from "../../types/services";
import { TossSelector } from "../entity-management/entity-selectors/toss-selector";
import { StatsLoadingPage } from "../stats-loading-page/stats-loading-page";
import { BowlInput } from "./bowl-input/bowl-input";
import { EventDisplay } from "./event-display/event-display";
import { GameStateDisplay } from "./game-state-display/game-state-display";
import { GameTitle } from "./game-state-display/game-title";
import { ScorecardDisplay } from "./scorecard/scorecard-display";

interface Props {}

interface State {
  match: Match;
  team1: Team;
  team2: Team;
  gameState: GameState;
  matchInfo: MatchInfo;
  squad1: Squad;
  squad2: Squad;
  matchFormat: MatchFormat;
  ground: Ground;
  tossChoiceModalOpen: boolean;
  scorecardModalOpen: boolean;
  bowlInputModalOpen: boolean;
  subscription: BetfairSubscription;
  latestAutoBallEntryMessage: AutoBallEntryMessage;
}

export class MatchPage extends Component<Props, State> {
  private subscriptions: Subscription[];
  private static readonly DEFAULT_STATE = {
    match: null,
    team1: null,
    team2: null,
    gameState: null,
    matchInfo: null,
    squad1: null,
    squad2: null,
    matchFormat: null,
    ground: null,
    tossChoiceModalOpen: false,
    bowlInputModalOpen: false,
    scorecardModalOpen: false,
    subscription: null,
    latestAutoBallEntryMessage: null,
    images: new Map(),
  };

  constructor(props) {
    super(props);
    this.subscriptions = [];
    this.state = {
      ...MatchPage.DEFAULT_STATE,
    };
  }

  componentDidMount() {
    this.subscriptions.push(
      services.currentGameService.currentMatchSubject.subscribe(
        (currentMatch: Match) => this.setState({ match: currentMatch })
      )
    );

    this.subscriptions.push(
      services.currentGameService.groundSubject.subscribe((ground: Ground) =>
        this.setState({ ground })
      )
    );

    this.subscriptions.push(
      services.currentGameService.squadsSubject.subscribe(
        ([squad1, squad2]: Squad[]) => this.setState({ squad1, squad2 })
      )
    );

    this.subscriptions.push(
      services.currentGameService.teamsSubject.subscribe(
        ([team1, team2]: Team[]) => this.setState({ team1, team2 })
      )
    );

    this.subscriptions.push(
      services.currentGameService.currentStateSubject.subscribe(
        (currentState: GameState) => this.setState({ gameState: currentState })
      )
    );

    this.subscriptions.push(
      services.currentGameService.currentInfoSubject.subscribe(
        (matchInfo: MatchInfo) => this.setState({ matchInfo })
      )
    );

    this.subscriptions.push(
      services.currentGameService.currentMatchFormatSubject.subscribe(
        (matchFormat: MatchFormat) => this.setState({ matchFormat })
      )
    );

    this.subscriptions.push(
      services.currentGameService.currentSubscriptionSubject.subscribe(
        (subscription: BetfairSubscription) => this.setState({ subscription })
      )
    );

    this.subscriptions.push(
      services.betfairService.latestAutoBallEntryMessageSubject.subscribe(
        (latestAutoBallEntryMessage: AutoBallEntryMessage) =>
          this.setState({ latestAutoBallEntryMessage })
      )
    );
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  public render() {
    const live =
      this.state.subscription &&
      this.state.subscription.status === SubscriptionStatus.LIVE;
    return (
      <UserRoute>
        <div
          className={`full-push-background-light with-navbar ${
            this.state.match ? "match-page" : ""
          }`}
        >
          {this.state.gameState && this.state.gameState.initialStatsLoaded && (
            <div>
              <div className="game-control-and-info-container">
                <GameTitle
                  matchInfo={this.state.matchInfo}
                  ground={this.state.ground}
                />

                <hr />

                <GameStateDisplay
                  match={this.state.match}
                  gameState={this.state.gameState}
                  matchInfo={this.state.matchInfo}
                  matchFormat={this.state.matchFormat}
                />

                {this.state.gameState && !this.state.gameState.started && (
                  <div className="match-info-contents">
                    <Button
                      variant="contained"
                      onClick={() => this.openTossModal()}
                    >
                      Start Game!
                    </Button>

                    <TossSelector
                      open={this.state.tossChoiceModalOpen}
                      onCancel={() =>
                        this.setState({ tossChoiceModalOpen: false })
                      }
                      onProceed={(tossWinner: Team, decision: TossChoice) =>
                        this.onTossDecided(tossWinner, decision)
                      }
                      match={this.state.match}
                      team1={this.state.team1}
                      team2={this.state.team2}
                    />
                  </div>
                )}

                <div>
                  {this.state.gameState &&
                    this.state.gameState.eventSequence > 0 && (
                      <div>
                        <hr />
                        <BowlInput
                          disabled={
                            this.state.gameState === null ||
                            this.state.gameState.batsman1 === null ||
                            this.state.gameState.batsman2 === null ||
                            this.state.gameState.bowler === null ||
                            this.state.scorecardModalOpen ||
                            this.state.tossChoiceModalOpen ||
                            (!!this.state.subscription &&
                              this.state.subscription.status ===
                                SubscriptionStatus.LIVE)
                          }
                          gameState={this.state.gameState}
                          matchFormat={this.state.matchFormat}
                          squad1={this.state.squad1}
                          squad2={this.state.squad2}
                          team1={this.state.team1}
                          team2={this.state.team2}
                          onModalOpen={() =>
                            this.setState({ bowlInputModalOpen: true })
                          }
                          onModalClose={() =>
                            this.setState({ bowlInputModalOpen: false })
                          }
                        />
                      </div>
                    )}
                </div>

                <BetfairRoute>
                  {this.state.gameState &&
                    this.state.gameState.eventSequence > 0 &&
                    !!this.state.subscription && (
                      <div className="betfair-subscription-toggle">
                        <hr />
                        <Button
                          disabled={false}
                          variant="contained"
                          onClick={() => services.betfairService.togglePause()}
                          color={live ? "primary" : "secondary"}
                        >
                          {live ? `Pause` : `Enable`} Betfair API Input
                        </Button>
                        {!!this.state.latestAutoBallEntryMessage && live && (
                          <div
                            className={
                              this.state.latestAutoBallEntryMessage.severity ===
                              "WARN"
                                ? "blink"
                                : ""
                            }
                          >
                            {this.state.latestAutoBallEntryMessage.message}
                          </div>
                        )}
                      </div>
                    )}
                </BetfairRoute>
              </div>
            </div>
          )}
          {this.state.gameState && this.state.gameState.started && (
            <ScorecardDisplay
              gameState={this.state.gameState}
              matchInfo={this.state.matchInfo}
              squad1={this.state.squad1}
              squad2={this.state.squad2}
              matchFormat={this.state.matchFormat}
              match={this.state.match}
              team1={this.state.team1}
              team2={this.state.team2}
              ground={this.state.ground}
              onModalOpen={() => this.setState({ scorecardModalOpen: true })}
              onModalClose={() => this.setState({ scorecardModalOpen: false })}
              externalModalOpen={
                this.state.bowlInputModalOpen || this.state.tossChoiceModalOpen
              }
            />
          )}
          {this.state.match && (
            <div className="event-display">
              <EventDisplay
                gameState={this.state.gameState}
                squad1={this.state.squad1}
                squad2={this.state.squad2}
                team1={this.state.team1}
                team2={this.state.team2}
                matchFormat={this.state.matchFormat}
                match={this.state.match}
                matchInfo={this.state.matchInfo}
              />
            </div>
          )}
          {!!this.state.gameState &&
            !this.state.gameState.initialStatsLoaded && <StatsLoadingPage />}
          {!this.state.gameState && (
            <div className="page-title">No match selected</div>
          )}
        </div>
      </UserRoute>
    );
  }

  private openTossModal(): void {
    this.setState({ tossChoiceModalOpen: true });
  }

  private onTossDecided(tossWinner: Team, decision: TossChoice): void {
    services.currentGameService.addTossInfo(tossWinner, decision).then(() => {
      this.setState({ tossChoiceModalOpen: false });
    });
  }
}
