import { Component } from "react";
import { Ball } from "../../types/entities/ball";
import { GameState } from "../../types/entities/game-state";
import { Ground } from "../../types/entities/ground";
import { Match } from "../../types/entities/match";
import { MatchFormat } from "../../types/entities/match-format";
import { Team } from "../../types/entities/team";
import {
  SimulationResult,
  SimulatorRecordedBowl,
} from "../../types/simulator/simulation-result";
import { Squad } from "../../types/entities/squad";
import { NumberSelector } from "../entity-management/entity-selectors/number-selector";
import { EndOfOverDisplay } from "../match-page/event-display/end-of-over-display";
import { InningsSummary } from "../match-page/game-state-display/innings-summary";
import { ScorecardDisplay } from "../match-page/scorecard/scorecard-display";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import { BowlerListModal } from "./bowler-list-modal";
import { SimulationBallDisplay } from "./simulation-ball-display";

interface Props {
  latestResult: SimulationResult;
  squad1: Squad;
  squad2: Squad;
  team1: Team;
  team2: Team;
  matchFormat: MatchFormat;
  match: Match;
  ground: Ground;
  collapsed: boolean;
  toggleCollapse: () => void;
}

interface State {
  simulation: number;
  bowlerListModalOpen: boolean;
}

export class RecordedSimulationsDisplay extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      simulation: 0,
      bowlerListModalOpen: false,
    };
  }

  public render() {
    let currentInnings = -1;
    let currentOver = -1;
    let squad1: Squad = null;
    let squad2: Squad = null;
    let team1: Team = null;
    let team2: Team = null;
    let finalState: GameState = null;

    if (
      this.props.latestResult &&
      this.props.latestResult.recordedSimulations &&
      this.props.latestResult.recordedSimulations.length > 0 &&
      this.props.latestResult.recordedFinalStates &&
      this.props.latestResult.recordedFinalStates.length > 0
    ) {
      finalState =
        this.props.latestResult.recordedFinalStates[this.state.simulation];
      const team1Number =
        finalState.squads[0][0].teamId.value === this.props.team1.teamId.value
          ? 1
          : 2;
      squad1 = team1Number === 1 ? this.props.squad1 : this.props.squad2;
      squad2 = team1Number === 1 ? this.props.squad2 : this.props.squad1;
      team1 = team1Number === 1 ? this.props.team1 : this.props.team2;
      team2 = team1Number === 1 ? this.props.team2 : this.props.team1;
    }

    return (
      <div className="simulator-container">
        <div className="simulator-title">
          Recorded Simulations
          <TooltipIconButton
            icon={this.props.collapsed ? "expand_more" : "expand_less"}
            onClick={() => this.props.toggleCollapse()}
            title={this.props.collapsed ? "Expand" : "Collapse"}
          />
        </div>
        {team1 &&
          team2 &&
          squad1 &&
          squad2 &&
          !this.props.collapsed &&
          this.props.matchFormat && (
            <div>
              <div className="recorded-simulation-button-container">
                <NumberSelector
                  label={"Simulation:"}
                  className="recorded-simulation-number-selector"
                  min={1}
                  max={this.props.latestResult.recordedSimulations.length}
                  initial={1}
                  onValid={(newValue) =>
                    this.setState({ simulation: newValue - 1 })
                  }
                  disabled={!this.props.latestResult}
                />
                <TooltipIconButton
                  icon="list"
                  onClick={() => this.setState({ bowlerListModalOpen: true })}
                  title="Bowler List"
                />
              </div>

              <hr />

              <div className="recorded-simulation-innings-summary">
                <div className="innings-summary">
                  {team1.name}:
                  <InningsSummary
                    data={finalState.calculateInningsSummary(
                      1,
                      this.props.matchFormat
                    )}
                  />
                </div>
                <div className="innings-summary">
                  {team2.name}:
                  <InningsSummary
                    data={finalState.calculateInningsSummary(
                      2,
                      this.props.matchFormat
                    )}
                  />
                </div>
              </div>

              <div className="recorded-simulation-scroll">
                <div className="recorded-simulation-sticky">
                  <div className={"simulation-number"}>
                    <div>
                      <div className="end-of-over-text-title">Simulation</div>
                      <div className="end-of-over-text">
                        {this.state.simulation + 1}
                      </div>
                    </div>
                  </div>
                  <div className="simulation-ball-display-stats-title">
                    Push:
                  </div>
                  <div className="simulation-ball-display-stats-title">
                    Adj. Push:
                  </div>
                  <div className="simulation-ball-display-stats-title">
                    SR Bias:
                  </div>
                  <div className="simulation-ball-display-stats-title">
                    Strike Rate:
                  </div>
                  <div className="simulation-ball-display-stats-title">
                    Wicket Bias:
                  </div>
                  <div className="simulation-ball-display-stats-title">
                    Wicket %:
                  </div>
                  <div className="simulation-ball-display-stats-title">
                    Desperate Run?:
                  </div>
                </div>

                {this.props.latestResult.recordedSimulations[
                  this.state.simulation
                ].map((recordedBall: SimulatorRecordedBowl, index: number) => {
                  return (
                    <div
                      className="recorded-simulation-display"
                      key={`Simulation-${this.state.simulation}-Recorded-Ball-${index}`}
                    >
                      {recordedBall.balls.map((ball: Ball, index: number) => {
                        const thisOver = Number(ball.over.split(".")[0]);
                        let items = [];
                        if (ball.innings !== currentInnings) {
                          currentInnings = ball.innings;
                          items.push(
                            <div key={`Ball-${ball.overUnique}-innings-marker`}>
                              <EndOfOverDisplay
                                endOfInnings={true}
                                event={ball}
                                overSummary={null}
                              />
                            </div>
                          );
                        }
                        if (thisOver !== currentOver) {
                          currentOver = thisOver;
                          items.push(
                            <div key={`Ball-${ball.overUnique}-over-marker`}>
                              <EndOfOverDisplay
                                endOfInnings={false}
                                event={ball}
                                overSummary={
                                  !!finalState &&
                                  finalState.getOverSummary(
                                    ball.innings,
                                    thisOver,
                                    this.props.matchFormat
                                  )
                                }
                                bowlerChoice={recordedBall.bowlerChoice}
                                squad1={this.props.squad1}
                                squad2={this.props.squad2}
                              />
                            </div>
                          );
                        }
                        items.push(
                          <div key={`Ball-${ball.overUnique}-ball-info`}>
                            <SimulationBallDisplay
                              recordedBall={recordedBall}
                              ball={ball}
                              overSummary={
                                !!finalState &&
                                finalState.getOverSummary(
                                  ball.innings,
                                  thisOver,
                                  this.props.matchFormat
                                )
                              }
                              overToggle={false}
                              squad1={this.props.squad1}
                              squad2={this.props.squad2}
                              matchFormat={this.props.matchFormat}
                            />
                          </div>
                        );
                        return (
                          <div
                            key={`Ball-${ball.overUnique}`}
                            className="recorded-simulation-display"
                          >
                            {items}
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>

              <div>
                <ScorecardDisplay
                  gameState={finalState}
                  squad1={squad1}
                  squad2={squad2}
                  team1={team1}
                  team2={team2}
                  matchFormat={this.props.matchFormat}
                  match={this.props.match}
                  ground={this.props.ground}
                  matchInfo={null}
                  onModalOpen={() => {}}
                  onModalClose={() => {}}
                  externalModalOpen={false}
                  allowToggleView={false}
                  allowPrint={false}
                  fullWidth={true}
                />
              </div>
            </div>
          )}
        <BowlerListModal
          gameState={finalState}
          squad1={squad1}
          squad2={squad2}
          team1={team1}
          team2={team2}
          matchFormat={this.props.matchFormat}
          match={this.props.match}
          open={this.state.bowlerListModalOpen}
          onClose={() => this.setState({ bowlerListModalOpen: false })}
        />
      </div>
    );
  }
}
