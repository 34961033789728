import React, { ReactNode } from "react";

import { GameState } from "../../types/entities/game-state";
import { MatchFormat } from "../../types/entities/match-format";
import { Squad } from "../../types/entities/squad";
import { Team } from "../../types/entities/team";
import {
  InningsSimulationOddsQuestion,
  MultiSimulationOddsQuestion,
  PlayerSimulationOddsQuestion,
  SimulationQuestionType,
  questionTypesStringToEnum,
  toHashCode,
} from "../../types/simulator/questions/simulation-odds-question";
import { createDefaultQuestionOfType } from "../../types/simulator/questions/stored-questions";
import { UUID } from "../../types/uuid";
import { NumberSelector } from "../entity-management/entity-selectors/number-selector";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";

import { InningsStoredQuestionDisplay } from "./innings-stored-question-display";
import PlayerStoredQuestionDisplay from "./player-stored-question-display";
import { StoredQuestionOddsDisplay } from "./stored-question-odds-display";

interface MultiProps {
  storedQuestionId: UUID;
  question: MultiSimulationOddsQuestion;
  editing: boolean;
  squad1: Squad;
  squad2: Squad;
  team1: Team;
  team2: Team;
  matchFormat: MatchFormat;
  gameState: GameState;
  odds: number;
  edge: number;
  onUpdate: (question: MultiSimulationOddsQuestion) => void;
  setEditing: (editing: boolean) => void;
  onDelete: (storedQuestionId: UUID) => void;
}

export function MultiStoredQuestionDisplay({
  storedQuestionId,
  question,
  editing,
  team1,
  team2,
  squad1,
  squad2,
  matchFormat,
  gameState,
  odds,
  edge,
  onUpdate,
  setEditing,
  onDelete,
}: MultiProps): React.JSX.Element {
  return (
    <div
      className="contained column"
      style={{
        gap: "10px",
        padding: "0px",
      }}
    >
      <div
        className="header"
        style={{
          backgroundColor: "rgba(100, 200, 300, 0.5)",
          borderRadius: "5px 5px 0px 0px",
          padding: "10px",
        }}
      >
        <div>Multi Question</div>
        <div className="stored-question-buttons">
          {editing && (
            <>
              <div className="compare-user-select-light">
                Number of questions to count:
                <NumberSelector
                  label={""}
                  className="medium-number-selector"
                  min={0}
                  max={question.questions.length}
                  initial={question.correctQuestionsToApply}
                  onValid={(value) => {
                    question.correctQuestionsToApply = value;
                    onUpdate(question);
                  }}
                />
              </div>
              <TooltipIconButton
                title={"Add"}
                icon={"add_circle"}
                onClick={() => {
                  question.correctQuestionsToApply++;
                  question.questions.push(
                    createDefaultQuestionOfType(
                      SimulationQuestionType.INNINGS,
                      squad1
                    )
                  );
                  onUpdate(question);
                }}
              />
            </>
          )}
          <TooltipIconButton
            title={editing ? "Save" : "Edit"}
            icon={editing ? "save_circle" : "edit_circle"}
            onClick={() => setEditing(!editing)}
          />
          <TooltipIconButton
            title={"Delete"}
            icon={"delete_circle"}
            onClick={() => onDelete(storedQuestionId)}
          />
        </div>
      </div>
      {question.questions.length === 0 && editing && (
        <div
          className="italic row padded"
          style={{
            gap: "10px",
          }}
        >
          No questions added, click{" "}
          <TooltipIconButton
            title={"Add"}
            icon={"add_circle"}
            onClick={() => {
              question.correctQuestionsToApply++;
              question.questions.push(
                createDefaultQuestionOfType(
                  SimulationQuestionType.INNINGS,
                  squad1
                )
              );
              onUpdate(question);
            }}
          />{" "}
          to add one.
        </div>
      )}
      {!editing &&
        question.questions.length !== question.correctQuestionsToApply && (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: editing ? "center" : "left",
              paddingLeft: editing ? "0px" : "10px",
              backgroundColor: "rgba(100, 200, 300, 0.2)",
            }}
          >
            AT LEAST {question.correctQuestionsToApply} OF
          </div>
        )}
      {question.questions.map((q, index) => {
        const children: ReactNode[] = [];
        index !== 0 &&
          children.push(
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: editing ? "center" : "left",
                paddingLeft: editing ? "0px" : "10px",
                backgroundColor: "rgba(100, 200, 300, 0.2)",
              }}
              key={`${index}`}
            >
              AND
            </div>
          );
        switch (questionTypesStringToEnum[q.subType]) {
          case SimulationQuestionType.MULTI:
            children.push(
              <div key={toHashCode(question)}>
                Multi question not allowed in multi!
              </div>
            );
            break;
          case SimulationQuestionType.INNINGS:
            children.push(
              <InningsStoredQuestionDisplay
                key={toHashCode(question)}
                storedQuestionId={storedQuestionId}
                question={q as InningsSimulationOddsQuestion}
                editing={editing}
                squad1={squad1}
                team1={team1}
                team2={team2}
                matchFormat={matchFormat}
                gameState={gameState}
                standalone={false}
                onUpdate={(editedQuestion) => {
                  question.questions[index] = editedQuestion;
                  onUpdate(question);
                }}
                setEditing={() => setEditing(!editing)}
                onDelete={() => {
                  question.correctQuestionsToApply--;
                  question.questions.splice(index, 1);
                  onUpdate(question);
                }}
                odds={null}
                edge={edge}
              />
            );
            break;
          case SimulationQuestionType.PLAYER:
            children.push(
              <PlayerStoredQuestionDisplay
                key={toHashCode(question)}
                storedQuestionId={storedQuestionId}
                question={q as PlayerSimulationOddsQuestion}
                editing={editing}
                squad1={squad1}
                squad2={squad2}
                standalone={false}
                onUpdate={(editedQuestion) => {
                  question.questions[index] = editedQuestion;
                  onUpdate(question);
                }}
                setEditing={() => setEditing(!editing)}
                onDelete={() => {
                  question.correctQuestionsToApply--;
                  question.questions.splice(index, 1);
                  onUpdate(question);
                }}
                odds={null}
                edge={edge}
              />
            );
            break;
          default:
            children.push(
              <div key={toHashCode(question)}>Unknown question type!</div>
            );
        }
        return children;
      })}
      {<StoredQuestionOddsDisplay odds={odds} edge={edge} />}
    </div>
  );
}
