export enum PushBracket {
  BLOCKING = "BLOCKING",
  DEFENSIVE = "DEFENSIVE",
  NORMAL = "NORMAL",
  PUSHING = "PUSHING",
  FULL_PUSH = "FULL_PUSH",
}

export const humanReadablePushBrackets: Record<PushBracket, string> = {
  BLOCKING: "Blocking",
  DEFENSIVE: "Defensive",
  NORMAL: "Normal",
  PUSHING: "Pushing",
  FULL_PUSH: "Full Push",
};
