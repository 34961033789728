import { Component } from "react";
import { closeConfirmationDialog } from "../../types/services";
import { CreationDialog } from "../my-matches/match-creation-modals/creation-dialog";

export interface ConfirmationDialogProps {
  open: boolean;
  onProceed: () => void;
  title: string;
  text: string;
}

export class ConfirmationDialog extends Component<ConfirmationDialogProps, {}> {
  public render() {
    return (
      <CreationDialog
        open={this.props.open}
        label={this.props.title}
        invalid={false}
        disabled={false}
        onCancel={() => closeConfirmationDialog()}
        onProceed={() => {
          this.props.onProceed();
          closeConfirmationDialog();
        }}
        cancelText="NO"
        proceedText="YES"
        colour="#c83937"
      >
        <div className="confirmation-modal-content">{this.props.text}</div>
      </CreationDialog>
    );
  }
}
