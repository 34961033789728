import { PlayerStats } from "../../types/stats/player-stats";

export enum StealAllPage {
  PLAYER_BATTING_STATS = "PLAYER_BATTING_STATS",
  PLAYER_BOWLING_STATS = "PLAYER_BOWLING_STATS",
  BOWLING_ALGORITHM_MODAL = "BOWLING_ALGORITHM_MODAL",
  UNSTEALABLE = "UNSTEALABLE",
}

export const stealAllPropertyRecord: Record<keyof PlayerStats, StealAllPage> = {
  defensiveStrikeRate: StealAllPage.PLAYER_BATTING_STATS,
  halfInStrikeRate: StealAllPage.PLAYER_BATTING_STATS,
  inStrikeRate: StealAllPage.PLAYER_BATTING_STATS,
  maxStrikeRate: StealAllPage.PLAYER_BATTING_STATS,
  ballsUntilHalfIn: StealAllPage.PLAYER_BATTING_STATS,
  ballsUntilFullyIn: StealAllPage.PLAYER_BATTING_STATS,
  defensiveWicketPercent: StealAllPage.PLAYER_BATTING_STATS,
  inWicketPercent: StealAllPage.PLAYER_BATTING_STATS,
  maxWicketPercent: StealAllPage.PLAYER_BATTING_STATS,
  strikeRateWicketMultiplier: StealAllPage.PLAYER_BATTING_STATS,
  extremePaceStrikeRateBias: StealAllPage.PLAYER_BATTING_STATS,
  normalPaceStrikeRateBias: StealAllPage.PLAYER_BATTING_STATS,
  wristSpinStrikeRateBias: StealAllPage.PLAYER_BATTING_STATS,
  intoSpinStrikeRateBias: StealAllPage.PLAYER_BATTING_STATS,
  awaySpinStrikeRateBias: StealAllPage.PLAYER_BATTING_STATS,
  extremePaceWicketBias: StealAllPage.PLAYER_BATTING_STATS,
  normalPaceWicketBias: StealAllPage.PLAYER_BATTING_STATS,
  wristSpinWicketBias: StealAllPage.PLAYER_BATTING_STATS,
  intoSpinWicketBias: StealAllPage.PLAYER_BATTING_STATS,
  awaySpinWicketBias: StealAllPage.PLAYER_BATTING_STATS,
  extremePaceConfidence: StealAllPage.PLAYER_BATTING_STATS,
  normalPaceConfidence: StealAllPage.PLAYER_BATTING_STATS,
  wristSpinConfidence: StealAllPage.PLAYER_BATTING_STATS,
  intoSpinConfidence: StealAllPage.PLAYER_BATTING_STATS,
  awaySpinConfidence: StealAllPage.PLAYER_BATTING_STATS,
  pushAdjustment: StealAllPage.PLAYER_BATTING_STATS,
  pushAdjustmentUpToBall: StealAllPage.PLAYER_BATTING_STATS,
  actualWicketMultiplier: StealAllPage.PLAYER_BATTING_STATS,
  widesMultiplier: StealAllPage.PLAYER_BATTING_STATS,
  averagePosition: StealAllPage.PLAYER_BATTING_STATS,
  battingGlobalStrikeRateBias: StealAllPage.PLAYER_BATTING_STATS,
  battingGlobalWicketBias: StealAllPage.PLAYER_BATTING_STATS,
  battingGlobalConfidence: StealAllPage.PLAYER_BATTING_STATS,
  battingExperience: StealAllPage.PLAYER_BATTING_STATS,
  strikeRateBiasByPush: StealAllPage.PLAYER_BATTING_STATS,
  wicketBiasByPush: StealAllPage.PLAYER_BATTING_STATS,
  confidenceByPush: StealAllPage.PLAYER_BATTING_STATS,
  aheadLimit: StealAllPage.PLAYER_BATTING_STATS,
  behindLimit: StealAllPage.PLAYER_BATTING_STATS,
  powerplayPushAdjustment: StealAllPage.PLAYER_BATTING_STATS,
  powerplayWicketBias: StealAllPage.PLAYER_BATTING_STATS,
  upToBalls: StealAllPage.PLAYER_BATTING_STATS,
  upToBallsStrikeRateBias: StealAllPage.PLAYER_BATTING_STATS,
  upToBallsWicketBias: StealAllPage.PLAYER_BATTING_STATS,
  upToBallsConfidence: StealAllPage.PLAYER_BATTING_STATS,

  globalPercentDistributionBiasData: StealAllPage.PLAYER_BATTING_STATS,
  pacePercentDistributionBiasData: StealAllPage.PLAYER_BATTING_STATS,
  spinPercentDistributionBiasData: StealAllPage.PLAYER_BATTING_STATS,
  percentDistributionBiasDataByPush: StealAllPage.PLAYER_BATTING_STATS,

  spinPower: StealAllPage.PLAYER_BOWLING_STATS,
  swingPower: StealAllPage.PLAYER_BOWLING_STATS,
  bowlingGlobalStrikeRateBias: StealAllPage.PLAYER_BOWLING_STATS,
  bowlingGlobalWicketBias: StealAllPage.PLAYER_BOWLING_STATS,
  bowlingGlobalExtrasBias: StealAllPage.PLAYER_BOWLING_STATS,
  bowlingGlobalConfidence: StealAllPage.PLAYER_BOWLING_STATS,
  bowlingExperience: StealAllPage.PLAYER_BOWLING_STATS,
  bowlingAgainstLeftHandedStrikeRateBias: StealAllPage.PLAYER_BOWLING_STATS,
  bowlingAgainstLeftHandedWicketBias: StealAllPage.PLAYER_BOWLING_STATS,
  bowlingAgainstLeftHandedConfidence: StealAllPage.PLAYER_BOWLING_STATS,
  bowlingAgainstRightHandedStrikeRateBias: StealAllPage.PLAYER_BOWLING_STATS,
  bowlingAgainstRightHandedWicketBias: StealAllPage.PLAYER_BOWLING_STATS,
  bowlingAgainstRightHandedConfidence: StealAllPage.PLAYER_BOWLING_STATS,
  bowlingStrikeRateBias: StealAllPage.PLAYER_BOWLING_STATS,
  bowlingWicketBias: StealAllPage.PLAYER_BOWLING_STATS,
  bowlingExtrasBias: StealAllPage.PLAYER_BOWLING_STATS,
  bowlingConfidence: StealAllPage.PLAYER_BOWLING_STATS,
  bowlingCallups: StealAllPage.PLAYER_BOWLING_STATS,

  bowlerSpecialityPercents: StealAllPage.BOWLING_ALGORITHM_MODAL,
  bowlerExtrasBiasesByType: StealAllPage.BOWLING_ALGORITHM_MODAL,
  desiredBalls: StealAllPage.BOWLING_ALGORITHM_MODAL,
  bowler: StealAllPage.BOWLING_ALGORITHM_MODAL,

  bowlingAverageGroundSRBias: StealAllPage.UNSTEALABLE,
  bowlingAverageGroundWicketBias: StealAllPage.UNSTEALABLE,
  bowlingAverageOpponentSRBias: StealAllPage.UNSTEALABLE,
  bowlingAverageOpponentWicketBias: StealAllPage.UNSTEALABLE,
  battingAverageGroundSRBias: StealAllPage.UNSTEALABLE,
  battingAverageGroundWicketBias: StealAllPage.UNSTEALABLE,
  battingAverageOpponentSRBias: StealAllPage.UNSTEALABLE,
  battingAverageOpponentWicketBias: StealAllPage.UNSTEALABLE,
};
