import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  Button,
  Slider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Component, Fragment, ReactNode } from "react";
import { Prompt } from "react-router-dom";

import { HistoricFormUpdateMessage } from "../../services/ground-stats-service";
import {
  HistoricPushMessage,
  PushBracketUpdateMessage,
} from "../../services/historic-push-service";
import { MultipleStageUpdateMessage } from "../../services/multiple-stage-update-service";
import {
  HistoricStatsUpdateMessage,
  StrikeRateAndWicketPercentResponse,
} from "../../services/player-stats-service";
import {
  MatchType,
  matchTypeDefaultMapNames,
  matchTypeMapNames,
  matchTypeNames,
} from "../../types/enums/match-type";
import { EmployeeRoute } from "../../types/route-helpers";
import { services } from "../../types/services";
import {
  PlayerStats,
  PlayerStatsWrapper,
} from "../../types/stats/player-stats";
import { calculateProgress, propsEqual } from "../component-utils";
import { EnumSelector } from "../entity-management/entity-selectors/enum-selector";
import { NumberSelector } from "../entity-management/entity-selectors/number-selector";
import { LinearProgressWithLabel } from "../historic-player-stats-page/all-player-stats-component";
import { CreationDialog } from "../my-matches/match-creation-modals/creation-dialog";
import { formatToDp } from "../simulator-page/simulator-utils";

import BallByBallChart from "./ball-by-ball-chart";

interface State {
  matchType: MatchType;
  t20Defaults: Map<number, PlayerStatsWrapper>;
  odiDefaults: Map<number, PlayerStatsWrapper>;
  t10Defaults: Map<number, PlayerStatsWrapper>;
  t20DefaultsWomen: Map<number, PlayerStatsWrapper>;
  odiDefaultsWomen: Map<number, PlayerStatsWrapper>;
  t10DefaultsWomen: Map<number, PlayerStatsWrapper>;
  t20DefaultsOriginal: Map<number, PlayerStatsWrapper>;
  odiDefaultsOriginal: Map<number, PlayerStatsWrapper>;
  t10DefaultsOriginal: Map<number, PlayerStatsWrapper>;
  t20DefaultsWomenOriginal: Map<number, PlayerStatsWrapper>;
  odiDefaultsWomenOriginal: Map<number, PlayerStatsWrapper>;
  t10DefaultsWomenOriginal: Map<number, PlayerStatsWrapper>;
  loading: boolean;
  isWarningModalOpen: boolean;
  latestUpdate: MultipleStageUpdateMessage;
  currentStage: number;
  totalStages: number;
  latestHistoricPushUpdate: HistoricPushMessage;
  latestPushBracketUpdate: PushBracketUpdateMessage;
  latestHistoricGroundFormUpdate: HistoricFormUpdateMessage;
  latestHistoricPlayerFormUpdate: HistoricStatsUpdateMessage;
  expandedRow: number | null;
  strikeRateData: { ball: number; value: number }[];
  wicketPercentData: { ball: number; value: number }[];
  aggression: number;
}

export class DefaultPlayerStatsPage extends Component<{}, State> {
  public static readonly DEFAULT_STATS_MAP_T20: Map<
    number,
    PlayerStatsWrapper
  > = PlayerStatsWrapper.buildDefaultStatsForT20();
  public static readonly DEFAULT_STATS_MAP_ODI: Map<
    number,
    PlayerStatsWrapper
  > = PlayerStatsWrapper.buildDefaultStatsForODI();
  public static readonly DEFAULT_STATS_MAP_T10: Map<
    number,
    PlayerStatsWrapper
  > = PlayerStatsWrapper.buildDefaultStatsForT10();
  public static readonly DEFAULT_STATS_MAP_T20_WOMEN: Map<
    number,
    PlayerStatsWrapper
  > = PlayerStatsWrapper.buildDefaultStatsForT20Women();
  public static readonly DEFAULT_STATS_MAP_ODI_WOMEN: Map<
    number,
    PlayerStatsWrapper
  > = PlayerStatsWrapper.buildDefaultStatsForODIWomen();
  public static readonly DEFAULT_STATS_MAP_T10_WOMEN: Map<
    number,
    PlayerStatsWrapper
  > = PlayerStatsWrapper.buildDefaultStatsForT10Women();

  public static readonly matchTypeDefaultMaps: Record<
    MatchType,
    Map<number, PlayerStatsWrapper>
  > = {
    T20_AND_HUNDRED: DefaultPlayerStatsPage.DEFAULT_STATS_MAP_T20,
    ODI: DefaultPlayerStatsPage.DEFAULT_STATS_MAP_ODI,
    T10: DefaultPlayerStatsPage.DEFAULT_STATS_MAP_T10,
    WOMENS_T20_AND_HUNDRED: DefaultPlayerStatsPage.DEFAULT_STATS_MAP_T20_WOMEN,
    WOMENS_ODI: DefaultPlayerStatsPage.DEFAULT_STATS_MAP_ODI_WOMEN,
    WOMENS_T10: DefaultPlayerStatsPage.DEFAULT_STATS_MAP_T10_WOMEN,
  };

  private static DEFAULT_STATE = {
    matchType: MatchType.T20_AND_HUNDRED,
    t20Defaults: null,
    odiDefaults: null,
    t10Defaults: null,
    t20DefaultsOriginal: null,
    odiDefaultsOriginal: null,
    t10DefaultsOriginal: null,
    t20DefaultsWomen: null,
    odiDefaultsWomen: null,
    t10DefaultsWomen: null,
    t20DefaultsWomenOriginal: null,
    odiDefaultsWomenOriginal: null,
    t10DefaultsWomenOriginal: null,
    loading: false,
    isWarningModalOpen: false,
    latestUpdate: null,
    currentStage: null,
    totalStages: null,
    latestHistoricPushUpdate: null,
    latestPushBracketUpdate: null,
    latestHistoricGroundFormUpdate: null,
    latestHistoricPlayerFormUpdate: null,
    expandedRow: null,
    strikeRateData: [],
    wicketPercentData: [],
    aggression: 0.0,
  };

  constructor(props) {
    super(props);
    this.state = DefaultPlayerStatsPage.DEFAULT_STATE;
  }

  componentDidMount(): void {
    this.updateValues();
  }

  private updateValues() {
    services.multipleStageUpdateService.multipleStageSubject.subscribe(
      (update: MultipleStageUpdateMessage) => {
        if (!!update && update.currentStage === update.totalStages) {
          this.setState({
            latestUpdate: update,
            currentStage: update.currentStage,
            totalStages: update.totalStages,
            loading: false,
          });
        } else {
          this.setState({
            latestUpdate: update,
            currentStage: update.currentStage,
            totalStages: update.totalStages,
            loading: true,
          });
        }
      }
    );

    services.historicPushService.historicPushUpdateSubject.subscribe(
      (update: HistoricPushMessage) => {
        this.setState({
          latestHistoricPushUpdate: update,
        });
      }
    );

    services.historicPushService.pushBracketsUpdateSubject.subscribe(
      (update: PushBracketUpdateMessage) => {
        this.setState({ latestPushBracketUpdate: update });
      }
    );

    services.groundStatsService.historicFormRequestSubject.subscribe(
      (update: HistoricFormUpdateMessage) => {
        this.setState({
          latestHistoricGroundFormUpdate: update,
        });
      }
    );

    services.playerStatsService.historicFormRequestSubject.subscribe(
      (update: HistoricStatsUpdateMessage) => {
        this.setState({
          latestHistoricPlayerFormUpdate: update,
        });
      }
    );

    services.playerStatsService
      .getDefaultStats(MatchType.T20_AND_HUNDRED)
      .then((t20Defaults) =>
        this.setState({
          t20Defaults: this.cloneStats(t20Defaults),
          t20DefaultsOriginal: t20Defaults,
        })
      );
    services.playerStatsService
      .getDefaultStats(MatchType.ODI)
      .then((odiDefaults) =>
        this.setState({
          odiDefaults: this.cloneStats(odiDefaults),
          odiDefaultsOriginal: odiDefaults,
        })
      );
    services.playerStatsService
      .getDefaultStats(MatchType.T10)
      .then((t10Defaults) =>
        this.setState({
          t10Defaults: this.cloneStats(t10Defaults),
          t10DefaultsOriginal: t10Defaults,
        })
      );
    services.playerStatsService
      .getDefaultStats(MatchType.WOMENS_T20_AND_HUNDRED)
      .then((t20DefaultsWomen) =>
        this.setState({
          t20DefaultsWomen: this.cloneStats(t20DefaultsWomen),
          t20DefaultsWomenOriginal: t20DefaultsWomen,
        })
      );
    services.playerStatsService
      .getDefaultStats(MatchType.WOMENS_ODI)
      .then((odiDefaultsWomen) =>
        this.setState({
          odiDefaultsWomen: this.cloneStats(odiDefaultsWomen),
          odiDefaultsWomenOriginal: odiDefaultsWomen,
        })
      );
    services.playerStatsService
      .getDefaultStats(MatchType.WOMENS_T10)
      .then((t10DefaultsWomen) =>
        this.setState({
          t10DefaultsWomen: this.cloneStats(t10DefaultsWomen),
          t10DefaultsWomenOriginal: t10DefaultsWomen,
        })
      );
  }

  private cloneStats(original: Map<number, PlayerStatsWrapper>) {
    if (!original) {
      return null;
    }

    const cloned: Map<number, PlayerStatsWrapper> = new Map();

    original.forEach(
      (playerStatsWrapper: PlayerStatsWrapper, order: number) => {
        cloned.set(
          order,
          PlayerStatsWrapper.deserializeOne(
            PlayerStatsWrapper.serialize(playerStatsWrapper)
          )
        );
      }
    );

    return cloned;
  }

  private updateValue(order: number, property: string, value: number) {
    const map = this.state[matchTypeMapNames[this.state.matchType]];
    const stats = map.get(order);
    stats.playerStats[property] = value;
    const propertyToUpdate = matchTypeMapNames[this.state.matchType];
    this.setState({ ...this.state, [propertyToUpdate]: map });

    if (this.state.expandedRow === order) {
      this.fetchInningsProgressionData(
        stats.playerStats,
        this.state.aggression
      );
    }
  }

  private buildTableRows(
    defaults: Map<number, PlayerStatsWrapper>
  ): ReactNode[] {
    const rows: ReactNode[] = [];
    !!defaults &&
      defaults.forEach((playerStatsWrapper, order) => {
        const isExpanded = this.state.expandedRow === order;
        rows.push(
          <Fragment key={order}>
            <TableRow className="default-stats-table-row">
              <TableCell
                className="default-stats-table-cell"
                align="center"
                style={{ paddingLeft: "1rem", paddingRight: "1rem" }}
              >
                <Button
                  onClick={() =>
                    this.toggleRowExpansion(
                      order,
                      playerStatsWrapper.playerStats
                    )
                  }
                >
                  <ExpandMoreIcon />
                </Button>
              </TableCell>
              <TableCell className="default-stats-table-cell" align="center">
                {order + 1}
              </TableCell>
              <TableCell className="default-stats-table-cell">
                <NumberSelector
                  label={""}
                  className="default-stats-number-selector"
                  min={0}
                  max={10}
                  step={0.01}
                  decimalPlaces={2}
                  initial={playerStatsWrapper.playerStats.defensiveStrikeRate}
                  onValid={(valid: number) =>
                    this.updateValue(order, "defensiveStrikeRate", valid)
                  }
                />
              </TableCell>
              <TableCell className="default-stats-table-cell">
                <NumberSelector
                  label={""}
                  className="default-stats-number-selector"
                  min={0}
                  max={10}
                  step={0.01}
                  decimalPlaces={2}
                  initial={playerStatsWrapper.playerStats.halfInStrikeRate}
                  onValid={(valid: number) =>
                    this.updateValue(order, "halfInStrikeRate", valid)
                  }
                />
              </TableCell>
              <TableCell className="default-stats-table-cell">
                <NumberSelector
                  label={""}
                  className="default-stats-number-selector"
                  min={0}
                  max={10}
                  step={0.01}
                  decimalPlaces={2}
                  initial={playerStatsWrapper.playerStats.inStrikeRate}
                  onValid={(valid: number) =>
                    this.updateValue(order, "inStrikeRate", valid)
                  }
                />
              </TableCell>
              <TableCell className="default-stats-table-cell">
                <NumberSelector
                  label={""}
                  className="default-stats-number-selector"
                  min={0}
                  max={10}
                  step={0.01}
                  decimalPlaces={2}
                  initial={playerStatsWrapper.playerStats.maxStrikeRate}
                  onValid={(valid: number) =>
                    this.updateValue(order, "maxStrikeRate", valid)
                  }
                />
              </TableCell>
              <TableCell className="default-stats-table-cell">
                <NumberSelector
                  label={""}
                  className="default-stats-number-selector"
                  min={0}
                  max={500}
                  step={1}
                  decimalPlaces={1}
                  initial={playerStatsWrapper.playerStats.ballsUntilHalfIn}
                  onValid={(valid: number) =>
                    this.updateValue(order, "ballsUntilHalfIn", valid)
                  }
                />
              </TableCell>
              <TableCell className="default-stats-table-cell">
                <NumberSelector
                  label={""}
                  className="default-stats-number-selector"
                  min={0}
                  max={500}
                  step={1}
                  decimalPlaces={0}
                  initial={playerStatsWrapper.playerStats.ballsUntilFullyIn}
                  onValid={(valid: number) =>
                    this.updateValue(order, "ballsUntilFullyIn", valid)
                  }
                />
              </TableCell>
              <TableCell className="default-stats-table-cell">
                <NumberSelector
                  label={""}
                  className="default-stats-number-selector"
                  min={0}
                  max={10}
                  step={0.001}
                  decimalPlaces={3}
                  initial={
                    playerStatsWrapper.playerStats.defensiveWicketPercent
                  }
                  onValid={(valid: number) =>
                    this.updateValue(order, "defensiveWicketPercent", valid)
                  }
                />
              </TableCell>
              <TableCell className="default-stats-table-cell">
                <NumberSelector
                  label={""}
                  className="default-stats-number-selector"
                  min={0}
                  max={10}
                  step={0.001}
                  decimalPlaces={3}
                  initial={playerStatsWrapper.playerStats.inWicketPercent}
                  onValid={(valid: number) =>
                    this.updateValue(order, "inWicketPercent", valid)
                  }
                />
              </TableCell>
              <TableCell className="default-stats-table-cell" align="center">
                {formatToDp(
                  this.calculateMaxWicketPercent(
                    playerStatsWrapper.playerStats
                  ),
                  3
                )}
              </TableCell>
              <TableCell className="default-stats-table-cell">
                <NumberSelector
                  label={""}
                  className="default-stats-number-selector"
                  min={0}
                  max={200}
                  step={1}
                  decimalPlaces={0}
                  initial={
                    playerStatsWrapper.playerStats.strikeRateWicketMultiplier
                  }
                  onValid={(valid: number) =>
                    this.updateValue(order, "strikeRateWicketMultiplier", valid)
                  }
                />
              </TableCell>
            </TableRow>
            {isExpanded && (
              <TableRow className="expanded-row">
                <TableCell colSpan={12}>
                  {!!this.state.strikeRateData &&
                    !!this.state.wicketPercentData && (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <BallByBallChart
                          strikeRateData={this.state.strikeRateData}
                          wicketPercentData={this.state.wicketPercentData}
                        />
                      </div>
                    )}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      marginTop: "1rem",
                    }}
                  >
                    Aggression
                    <Box sx={{ width: 300 }}>
                      <Slider
                        value={this.state.aggression}
                        onChange={this.handleAggressionChange.bind(this)}
                        onChangeCommitted={this.handleAggressionChangeCommitted.bind(
                          this
                        )}
                        aria-labelledby="aggression-slider"
                        min={0}
                        max={100}
                        step={1}
                        valueLabelDisplay="auto"
                      />
                    </Box>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </Fragment>
        );
      });

    return rows;
  }

  private calculateMaxWicketPercent(playerStats: PlayerStats) {
    const srWMul = playerStats.strikeRateWicketMultiplier;
    const push = 100 * (playerStats.maxStrikeRate - playerStats.inStrikeRate);
    const inWicketPercent = playerStats.inWicketPercent;
    const wicketPower = playerStats.actualWicketMultiplier;

    return inWicketPercent * Math.pow(wicketPower, push / srWMul);
  }

  private buildTableHeader(): ReactNode[] {
    const headers = [
      "",
      "Batting Order",
      "Defensive SR",
      "Half In SR",
      "Fully In SR",
      "Max SR",
      "Balls Until Half In",
      "Balls Until Fully In",
      "Defensive W%",
      "In W%",
      "Max W%",
      "SR Wicket Multiplier",
    ];
    return headers.map((header, order) => (
      <TableCell align="center" key={order}>
        {header}
      </TableCell>
    ));
  }

  private save() {
    let playerStats: PlayerStatsWrapper[] = [];
    this.state[matchTypeMapNames[this.state.matchType]].forEach((value) =>
      playerStats.push(value)
    );

    return services.playerStatsService
      .saveDefaultStats(playerStats)
      .then((response: boolean) => {
        if (response) {
          this.updateValues();
        }
      });
  }

  private saveAndProceed() {
    this.save().then(() =>
      services.multipleStageUpdateService.recalculateAllRelatedStats(
        this.state.matchType
      )
    );
  }

  private isUnchanged(
    userValues: Map<number, PlayerStatsWrapper>,
    original: Map<number, PlayerStatsWrapper>
  ) {
    let unchanged = true;
    original.forEach((playerStatsWrapper, order) => {
      unchanged =
        unchanged &&
        propsEqual(
          playerStatsWrapper.playerStats,
          userValues.get(order).playerStats
        );
    });
    return unchanged;
  }

  private undoChanges() {
    const propertyToUpdate = matchTypeMapNames[this.state.matchType];
    const propertyToClone = matchTypeDefaultMapNames[this.state.matchType];
    this.setState({
      ...this.state,
      [propertyToUpdate]: this.cloneStats(this.state[propertyToClone]),
    });
  }

  private default() {
    const propertyToUpdate = matchTypeMapNames[this.state.matchType];
    const propertyToClone =
      DefaultPlayerStatsPage.matchTypeDefaultMaps[this.state.matchType];
    this.setState({
      ...this.state,
      [propertyToUpdate]: this.cloneStats(propertyToClone),
    });
  }

  private toggleRowExpansion(order: number, playerStats: PlayerStats) {
    const isExpanded = this.state.expandedRow === order;
    if (isExpanded) {
      this.setState({ expandedRow: null });
    } else {
      this.setState({ expandedRow: order });
      this.fetchInningsProgressionData(playerStats, this.state.aggression);
    }
  }

  private handleAggressionChange(event: Event, newValue: number) {
    this.setState({ aggression: newValue });
  }

  private handleAggressionChangeCommitted(event: Event, newValue: number) {
    if (this.state.expandedRow !== null) {
      const playerStats = this.state[
        matchTypeMapNames[this.state.matchType]
      ].get(this.state.expandedRow).playerStats;
      this.fetchInningsProgressionData(playerStats, newValue);
    }
  }
  private fetchInningsProgressionData(
    playerStats: PlayerStats,
    aggression: number
  ) {
    services.playerStatsService
      .getStrikeRateAndWicketPercentForAggression(playerStats, aggression)
      .then(
        ({
          strikeRateData,
          wicketPercentData,
        }: StrikeRateAndWicketPercentResponse) => {
          this.setState({ strikeRateData, wicketPercentData });
        }
      )
      .catch((error) => {
        console.error("Failed to fetch innings progression data", error);
      });
  }

  public render() {
    const defaults = this.state[matchTypeMapNames[this.state.matchType]];
    const defaultsOriginal =
      this.state[matchTypeDefaultMapNames[this.state.matchType]];
    const absoluteDefaults =
      DefaultPlayerStatsPage.matchTypeDefaultMaps[this.state.matchType];

    const tableRows = this.buildTableRows(defaults);
    const tableHeaderRow = this.buildTableHeader();

    return (
      <EmployeeRoute>
        <Prompt
          message={"Update still in progress, are you sure you want to leave?"}
          when={this.state.loading}
        />

        <div className="full-push-background-light with-navbar">
          <div className="page-title-and-buttons">
            <div className="page-title">Default Player Stats</div>
            <div className="squad-buttons">
              <Button
                onClick={() => {
                  if (!this.isUnchanged(defaults, defaultsOriginal)) {
                    this.setState({ isWarningModalOpen: true });
                  } else {
                    this.setState({ loading: true });
                    services.multipleStageUpdateService.recalculateAllRelatedStats(
                      this.state.matchType
                    );
                  }
                }}
                variant={"contained"}
                color="primary"
                disabled={this.state.loading}
              >
                Recalculate all related stats
              </Button>
              <div className="historic-ground-stats-radio">
                <EnumSelector
                  classes="match-type-selector"
                  enumObject={MatchType}
                  label={"Match Type"}
                  value={this.state.matchType}
                  readableValues={matchTypeNames}
                  onSelect={(matchType) => this.setState({ matchType })}
                  disabled={false}
                  canType={false}
                />
              </div>
            </div>
          </div>

          <CreationDialog
            open={this.state.isWarningModalOpen}
            label={"Warning!"}
            invalid={false}
            disabled={false}
            onCancel={() => this.setState({ isWarningModalOpen: false })}
            onProceed={() => {
              this.setState({ isWarningModalOpen: false, loading: true });
              this.saveAndProceed();
            }}
            proceedText="Save and proceed"
            cancelText="NO"
          >
            <div>
              You have unsaved changes, are you sure you want to recalculate?
            </div>
          </CreationDialog>

          {!!defaults && !this.state.loading && (
            <div className="default-player-stats-table">
              <TableContainer style={{ overflowX: "scroll" }}>
                <Table>
                  <TableHead>
                    <TableRow>{tableHeaderRow}</TableRow>
                  </TableHead>
                  <TableBody>{tableRows}</TableBody>
                </Table>
              </TableContainer>
              <div className="default-player-stats-buttons">
                <Button
                  onClick={() => this.save()}
                  disabled={this.isUnchanged(defaults, defaultsOriginal)}
                  color="primary"
                >
                  Save
                </Button>
                <Button
                  onClick={() => this.undoChanges()}
                  disabled={this.isUnchanged(defaults, defaultsOriginal)}
                  color="secondary"
                >
                  Undo Changes
                </Button>
                <Button
                  onClick={() => this.default()}
                  disabled={this.isUnchanged(defaults, absoluteDefaults)}
                  color="secondary"
                >
                  Reset to Default
                </Button>
              </div>
            </div>
          )}
          {this.state.loading && (
            <>
              <div className="ground-display loading-bar">
                Loading... Currently on stage {this.state.currentStage} of{" "}
                {this.state.totalStages}
                <Box sx={{ width: "100%" }}>
                  <LinearProgressWithLabel
                    value={
                      (this.state.currentStage / this.state.totalStages) * 100
                    }
                  />
                </Box>
              </div>
              {this.state.currentStage === 1 && (
                <div className="ground-display loading-bar">
                  Loading Historic Push
                  <Box sx={{ width: "100%" }}>
                    <LinearProgressWithLabel
                      value={calculateProgress(
                        this.state.latestHistoricPushUpdate
                      )}
                    />
                  </Box>
                </div>
              )}
              {this.state.currentStage === 2 && (
                <div className="ground-display loading-bar">
                  Loading Push Brackets
                  <Box sx={{ width: "100%" }}>
                    <LinearProgressWithLabel
                      value={calculateProgress(
                        this.state.latestPushBracketUpdate
                      )}
                    />
                  </Box>
                </div>
              )}
              {this.state.currentStage === 3 && (
                <div className="ground-display loading-bar">
                  Loading Historic Ground Form
                  <Box sx={{ width: "100%" }}>
                    <LinearProgressWithLabel
                      value={calculateProgress(
                        this.state.latestHistoricGroundFormUpdate
                      )}
                    />
                  </Box>
                </div>
              )}
              {this.state.currentStage === 4 && (
                <div className="ground-display loading-bar">
                  Loading Historic Player Form
                  <Box sx={{ width: "100%" }}>
                    <LinearProgressWithLabel
                      value={calculateProgress(
                        this.state.latestHistoricPlayerFormUpdate
                      )}
                    />
                  </Box>
                </div>
              )}
            </>
          )}
        </div>
      </EmployeeRoute>
    );
  }
}
