import React from "react";
import { NumberSelector } from "../entity-management/entity-selectors/number-selector";

interface InningsProps {
  edge: number;
  onChange: (edge: number) => void;
}

export function BetBuilderEdgeControl({
  edge,
  onChange,
}: InningsProps): React.JSX.Element {
  return (
    <div
      className="compare-user-select-light"
    >
      Edge (%):
      <NumberSelector
        label={""}
        className="medium-number-selector"
        min={0}
        max={100}
        initial={edge}
        onValid={onChange}
      />
    </div>
  );
}
