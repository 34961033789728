import { Component } from "react";

import { GroundStatsWrapper } from "../../types/stats/ground-stats";
import { NumberSelector } from "../entity-management/entity-selectors/number-selector";
import { NumberComparison } from "../stats-editing-components/number-comparison";
import {
  areStatsEqual,
  getToolTipMessage,
} from "../stats-editing-components/stats-editing-utils";
import StealStatsButton from "../stats-editing-components/steal-stats-button";

import { batchUpdate } from "./ground-page-utils";

interface Props {
  innings: number;
  strikeRateProperty: string;
  wicketProperty: string;
  currentStats: GroundStatsWrapper;
  comparedStats: GroundStatsWrapper;
  comparedUserName: string;
  onUpdate: (updatedStats: GroundStatsWrapper) => void;
}

interface State {
  strikeRateBiasValid: boolean;
  wicketBiasValid: boolean;
}

export class GroundStatsSurgeSelector extends Component<Props, State> {
  public STATS_TO_STEAL = [
    this.props.strikeRateProperty,
    this.props.wicketProperty,
  ];

  constructor(props: Props) {
    super(props);
    this.state = {
      strikeRateBiasValid: true,
      wicketBiasValid: true,
    };
  }

  private onClickHandler = () => {
    batchUpdate(
      this.props.currentStats,
      this.props.comparedStats,
      this.STATS_TO_STEAL,
      this.props.onUpdate
    );
  };

  private updateGroundStats(propertyName: string, value: number): void {
    const groundStatsWrapper: GroundStatsWrapper = new GroundStatsWrapper(
      this.props.currentStats.groundStatsId,
      this.props.currentStats.createdBy,
      this.props.currentStats.createdAt,
      this.props.currentStats.name,
      {
        ...this.props.currentStats.groundStats,
        [propertyName]: value,
      },
      this.props.currentStats.matchType
    );

    this.props.onUpdate(groundStatsWrapper);
  }

  public render() {
    return (
      <div className="stats-modal-section">
        <div className="stats-modal-section-head">
          Surge Innings {this.props.innings}
        </div>
        <NumberSelector
          label={"Strike Rate Bias"}
          className="stats-modal-number-selector"
          min={0}
          max={10}
          step={0.01}
          decimalPlaces={4}
          initial={
            this.props.currentStats.groundStats[this.props.strikeRateProperty]
          }
          onValid={(valid: number) => {
            this.setState({ strikeRateBiasValid: true });
            this.updateGroundStats(this.props.strikeRateProperty, valid);
          }}
          onInvalid={(invalid: number) => {
            this.setState({ strikeRateBiasValid: false });
            this.updateGroundStats(this.props.strikeRateProperty, invalid);
          }}
        />
        <NumberSelector
          label={"Wicket % Bias"}
          className="stats-modal-number-selector"
          min={0}
          max={10}
          step={0.01}
          decimalPlaces={4}
          initial={
            this.props.currentStats.groundStats[this.props.wicketProperty]
          }
          onValid={(valid: number) => {
            this.setState({ wicketBiasValid: true });
            this.updateGroundStats(this.props.wicketProperty, valid);
          }}
          onInvalid={(invalid: number) => {
            this.setState({ wicketBiasValid: false });
            this.updateGroundStats(this.props.wicketProperty, invalid);
          }}
        />
        {!!this.props.comparedStats && (
          <div className="comparison">
            <div className="comparison-title-and-steal-button">
              <span>Compared to {this.props.comparedUserName}</span>
              <StealStatsButton
                comparedUserName={this.props.comparedUserName}
                disabled={areStatsEqual(
                  this.props.currentStats,
                  this.props.comparedStats,
                  this.STATS_TO_STEAL,
                  "groundStats"
                )}
                tooltipMessage={getToolTipMessage(
                  this.props.currentStats,
                  this.props.comparedStats,
                  this.STATS_TO_STEAL,
                  this.props.comparedUserName,
                  "groundStats"
                )}
                onClickHandler={this.onClickHandler}
              />
            </div>
            <NumberComparison
              label="Strike Rate Bias"
              originalValue={
                this.props.currentStats.groundStats[
                  this.props.strikeRateProperty
                ]
              }
              comparedValue={
                this.props.comparedStats.groundStats[
                  this.props.strikeRateProperty
                ]
              }
            />
            <NumberComparison
              label="Wicket Bias"
              originalValue={
                this.props.currentStats.groundStats[this.props.wicketProperty]
              }
              comparedValue={
                this.props.comparedStats.groundStats[this.props.wicketProperty]
              }
            />
          </div>
        )}
      </div>
    );
  }
}
