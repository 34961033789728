import LoadingOverlay from "@ronchalant/react-loading-overlay";
import { Component } from "react";
import { Bar } from "react-chartjs-2";
import { GameState } from "../../types/entities/game-state";
import { MatchFormat } from "../../types/entities/match-format";
import { Squad } from "../../types/entities/squad";
import { Team } from "../../types/entities/team";
import { SimulationResult } from "../../types/simulator/simulation-result";
import { buildChartOptions } from "../component-utils";
import { NumberSelector } from "../entity-management/entity-selectors/number-selector";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";

const chartOptions = buildChartOptions("bottom", 1);

interface Props {
  latestResult: SimulationResult;
  comparedResults: SimulationResult;
  comparedUserName: string;
  matchFormat: MatchFormat;
  gameState: GameState;
  team: Team;
  squad: Squad;
  collapsed: boolean;
  toggleCollapse: () => void;
  loading: boolean;
  innings: number;
  showInningsToggle: boolean;
  onInningsChange: (innings: number) => void;
  width: string;
  height: string;
  hideByDefault: boolean;
}

export class SimulatorOversDistributionDisplay extends Component<Props, {}> {
  public static readonly playerColours: string[] = [
    "#c2b6cd",
    "#5b8970",
    "#1942f7",
    "#800bad",
    "#152145",
    "#e74a23",
    "#71faca",
    "#c6c620",
    "#563b01",
    "#79bbf0",
    "#afd310",
  ];

  static defaultProps: {
    showInningsToggle: false;
    onInningsChange: (innings) => {};
    width: "500px";
    height: "100px";
    hideByDefault: false;
  };

  public render() {
    if (!this.props.latestResult) {
      return <div></div>;
    }

    const overs = this.props.matchFormat.overConfiguration[
      this.props.innings - 1
    ].map((_value, index) => index + 1);
    const chartData = {
      labels: overs,
      datasets: this.props.squad.players
        .map((p, index) => {
          const percents: number[] = overs.map((over) =>
            this.props.latestResult.numberOfSimulations === 0
              ? 0
              : ((!!this.props.latestResult.playerOversBowled.get(
                  p.playerId.value
                ) &&
                  this.props.latestResult.playerOversBowled
                    .get(p.playerId.value)
                    .get(over - 1)) ||
                  0) / this.props.latestResult.numberOfSimulations
          );
          const sum = percents.reduce((partialSum, a) => partialSum + a, 0);

          return sum > 0
            ? {
                yAxisID: "yAxis",
                label: p.longName,
                data: percents,
                hidden: this.props.hideByDefault,
                backgroundColor:
                  SimulatorOversDistributionDisplay.playerColours[index % 10],
                borderColor:
                  SimulatorOversDistributionDisplay.playerColours[index % 10],
              }
            : null;
        })
        .filter((a) => a !== null),
    };

    return (
      <div className="simulator-container">
        <div className="simulator-title">
          Bowler Distribution
          <div className="simulator-title-buttons">
            {this.props.showInningsToggle && (
              <div>
                Innings:
                <NumberSelector
                  className="stats-modal-number-selector"
                  label={""}
                  min={1}
                  max={
                    !!this.props.matchFormat
                      ? Array.from(
                          this.props.matchFormat.inningsConfiguration.keys()
                        ).length
                      : 1
                  }
                  step={1}
                  decimalPlaces={0}
                  initial={this.props.innings}
                  onValid={(innings) => this.props.onInningsChange(innings)}
                />
              </div>
            )}
            <TooltipIconButton
              icon={this.props.collapsed ? "expand_more" : "expand_less"}
              onClick={() => this.props.toggleCollapse()}
              title={this.props.collapsed ? "Expand" : "Collapse"}
            />
          </div>
        </div>
        {!this.props.collapsed && (
          <div>
            <LoadingOverlay
              active={this.props.loading}
              spinner
              text="Simulating..."
            >
              {!!this.props.latestResult && (
                <div>
                  <Bar
                    width={this.props.width}
                    height={this.props.height}
                    options={chartOptions}
                    data={chartData}
                  />
                </div>
              )}
            </LoadingOverlay>
          </div>
        )}
      </div>
    );
  }
}
