import { Component } from "react";

import { MatchFormat } from "../../../types/entities/match-format";
import { Team } from "../../../types/entities/team";
import { propsEqual } from "../../component-utils";
import { NumberSelector } from "../../entity-management/entity-selectors/number-selector";
import { CreationDialog } from "../../my-matches/match-creation-modals/creation-dialog";

interface Props {
  open: boolean;
  onCancel: () => void;
  onProceed: (runs: number, innings: number) => void;
  team1: Team;
  team2: Team;
  matchFormat: MatchFormat;
}

interface State {
  runs: number;
  innings: number;
  runsInvalid: boolean;
  inningsInvalid: boolean;
}

export class PenaltyModal extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      runs: 5,
      innings: 1,
      runsInvalid: false,
      inningsInvalid: false,
    };
  }

  componentDidUpdate(prevProps): void {
    if (!propsEqual(prevProps, this.props)) {
      this.setState({
        runs: 5,
        innings: 1,
        runsInvalid: false,
        inningsInvalid: false,
      });
    }
  }

  private invalid(): boolean {
    return this.state.runs === null || this.state.innings === null;
  }

  public render() {
    return (
      <CreationDialog
        open={this.props.open}
        label={"Penalty"}
        invalid={this.invalid()}
        disabled={false}
        onCancel={() => this.props.onCancel()}
        onProceed={() =>
          this.props.onProceed(this.state.runs, this.state.innings)
        }
        proceedText="OK"
        colour="#34ebb4"
      >
        <div>
          <NumberSelector
            label="Penalty Runs Awarded"
            textFieldClassName="small-number-selector"
            min={1}
            max={100}
            initial={this.state.runs}
            onValid={(runs) => this.setState({ runsInvalid: false, runs })}
            onInvalid={() => this.setState({ runsInvalid: true })}
          />

          <NumberSelector
            label="Innings Awarded To"
            textFieldClassName="small-number-selector"
            min={1}
            max={
              this.props.matchFormat &&
              this.props.matchFormat.inningsConfiguration.length
            }
            initial={this.state.innings}
            onValid={(innings) =>
              this.setState({ inningsInvalid: false, innings })
            }
            onInvalid={() => this.setState({ inningsInvalid: true })}
          />
        </div>
      </CreationDialog>
    );
  }
}
