import { Button, Checkbox, TextField } from "@mui/material";
import { Component } from "react";

import {
  InningsConfiguration,
  allOversEqual,
  inningsConfigurationsEqual,
  overConfigurationsEqual,
} from "../../../types/entities/innings-configuration";
import { MatchFormat } from "../../../types/entities/match-format";
import { BowlerSpecialityPhase } from "../../../types/enums/bowler-speciality";
import { CreationDialog } from "../../my-matches/match-creation-modals/creation-dialog";
import { NumberSelector } from "../entity-selectors/number-selector";

import EditMatchFormatOtherRules from "./edit-match-format-other-rules";
import { MatchFormatPhaseSelectionComponent } from "./match-format-phase-selection-component";

interface Props {
  initialValue: MatchFormat;
  open: boolean;
  label: string;
  buttonText: string;
  onProceed: Function;
  onCancel: Function;
  isNew: boolean;
}

interface State {
  value: MatchFormat;
  customInnings: boolean;
  innings: number;
  widePenaltyValid: boolean;
  noBallPenaltyValid: boolean;
  oversBeforeSwitchValid: boolean;
  maxConsecutiveOversValid: boolean;
  inningsEachValid: boolean;
  totalOversValid: boolean[];
  ballsPerOverValid: boolean[];
  oversPerBowlerValid: boolean[];
  surgeOversValid: boolean[];
  surgeAvailableFromValid: boolean[];
  powerplayOversStartValid: boolean[];
  powerplayOversEndValid: boolean[];
  battingTeamValid: boolean[];
  maxWicketsValid: boolean[];
  customOvers: boolean[];
}

export class EditMatchFormatComponent extends Component<Props, State> {
  private static defaultStateForProps(props): State {
    return {
      widePenaltyValid: true,
      noBallPenaltyValid: true,
      oversBeforeSwitchValid: true,
      maxConsecutiveOversValid: true,
      inningsEachValid: true,
      totalOversValid: [true],
      ballsPerOverValid: [true],
      oversPerBowlerValid: [true],
      surgeOversValid: [true],
      surgeAvailableFromValid: [true],
      powerplayOversStartValid: [true],
      powerplayOversEndValid: [true],
      battingTeamValid: [true],
      maxWicketsValid: [true],
      value: !!props.initialValue
        ? MatchFormat.clone(props.initialValue)
        : null,
      customInnings: !!props.initialValue
        ? EditMatchFormatComponent.areInningsCustom(props)
        : false,
      innings: 1,
      customOvers: !!props.initialValue
        ? EditMatchFormatComponent.initialiseCustomOvers(props)
        : [],
    };
  }

  constructor(props) {
    super(props);
    this.state = EditMatchFormatComponent.defaultStateForProps(props);
  }

  componentDidMount(): void {
    this.setState(EditMatchFormatComponent.defaultStateForProps(this.props));
  }

  componentDidUpdate(prevProps: Props): void {
    if (this.props.initialValue !== prevProps.initialValue) {
      this.setState(EditMatchFormatComponent.defaultStateForProps(this.props));
    }
  }

  private static areInningsCustom(props: Props): boolean {
    const matchFormat = props.initialValue;
    const totalInnings = matchFormat.inningsConfiguration.length;
    let latestInningsConfiguration: InningsConfiguration =
      matchFormat.inningsConfiguration[0];
    let latestOverConfiguration: number[] = matchFormat.overConfiguration[0];
    let equal = true;
    for (let i = 1; i < totalInnings; i++) {
      if (
        !inningsConfigurationsEqual(
          latestInningsConfiguration,
          matchFormat.inningsConfiguration[i]
        ) ||
        !overConfigurationsEqual(
          latestOverConfiguration,
          matchFormat.overConfiguration[i]
        )
      ) {
        equal = false;
        break;
      }
      latestInningsConfiguration = matchFormat.inningsConfiguration[i];
      latestOverConfiguration = matchFormat.overConfiguration[i];
    }
    return !equal;
  }

  private static initialiseCustomOvers(props: Props): boolean[] {
    return props.initialValue.overConfiguration.map(
      (map) => !allOversEqual(map)
    );
  }

  public valid(): boolean {
    return (
      this.state.value &&
      this.state.value.name &&
      this.state.value.name.trim().length > 0 &&
      this.state.value.description &&
      this.state.value.description.trim().length > 0 &&
      this.state.widePenaltyValid &&
      this.state.noBallPenaltyValid &&
      this.state.oversBeforeSwitchValid &&
      this.state.maxConsecutiveOversValid &&
      this.state.inningsEachValid &&
      this.state.totalOversValid.every((b) => b === true) &&
      this.state.ballsPerOverValid.every((b) => b === true) &&
      this.state.oversPerBowlerValid.every((b) => b === true) &&
      this.state.surgeOversValid.every((b) => b === true) &&
      this.state.surgeAvailableFromValid.every((b) => b === true) &&
      this.state.powerplayOversStartValid.every((b) => b === true) &&
      this.state.powerplayOversEndValid.every((b) => b === true) &&
      this.state.battingTeamValid.every((b) => b === true) &&
      this.state.maxWicketsValid.every((b) => b === true) &&
      this.state.value.overConfiguration.every((inningsOverConfig) =>
        this.inningsOverConfigurationValid(inningsOverConfig)
      )
    );
  }

  private inningsOverConfigurationValid(inningsOverConfig: number[]): boolean {
    let valid = true;
    inningsOverConfig.forEach(
      (over) => (valid = valid && over >= 1 && over <= 20)
    );
    return valid;
  }

  private clearAndCancel() {
    this.props.onCancel(this.props.isNew);
    this.setState(EditMatchFormatComponent.defaultStateForProps(this.props));
  }

  private disabled(): boolean {
    return !this.props.isNew && this.state.value && !this.state.value.createdBy;
  }

  public render() {
    return (
      <CreationDialog
        open={this.props.open}
        label={this.props.label}
        onProceed={() => this.props.onProceed(this.state.value)}
        onCancel={() => this.clearAndCancel()}
        invalid={!this.valid()}
        disabled={this.disabled()}
        proceedText={this.props.buttonText}
      >
        {this.state.value && (
          <div className="match-format-dialog">
            <div className="match-format-textfield">
              <div>Name:</div>
              <TextField
                value={this.state.value.name}
                onChange={(newValue) => this.handleTextChange(newValue, "name")}
                placeholder={"Format name"}
                variant="outlined"
                disabled={this.disabled()}
              />
            </div>
            <div className="match-format-textfield">
              <div>Description:</div>
              <TextField
                value={this.state.value.description}
                onChange={(newValue) =>
                  this.handleTextChange(newValue, "description")
                }
                placeholder={"Format description"}
                variant="outlined"
                disabled={this.disabled()}
              />
            </div>
            <div className="match-format-section">
              <div className="match-format-section-head">
                <div>Innings Properties</div>
                <div className="checkbox-and-label">
                  <div>Customise per Innings</div>
                  <Checkbox
                    data-testid={"customise-by-innings"}
                    checked={this.state.customInnings || false}
                    onChange={(event) => this.handleCustomInningsChange(event)}
                    disabled={this.disabled()}
                  />
                </div>
              </div>
              {!this.state.customInnings && (
                <div>
                  <NumberSelector
                    className="match-format-number-selector"
                    testId={"innings-each"}
                    label="Number of innings each:"
                    min={1}
                    max={10}
                    initial={Math.ceil(
                      this.state.value.inningsConfiguration.length / 2
                    )}
                    onValid={(newValue) => {
                      this.handleInningsEachChange(newValue);
                      this.setState({ inningsEachValid: true });
                    }}
                    onInvalid={() => this.setState({ inningsEachValid: false })}
                    disabled={this.disabled() || this.state.customInnings}
                  />
                </div>
              )}
              {this.state.customInnings && (
                <NumberSelector
                  className="match-format-number-selector"
                  testId={"innings-total"}
                  label="Total number of innings:"
                  min={1}
                  max={20}
                  initial={this.state.value.inningsConfiguration.length}
                  onValid={(newValue) => {
                    this.handleTotalInningsChange(newValue);
                    this.setState({ inningsEachValid: true });
                  }}
                  onInvalid={(newValue) => {
                    this.handleTotalInningsChange(newValue);
                    this.setState({ inningsEachValid: false });
                  }}
                  disabled={this.disabled()}
                />
              )}
              {this.state.customInnings && (
                <div className="innings-chooser">
                  <Button
                    onClick={() =>
                      this.setState({ innings: this.state.innings - 1 })
                    }
                    disabled={this.state.innings === 1}
                  >
                    {"<<"}
                  </Button>
                  Innings {this.state.innings}
                  <Button
                    onClick={() =>
                      this.setState({ innings: this.state.innings + 1 })
                    }
                    disabled={
                      this.state.innings ===
                      this.state.value.inningsConfiguration.length
                    }
                  >
                    {">>"}
                  </Button>
                </div>
              )}
              <NumberSelector
                className="match-format-number-selector"
                testId={"overs"}
                label={`Overs ${
                  this.state.customInnings ? "" : "per innings"
                }:`}
                min={1}
                max={500}
                initial={
                  this.state.value.overConfiguration[this.state.innings - 1]
                    .length
                }
                onValid={(newValue) => {
                  this.handleTotalOversChange(newValue, () =>
                    this.setArrayStateForInnings("totalOversValid", true)
                  );
                }}
                onInvalid={(newValue) => {
                  this.handleTotalOversChange(newValue, () =>
                    this.setArrayStateForInnings("totalOversValid", false)
                  );
                }}
                disabled={this.disabled()}
              />
              <MatchFormatPhaseSelectionComponent
                innings={this.state.innings}
                bowlerSpecialities={
                  this.state.value.bowlerSpecialityPhases[
                    this.state.innings - 1
                  ]
                }
                onEdit={(newSpecialities: BowlerSpecialityPhase[]) =>
                  this.handlePhasesChange(newSpecialities)
                }
                matchFormat={this.state.value}
                disabled={this.disabled()}
              />
              <div className="match-format-subsection">
                <div className="match-format-subsection-head">
                  <NumberSelector
                    className="match-format-number-selector"
                    testId={"balls-per-over"}
                    label="Balls per Over:"
                    min={1}
                    max={20}
                    initial={
                      this.state.value.overConfiguration[
                        this.state.innings - 1
                      ][0]
                    }
                    onValid={(newValue) => {
                      this.handleBallsPerOverChange(newValue, () =>
                        this.setArrayStateForInnings("ballsPerOverValid", true)
                      );
                    }}
                    onInvalid={(newValue) => {
                      this.handleBallsPerOverChange(newValue, () =>
                        this.setArrayStateForInnings("ballsPerOverValid", false)
                      );
                    }}
                    disabled={
                      this.disabled() ||
                      this.state.customOvers[this.state.innings - 1]
                    }
                  />
                  <div className="checkbox-and-label">
                    <div>Customise per Over:</div>
                    <Checkbox
                      data-testid={"customise-by-over"}
                      checked={
                        this.state.customOvers[this.state.innings - 1] || false
                      }
                      onChange={(event) => this.handleCustomOverChange(event)}
                      disabled={this.disabled()}
                    />
                  </div>
                </div>
                {this.state.customOvers[this.state.innings - 1] && (
                  <div>
                    <div className="balls-per-over-row">
                      <div className="balls-per-over-column">Over</div>
                      <div className="balls-per-over-column">Balls</div>
                    </div>
                    <div className="match-format-subsection-body">
                      {this.state.value.overConfiguration[
                        this.state.innings - 1
                      ].map((value: number, index) => (
                        <div
                          key={`innings_${this.state.innings}_over_${
                            index + 1
                          }`}
                          className="balls-per-over-row"
                        >
                          <div className="balls-per-over-column">
                            {index + 1}
                          </div>
                          <NumberSelector
                            testId={`balls-per-over-${index + 1}`}
                            label=""
                            min={1}
                            max={20}
                            initial={value}
                            onValid={(newValue) => {
                              this.handleBallsInOverChange(
                                index + 1,
                                newValue,
                                true
                              );
                            }}
                            onInvalid={(newValue) => {
                              this.handleBallsInOverChange(
                                index + 1,
                                newValue,
                                false
                              );
                            }}
                            disabled={this.disabled()}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              <NumberSelector
                className="match-format-number-selector"
                testId={"powerplay-start"}
                label="Powerplay Overs Start:"
                min={0}
                max={Math.min(
                  this.state.value.overConfiguration[this.state.innings - 1]
                    .length,
                  this.state.value.inningsConfiguration[this.state.innings - 1]
                    .powerplayOversEnd
                )}
                initial={
                  this.state.value.inningsConfiguration[this.state.innings - 1]
                    .powerplayOversStart
                }
                onValid={(newValue) => {
                  this.handleInningsNumberChange(
                    newValue,
                    "powerplayOversStart"
                  );
                  this.setArrayStateForInnings(
                    "powerplayOversStartValid",
                    true
                  );
                }}
                onInvalid={(newValue) => {
                  this.handleInningsNumberChange(
                    newValue,
                    "powerplayOversStart"
                  );
                  this.setArrayStateForInnings(
                    "powerplayOversStartValid",
                    false
                  );
                }}
                disabled={this.disabled()}
              />
              <NumberSelector
                className="match-format-number-selector"
                testId={"powerplay-end"}
                label="Powerplay Overs End:"
                min={Math.min(
                  this.state.value.overConfiguration[this.state.innings - 1]
                    .length,
                  this.state.value.inningsConfiguration[this.state.innings - 1]
                    .powerplayOversStart
                )}
                max={
                  this.state.value.overConfiguration[this.state.innings - 1]
                    .length
                }
                initial={
                  this.state.value.inningsConfiguration[this.state.innings - 1]
                    .powerplayOversEnd
                }
                onValid={(newValue) => {
                  this.handleInningsNumberChange(newValue, "powerplayOversEnd");
                  this.setArrayStateForInnings("powerplayOversEndValid", true);
                }}
                onInvalid={(newValue) => {
                  this.handleInningsNumberChange(newValue, "powerplayOversEnd");
                  this.setArrayStateForInnings("powerplayOversEndValid", false);
                }}
                disabled={this.disabled()}
              />
              <NumberSelector
                className="match-format-number-selector"
                testId={"overs-per-bowler"}
                label="Overs per bowler (0=unlimited):"
                min={0}
                max={
                  this.state.value.overConfiguration[this.state.innings - 1]
                    .length
                }
                initial={
                  this.state.value.inningsConfiguration[this.state.innings - 1]
                    .oversPerBowler
                }
                onValid={(newValue) => {
                  this.handleInningsNumberChange(newValue, "oversPerBowler");
                  this.setArrayStateForInnings("oversPerBowlerValid", true);
                }}
                onInvalid={(newValue) => {
                  this.handleInningsNumberChange(newValue, "oversPerBowler");
                  this.setArrayStateForInnings("oversPerBowlerValid", false);
                }}
                disabled={this.disabled()}
              />
              <NumberSelector
                className="match-format-number-selector"
                testId={"max-wickets"}
                label="Max Wickets:"
                min={1}
                max={100}
                initial={
                  this.state.value.inningsConfiguration[this.state.innings - 1]
                    .maxWickets
                }
                onValid={(newValue) => {
                  this.handleInningsNumberChange(newValue, "maxWickets");
                  this.setArrayStateForInnings("maxWicketsValid", true);
                }}
                onInvalid={(newValue) => {
                  this.handleInningsNumberChange(newValue, "maxWickets");
                  this.setArrayStateForInnings("maxWicketsValid", false);
                }}
                disabled={this.disabled()}
              />
              {this.state.customInnings && (
                <NumberSelector
                  className="match-format-number-selector"
                  label="Batting Team:"
                  min={1}
                  max={2}
                  initial={
                    this.state.value.inningsConfiguration[
                      this.state.innings - 1
                    ].battingTeam
                  }
                  onValid={(newValue) => {
                    this.handleInningsNumberChange(newValue, "battingTeam");
                    this.setArrayStateForInnings("battingTeamValid", true);
                  }}
                  onInvalid={(newValue) => {
                    this.handleInningsNumberChange(newValue, "battingTeam");
                    this.setArrayStateForInnings("battingTeamValid", false);
                  }}
                  disabled={this.disabled()}
                />
              )}
              <div className="dialog-content-sideways match-format-edit-col">
                <div className="dialog-content-sideways-item">
                  Format has Surge?
                </div>
                <Checkbox
                  checked={
                    this.state.value.inningsConfiguration[
                      this.state.innings - 1
                    ].surge
                  }
                  onChange={(event) => this.handleSurgeChange(event)}
                  disabled={this.disabled()}
                />
              </div>
              {this.state.value.inningsConfiguration[this.state.innings - 1]
                .surge && (
                <NumberSelector
                  className="match-format-number-selector"
                  label="Number of Surge Overs:"
                  min={0}
                  max={
                    this.state.value.overConfiguration[this.state.innings - 1]
                      .length
                  }
                  initial={
                    this.state.value.inningsConfiguration[
                      this.state.innings - 1
                    ].surgeOvers === null
                      ? 0
                      : this.state.value.inningsConfiguration[
                          this.state.innings - 1
                        ].surgeOvers
                  }
                  onValid={(newValue) => {
                    this.handleInningsNumberChange(newValue, "surgeOvers");
                    this.setArrayStateForInnings("surgeOversValid", true);
                  }}
                  onInvalid={(newValue) => {
                    this.handleInningsNumberChange(newValue, "surgeOvers");
                    this.setArrayStateForInnings("surgeOversValid", false);
                  }}
                  disabled={this.disabled()}
                />
              )}
              {this.state.value.inningsConfiguration[this.state.innings - 1]
                .surge && (
                <NumberSelector
                  className="match-format-number-selector"
                  label="Surge Available from Over:"
                  min={0}
                  max={
                    this.state.value.overConfiguration[this.state.innings - 1]
                      .length
                  }
                  initial={
                    this.state.value.inningsConfiguration[
                      this.state.innings - 1
                    ].surgeAvailableFromOver === null
                      ? 0
                      : this.state.value.inningsConfiguration[
                          this.state.innings - 1
                        ].surgeAvailableFromOver
                  }
                  onValid={(newValue) => {
                    this.handleInningsNumberChange(
                      newValue,
                      "surgeAvailableFromOver"
                    );
                    this.setArrayStateForInnings(
                      "surgeAvailableFromValid",
                      true
                    );
                  }}
                  onInvalid={(newValue) => {
                    this.handleInningsNumberChange(
                      newValue,
                      "surgeAvailableFromOver"
                    );
                    this.setArrayStateForInnings(
                      "surgeAvailableFromValid",
                      false
                    );
                  }}
                  disabled={this.disabled()}
                />
              )}
            </div>
            <div className="match-format-section">
              <EditMatchFormatOtherRules
                value={this.state.value}
                onFreeHitChange={(event) => this.handleFreeHitChange(event)}
                onNumberChange={(newValue, property, valid, validProperty) =>
                  this.handleNumberChange(
                    newValue,
                    property,
                    valid,
                    validProperty
                  )
                }
                disabled={this.disabled()}
              />
            </div>
          </div>
        )}
      </CreationDialog>
    );
  }

  private handlePhasesChange(newSpecialities: BowlerSpecialityPhase[]): void {
    if (this.state.customInnings) {
      const bowlerSpecialityPhases = this.state.value.bowlerSpecialityPhases;
      bowlerSpecialityPhases[this.state.innings - 1] = newSpecialities;
      this.setState({ value: { ...this.state.value, bowlerSpecialityPhases } });
    } else {
      const newBowlerSpecialityPhases: BowlerSpecialityPhase[][] = [];
      for (
        let innings = 0;
        innings < this.state.value.inningsConfiguration.length;
        innings++
      ) {
        newBowlerSpecialityPhases.push(newSpecialities);
      }
      this.setState({
        value: {
          ...this.state.value,
          bowlerSpecialityPhases: newBowlerSpecialityPhases,
        },
      });
    }
  }

  private handleTextChange(newValue, property) {
    this.setState({
      value: {
        ...this.state.value,
        [property]: newValue.target.value,
      },
    });
  }

  private handleNumberChange(
    newValue: number,
    property: string,
    valid: boolean,
    validProperty: string
  ) {
    this.setState({
      value: {
        ...this.state.value,
        [property]: newValue,
      },
      [validProperty]: valid,
    } as unknown as State); // Disgusting hack to tell typescript to shut up!
  }

  private handleInningsNumberChange(newValue, property) {
    const matchFormat: MatchFormat = Object.assign({}, this.state.value);
    const inningsAffected = this.state.customInnings
      ? [matchFormat.inningsConfiguration[this.state.innings - 1]]
      : matchFormat.inningsConfiguration;
    inningsAffected.forEach((inningsConfig) => {
      inningsConfig[property] = newValue;
    });
    this.setState({
      value: matchFormat,
    });
  }

  private handleTotalInningsChange(newInnings) {
    let inningsConfiguration;
    let customOvers = this.state.customOvers;
    let overConfiguration;
    let bowlerSpecialityPhases;
    const currentInnings = this.state.value.inningsConfiguration.length;
    if (currentInnings < newInnings) {
      for (
        let thisInnings = currentInnings + 1;
        thisInnings <= newInnings;
        thisInnings++
      ) {
        overConfiguration = this.state.value.overConfiguration;
        inningsConfiguration = this.state.value.inningsConfiguration;
        bowlerSpecialityPhases = this.state.value.bowlerSpecialityPhases;
        inningsConfiguration.push({
          ...Object.assign({}, inningsConfiguration[thisInnings - 2]),
          battingTeam: thisInnings % 2 === 1 ? 1 : 2,
        });
        overConfiguration.push([...overConfiguration[thisInnings - 2]]);
        bowlerSpecialityPhases.push(
          MatchFormat.cloneInningsBowlerSpecialityPhases(
            bowlerSpecialityPhases[thisInnings - 2]
          )
        );
        customOvers.push(customOvers[thisInnings - 2]);
      }
    } else {
      for (
        let thisInnings = currentInnings;
        thisInnings > newInnings;
        thisInnings--
      ) {
        inningsConfiguration = this.state.value.inningsConfiguration.slice(
          0,
          newInnings
        );
      }
      overConfiguration = this.state.value.overConfiguration.slice(
        0,
        newInnings
      );
      bowlerSpecialityPhases = this.state.value.bowlerSpecialityPhases.slice(
        0,
        newInnings
      );
      customOvers = this.state.customOvers.slice(0, newInnings);
    }
    this.setState({
      value: {
        ...this.state.value,
        inningsConfiguration,
        overConfiguration,
        bowlerSpecialityPhases,
      },
      innings: newInnings,
      customOvers,
    });
  }

  private handleInningsEachChange(inningsEach) {
    const ballsPerOver =
      this.state.value.overConfiguration[this.state.innings - 1][0];
    const totalOvers =
      this.state.value.overConfiguration[this.state.innings - 1].length;
    this.setMatchConfiguration(inningsEach * 2, totalOvers, ballsPerOver);
  }

  private handleTotalOversChange(totalOvers, onComplete) {
    const ballsPerOver =
      this.state.value.overConfiguration[this.state.innings - 1][0];
    const numInnings = this.state.value.inningsConfiguration.length;
    this.setMatchConfiguration(
      numInnings,
      totalOvers,
      ballsPerOver,
      onComplete
    );
  }

  private handleBallsPerOverChange(ballsPerOver, onComplete) {
    const totalOvers =
      this.state.value.overConfiguration[this.state.innings - 1].length;
    const numInnings = this.state.value.inningsConfiguration.length;
    this.setMatchConfiguration(
      numInnings,
      totalOvers,
      ballsPerOver,
      onComplete
    );
  }

  private handleBallsInOverChange(over: number, balls: number, valid: boolean) {
    let overConfiguration: number[][] = this.state.value.overConfiguration;
    const inningsAffected = this.state.customInnings
      ? [this.state.innings - 1]
      : Array.from(Array(overConfiguration.length).keys());
    for (const innings of inningsAffected) {
      overConfiguration[innings][over - 1] = balls;
    }
    this.setState({ value: { ...this.state.value, overConfiguration } });
  }

  private setMatchConfiguration(
    numInnings: number,
    totalOvers: number,
    ballsPerOver: number,
    onComplete?: () => void
  ) {
    let overConfiguration: number[][];
    let bowlerSpecialityPhases: BowlerSpecialityPhase[][];
    let inningsConfiguration: InningsConfiguration[];
    let customOvers: boolean[];
    if (this.state.customInnings) {
      customOvers = this.state.customOvers;
      inningsConfiguration = this.state.value.inningsConfiguration;
      bowlerSpecialityPhases = this.state.value.bowlerSpecialityPhases;
      overConfiguration = this.state.value.overConfiguration;
      overConfiguration[this.state.innings - 1] = this.buildInningsOverConfig(
        totalOvers,
        ballsPerOver,
        this.state.innings
      );
      bowlerSpecialityPhases[this.state.innings - 1] =
        this.shiftPhasesForNewOvers(
          bowlerSpecialityPhases[this.state.innings - 1],
          totalOvers
        );
    } else {
      const firstInningsPhases = this.state.value.bowlerSpecialityPhases[0];
      const inningsConfig =
        this.state.value.inningsConfiguration[this.state.innings - 1];
      customOvers = new Array(numInnings).fill(this.state.customOvers[0]);
      inningsConfiguration = [];
      overConfiguration = [];
      bowlerSpecialityPhases = this.state.value.bowlerSpecialityPhases;

      for (let innings = 0; innings < numInnings; innings++) {
        inningsConfiguration.push({
          ...inningsConfig,
          battingTeam: innings % 2 === 0 ? 1 : 2,
        });
        overConfiguration.push(
          this.buildInningsOverConfig(totalOvers, ballsPerOver, innings + 1)
        );
        bowlerSpecialityPhases[innings] = this.shiftPhasesForNewOvers(
          firstInningsPhases,
          totalOvers
        );
      }
    }
    this.setState(
      {
        value: {
          ...this.state.value,
          inningsConfiguration,
          overConfiguration,
          bowlerSpecialityPhases,
        },
        customOvers,
      },
      onComplete
    );
  }

  private shiftPhasesForNewOvers(
    original: BowlerSpecialityPhase[],
    overs: number
  ): BowlerSpecialityPhase[] {
    const phases = MatchFormat.cloneInningsBowlerSpecialityPhases(original);
    phases.forEach((phase: BowlerSpecialityPhase) => {
      if (phase.end > overs) {
        phase.end = overs;
      }
      if (phase.start > overs) {
        phase.start = overs;
      }
    });
    if (phases[phases.length - 1].end < overs) {
      phases[phases.length - 1].end = overs;
    }

    return phases;
  }

  private handleFreeHitChange(event) {
    const freeHit = event.target.checked;
    this.setState({
      value: {
        ...this.state.value,
        noBallFreeHit: freeHit,
      },
    });
  }

  private handleSurgeChange(event) {
    const surge = event.target.checked;
    const matchFormat: MatchFormat = this.state.value;
    matchFormat.inningsConfiguration.forEach((inningsConfig, index) => {
      const innings = index + 1;
      if (!this.state.customInnings || innings === this.state.innings) {
        if (surge) {
          inningsConfig.surge = true;
          inningsConfig.surgeAvailableFromOver = Math.min(
            10,
            matchFormat.overConfiguration[innings - 1].length
          );
          inningsConfig.surgeOvers = Math.min(
            2,
            matchFormat.overConfiguration[innings - 1].length
          );
        } else {
          inningsConfig.surge = false;
          inningsConfig.surgeAvailableFromOver = null;
          inningsConfig.surgeOvers = null;
        }
        this.setArrayStateForInnings("surgeOversValid", true, innings);
        this.setArrayStateForInnings("surgeAvailableFromValid", true, innings);
      }
    });

    this.setState({ value: matchFormat });
  }

  private handleCustomInningsChange(event) {
    const customInnings = event.target.checked;
    let numberOfInnings = this.state.value.inningsConfiguration.length;
    let matchFormat;
    let callback;

    if (customInnings) {
      matchFormat = {
        ...this.state.value,
      };
      callback = () => {};
    } else {
      const currentInningsConfiguration = this.state.value.inningsConfiguration;
      const currentOverConfiguration = this.state.value.overConfiguration;
      const newInningsConfiguration: InningsConfiguration[] = [];
      const newOverConfiguration: number[][] = [];
      const firstInningsConfiguration = currentInningsConfiguration[0];
      const ballsPerOver = currentOverConfiguration[0][0];
      const totalOvers = currentOverConfiguration[0].length;
      numberOfInnings =
        numberOfInnings % 2 === 1 ? numberOfInnings + 1 : numberOfInnings;

      for (let innings = 1; innings <= numberOfInnings; innings++) {
        newInningsConfiguration.push({
          ...firstInningsConfiguration,
          battingTeam: innings % 2 === 1 ? 1 : 2,
        });
        newOverConfiguration.push(
          this.buildInningsOverConfig(totalOvers, ballsPerOver, 1)
        );
      }

      matchFormat = {
        ...this.state.value,
        inningsConfiguration: newInningsConfiguration,
        overConfiguration: newOverConfiguration,
      };
      callback = () => this.removeCustomInningsValidity();
    }

    this.setState(
      {
        customOvers: new Array(numberOfInnings).fill(this.state.customOvers[0]),
        customInnings: customInnings,
        innings: 1,
        value: matchFormat,
      },
      callback
    );
  }

  private buildInningsOverConfig(
    totalOvers: number,
    ballsPerOver: number,
    innings: number
  ): number[] {
    const inningsOverConfig: number[] = [];
    for (let i: number = 0; i < totalOvers; i++) {
      if (this.state.customOvers[innings - 1]) {
        inningsOverConfig.push(
          this.state.value.overConfiguration[innings - 1][i] || ballsPerOver
        );
      } else {
        inningsOverConfig.push(ballsPerOver);
      }
    }
    return inningsOverConfig;
  }

  private handleCustomOverChange(event) {
    const customOvers = event.target.checked;
    let customOversArray: boolean[] = this.state.customOvers;

    const numberOfInnings = this.state.value.inningsConfiguration.length;
    const inningsAffected = this.state.customInnings
      ? [this.state.innings - 1]
      : Array.from(Array(numberOfInnings).keys());
    for (const i of inningsAffected) {
      customOversArray[i] = customOvers;
    }

    if (customOvers) {
      this.setState({
        customOvers: customOversArray,
      });
    } else {
      const overConfiguration = this.resetOverConfiguration();
      this.setState({
        customOvers: customOversArray,
        value: {
          ...this.state.value,
          overConfiguration,
        },
      });
    }
  }

  private resetOverConfiguration(): number[][] {
    const overConfiguration: number[][] = this.state.value.overConfiguration;
    const numberOfInnings = this.state.value.inningsConfiguration.length;
    const ballsPerOver = overConfiguration[this.state.innings - 1][0];
    const totalOvers = overConfiguration[this.state.innings - 1].length;

    const inningsOverConfig: number[] = [];
    for (let i = 0; i < totalOvers; i++) {
      inningsOverConfig.push(ballsPerOver);
    }

    const inningsAffected = this.state.customInnings
      ? [this.state.innings - 1]
      : Array.from(Array(numberOfInnings).keys());
    for (const i of inningsAffected) {
      overConfiguration[i] = inningsOverConfig;
    }
    return overConfiguration;
  }

  private setArrayStateForInnings(
    property:
      | "totalOversValid"
      | "ballsPerOverValid"
      | "oversPerBowlerValid"
      | "surgeOversValid"
      | "surgeAvailableFromValid"
      | "powerplayOversStartValid"
      | "powerplayOversEndValid"
      | "battingTeamValid"
      | "maxWicketsValid"
      | "customOvers",
    value: boolean,
    innings: number = this.state.innings
  ) {
    const array: boolean[] = this.state[property];
    array[innings - 1] = value;
    this.setState({ ...this.state, [property]: array });
  }

  private removeCustomInningsValidity() {
    this.setState({
      totalOversValid: this.state.totalOversValid.slice(0, 1),
      ballsPerOverValid: this.state.ballsPerOverValid.slice(0, 1),
      oversPerBowlerValid: this.state.oversPerBowlerValid.slice(0, 1),
      surgeOversValid: this.state.surgeOversValid.slice(0, 1),
      surgeAvailableFromValid: this.state.surgeAvailableFromValid.slice(0, 1),
      powerplayOversStartValid: this.state.powerplayOversStartValid.slice(0, 1),
      powerplayOversEndValid: this.state.powerplayOversEndValid.slice(0, 1),
      battingTeamValid: this.state.battingTeamValid.slice(0, 1),
      maxWicketsValid: this.state.maxWicketsValid.slice(0, 1),
      customOvers: this.state.customOvers.slice(0, 1),
    });
  }
}
