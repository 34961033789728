import { Component } from "react";
import { PlayerStatsWrapper } from "../../types/stats/player-stats";
import { BowlingStatByPushDisplay } from "./bowling-stat-by-push-display";
import { OpponentSkillStats } from "./opponent-skill-stats";
import { PlayerExtrasBiasesByTypeComponent } from "./player-extras-biases-by-type-component";
import { PlayerStrikeRateAndWicketPercentStats } from "./player-strike-rate-and-wicket-percent-stats";

interface Props {
  currentStats: PlayerStatsWrapper;
  comparedStats: PlayerStatsWrapper;
  comparedUserName: string;
  onUpdate: (updatedStats: PlayerStatsWrapper) => void;
  onValid: (property: string, value) => void;
  onArrayElementValid: (property: string, index: number, value) => void;
}

export class PlayerBowlingStatsDisplay extends Component<Props> {
  public render() {
    return (
      <div className="stats-modal-content">
        <div className="stats-modal-section">
          <div className="stats-modal-section-head">
            Multipliers at Aggression Levels (Each 20th Percentile)
          </div>
          <div className="batting-stats-modal-6-columns">
            <BowlingStatByPushDisplay
              title={"Blocking"}
              index={0}
              currentStats={this.props.currentStats}
              comparedStats={this.props.comparedStats}
              comparedUserName={this.props.comparedUserName}
              onArrayElementValid={this.props.onArrayElementValid}
              classes="blocking"
              onUpdate={(currentStats) => this.props.onUpdate(currentStats)}
            />
            <BowlingStatByPushDisplay
              title={"Defensive"}
              index={1}
              currentStats={this.props.currentStats}
              comparedStats={this.props.comparedStats}
              comparedUserName={this.props.comparedUserName}
              onArrayElementValid={this.props.onArrayElementValid}
              classes="defensive"
              onUpdate={(currentStats) => this.props.onUpdate(currentStats)}
            />
            <BowlingStatByPushDisplay
              title={"Normal"}
              index={2}
              currentStats={this.props.currentStats}
              comparedStats={this.props.comparedStats}
              comparedUserName={this.props.comparedUserName}
              onArrayElementValid={this.props.onArrayElementValid}
              classes="normal"
              onUpdate={(currentStats) => this.props.onUpdate(currentStats)}
            />
            <BowlingStatByPushDisplay
              title={"Pushing"}
              index={3}
              currentStats={this.props.currentStats}
              comparedStats={this.props.comparedStats}
              comparedUserName={this.props.comparedUserName}
              onArrayElementValid={this.props.onArrayElementValid}
              classes="pushing"
              onUpdate={(currentStats) => this.props.onUpdate(currentStats)}
            />
            <BowlingStatByPushDisplay
              title={"Full Push"}
              index={4}
              currentStats={this.props.currentStats}
              comparedStats={this.props.comparedStats}
              comparedUserName={this.props.comparedUserName}
              onArrayElementValid={this.props.onArrayElementValid}
              classes="full-push"
              onUpdate={(currentStats) => this.props.onUpdate(currentStats)}
            />
            <OpponentSkillStats
              currentStats={this.props.currentStats}
              comparedStats={this.props.comparedStats}
              comparedUserName={this.props.comparedUserName}
              title="Historic Averages"
              classes="global-sr-and-wpc"
              groundStrikeRateProperty="bowlingAverageGroundSRBias"
              groundWicketPercentProperty="bowlingAverageGroundWicketBias"
              opponentStrikeRateProperty="bowlingAverageOpponentSRBias"
              opponentWicketPercentProperty="bowlingAverageOpponentWicketBias"
              opponentType="Batsman"
            />
          </div>
        </div>

        <div className="bowling-stats-modal-4-columns">
          <PlayerStrikeRateAndWicketPercentStats
            currentStats={this.props.currentStats}
            comparedStats={this.props.comparedStats}
            comparedUserName={this.props.comparedUserName}
            title="Global"
            classes="global-sr-and-wpc"
            strikeRateProperty="bowlingGlobalStrikeRateBias"
            wicketPercentProperty="bowlingGlobalWicketBias"
            extrasProperty="bowlingGlobalExtrasBias"
            confidenceProperty="bowlingGlobalConfidence"
            onUpdate={(currentStats) => this.props.onUpdate(currentStats)}
          />

          <PlayerStrikeRateAndWicketPercentStats
            currentStats={this.props.currentStats}
            comparedStats={this.props.comparedStats}
            comparedUserName={this.props.comparedUserName}
            title="Against Left Handed"
            classes="against-left-handed"
            strikeRateProperty="bowlingAgainstLeftHandedStrikeRateBias"
            wicketPercentProperty="bowlingAgainstLeftHandedWicketBias"
            confidenceProperty="bowlingAgainstLeftHandedConfidence"
            onUpdate={(currentStats) => this.props.onUpdate(currentStats)}
          />

          <PlayerStrikeRateAndWicketPercentStats
            currentStats={this.props.currentStats}
            comparedStats={this.props.comparedStats}
            comparedUserName={this.props.comparedUserName}
            title="Against Right Handed"
            classes="against-right-handed"
            strikeRateProperty="bowlingAgainstRightHandedStrikeRateBias"
            wicketPercentProperty="bowlingAgainstRightHandedWicketBias"
            confidenceProperty="bowlingAgainstRightHandedConfidence"
            onUpdate={(currentStats) => this.props.onUpdate(currentStats)}
          />

          <PlayerExtrasBiasesByTypeComponent
            currentStats={this.props.currentStats}
            comparedStats={this.props.comparedStats}
            comparedUserName={this.props.comparedUserName}
            onUpdate={(currentStats) => this.props.onUpdate(currentStats)}
          />
        </div>
      </div>
    );
  }
}
