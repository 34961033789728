import { Box, Button, Tab, Tabs, TextField } from "@mui/material";
import LoadingOverlay from "@ronchalant/react-loading-overlay";
import { Component } from "react";
import { Observable, Subscription } from "rxjs";

import { GameState } from "../../types/entities/game-state";
import { Match } from "../../types/entities/match";
import { MatchFormat } from "../../types/entities/match-format";
import { Squad } from "../../types/entities/squad";
import { Team } from "../../types/entities/team";
import { TeamPlayer } from "../../types/entities/team-player";
import { activeMatchRoles } from "../../types/enums/match-role";
import { UserPreferences } from "../../types/preferences/preferences";
import { SimulatorRoute } from "../../types/route-helpers";
import {
  services,
  showConfirmationDialog,
  showMessage,
} from "../../types/services";
import { SimulationResult } from "../../types/simulator/simulation-result";
import {
  PlayerStats,
  PlayerStatsWrapper,
} from "../../types/stats/player-stats";
import { UUID } from "../../types/uuid";
import RunSimulatorButton from "../common-components/run-simulator-button";
import { noSimulationsAllowed, propsEqual } from "../component-utils";
import { EntityAutoSelector } from "../entity-management/entity-selectors/entity-auto-selector";
import { CreationDialog } from "../my-matches/match-creation-modals/creation-dialog";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import { ComparedUserSelector } from "../stats-editing-components/compared-user-selector";
import {
  areStatsEqual,
  getToolTipMessage,
} from "../stats-editing-components/stats-editing-utils";
import StealStatsButton from "../stats-editing-components/steal-stats-button";
import { TabPanel } from "./../match-page/scorecard/tab-panel";

import { PlayerFormExplanation } from "../../types/stats/form-explanation";
import { FormSettingsModal } from "./form-settings-modal";
import { PlayerBattingSimulationDisplay } from "./player-batting-simulation-display";
import { PlayerBattingStatsDisplay } from "./player-batting-stats-display";
import { PlayerBowlingSimulationDisplay } from "./player-bowling-simulation-display";
import { PlayerBowlingStatsDisplay } from "./player-bowling-stats-display";
import PlayerFormExplanationDisplay from "./player-form-explanation-display";
import { PlayerStatsTitle } from "./player-stats-title";
import { batchUpdate } from "./squad-page-utils";
import { StealAllPage } from "./steal-all-page";

interface Props {
  open: boolean;
  onCancel: () => void;
  onProceed: () => void;
  teamNumber: number;
  gameState: GameState;
  matchFormat: MatchFormat;
  fromThisPoint: boolean;
  squad: Squad;
  team: Team;
  match: Match;
  initialPlayer: number;
  simulationResult: SimulationResult;
  comparedResult: SimulationResult;
  comparedUserName: string;
  userPreferences: UserPreferences;
}

interface State {
  currentPlayer: number;
  currentStats: PlayerStatsWrapper;
  comparedStats: PlayerStatsWrapper;
  recentPlayerStats: Observable<PlayerStatsWrapper[]>;
  loading: boolean;
  renaming: boolean;
  nameValid: boolean;
  selectedTab: number;
  simulationLoading: boolean;
  playerStats: Map<string, PlayerStatsWrapper>;
  originalPlayerStats: Map<string, PlayerStatsWrapper>;
  comparedPlayerStats: Map<string, PlayerStatsWrapper>;
  formSettingsModalOpen: boolean;
  formExplanation: PlayerFormExplanation;
}

export class PlayerStatsModal extends Component<Props, State> {
  private subscriptions: Subscription[] = [];

  private static readonly DEFAULT_STATE: State = {
    currentPlayer: null,
    currentStats: null,
    comparedStats: null,
    recentPlayerStats: null,
    loading: false,
    renaming: false,
    nameValid: true,
    selectedTab: 0,
    simulationLoading: false,
    playerStats: null,
    originalPlayerStats: null,
    comparedPlayerStats: new Map(),
    formSettingsModalOpen: false,
    formExplanation: null,
  };

  constructor(props) {
    super(props);
    this.state = PlayerStatsModal.DEFAULT_STATE;
  }

  componentDidMount(): void {
    this.updatePlayers(this.props.initialPlayer);

    this.subscriptions.push(
      services.simulationService.loadingSubject.subscribe((simulationLoading) =>
        this.setState({ simulationLoading })
      )
    );

    this.subscriptions.push(
      services.playerStatsService.comparedUserStatsSubject.subscribe(
        (comparedPlayerStats: Map<string, PlayerStatsWrapper>) => {
          this.setState({ comparedPlayerStats }, () =>
            this.updateComparedPlayers(this.state.currentPlayer)
          );
        }
      )
    );

    this.subscriptions.push(
      services.playerStatsService.playerStatsSubject.subscribe(
        (playerStats: Map<string, PlayerStatsWrapper>) =>
          this.setState(
            {
              playerStats,
              originalPlayerStats:
                PlayerStatsWrapper.clonePlayerStatsWrapperMap(playerStats),
            },
            () => this.updatePlayers(this.state.currentPlayer)
          )
      )
    );
  }

  componentDidUpdate(prevProps): void {
    if (
      !propsEqual(
        this.getPlayerSelectionProps(prevProps),
        this.getPlayerSelectionProps(this.props)
      )
    ) {
      this.updatePlayers(this.props.initialPlayer);
    }
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }

  private getPlayerSelectionProps(props: any): any {
    const {
      simulationResult,
      comparedResult,
      comparedUserName,
      gameState,
      matchFormat,
      fromThisPoint,
      squad,
      team,
      userPreferences,
      ...propsWithoutSimulationResult
    } = props;
    return propsWithoutSimulationResult;
  }

  private updatePlayers(currentPlayer: number) {
    this.setState({ loading: true }, () => {
      if (this.props.squad && this.props.squad.players[currentPlayer]) {
        const playerId: UUID = this.props.squad.players[currentPlayer].playerId;

        const playerStats: PlayerStatsWrapper =
          !!this.state.playerStats &&
          !!playerId &&
          this.state.playerStats.get(playerId.value);

        const comparedStats: PlayerStatsWrapper =
          !!playerId &&
          !!this.state.comparedPlayerStats &&
          this.state.comparedPlayerStats.get(playerId.value);

        this.setState(
          {
            ...PlayerStatsModal.DEFAULT_STATE,
            loading: false,
            currentPlayer,
            currentStats: playerStats,
            comparedStats: comparedStats,
            playerStats: this.state.playerStats,
            originalPlayerStats: this.state.originalPlayerStats,
            comparedPlayerStats: this.state.comparedPlayerStats,
            recentPlayerStats: this.state.recentPlayerStats,
            selectedTab: this.state.selectedTab,
            formExplanation: null,
          },
          () => {
            services.playerStatsService
              .getAllForPlayer(playerId, this.props.gameState.matchId)
              .then((stats: PlayerStatsWrapper[]) => {
                this.setState({
                  recentPlayerStats: new Observable((subscriber) =>
                    subscriber.next(stats)
                  ),
                });
              })
              .catch((reason) => {
                showMessage(
                  `Could not load player recent stats: ${reason}`,
                  "error"
                );
              });
          }
        );
      }
    });
  }

  private updateComparedPlayers(currentPlayer: number) {
    this.setState({ loading: true }, () => {
      if (this.props.squad && this.props.squad.players[currentPlayer]) {
        const playerId: UUID = this.props.squad.players[currentPlayer].playerId;

        const comparedStats: PlayerStatsWrapper =
          !!playerId &&
          !!this.state.comparedPlayerStats &&
          this.state.comparedPlayerStats.get(playerId.value);

        this.setState({
          comparedStats: comparedStats,
          loading: false,
        });
      }
    });
  }

  private regeneratePlayerStats(currentPlayer: number) {
    this.setState({ loading: true }, () => {
      if (this.props.squad && this.props.squad.players[currentPlayer]) {
        const playerId: UUID = this.props.squad.players[currentPlayer].playerId;

        services.playerStatsService
          .generatePlayerStats(
            services.currentGameService.currentMatchId,
            playerId
          )
          .then((playerStats: PlayerStats) => {
            this.updateWithNewPlayerStats(currentPlayer, playerStats);
          })
          .catch((reason) => {
            this.setState({ loading: false });
            showMessage(`Could not generate player stats: ${reason}`, "error");
          });
      }
    });
  }

  private explainPlayerStats(currentPlayer: number) {
    this.setState({ loading: true }, () => {
      if (this.props.squad && this.props.squad.players[currentPlayer]) {
        const playerId: UUID = this.props.squad.players[currentPlayer].playerId;

        services.playerStatsService
          .explainPlayerStats(
            services.currentGameService.currentMatchId,
            playerId
          )
          .then((formExplanation: PlayerFormExplanation) => {
            this.setState({ loading: false, formExplanation });
          })
          .catch((reason) => {
            this.setState({ loading: false });
            showMessage(
              `Could not generate player stats explanation: ${reason}`,
              "error"
            );
          });
      }
    });
  }

  private getPositionalDefaults(currentPlayer: number) {
    this.setState({ loading: true }, () => {
      if (this.props.squad && this.props.squad.players[currentPlayer]) {
        const playerId: UUID = this.props.squad.players[currentPlayer].playerId;

        services.playerStatsService
          .getDefaultPlayerStats(
            services.currentGameService.currentMatchId,
            playerId
          )
          .then((playerStats: PlayerStats) => {
            this.updateWithNewPlayerStats(currentPlayer, playerStats);
          })
          .catch((reason) => {
            this.setState({ loading: false });
            showMessage(
              `Could not get default for position: ${reason}`,
              "error"
            );
          });
      }
    });
  }

  private getPlayerDefaults(currentPlayer: number) {
    this.setState({ loading: true }, () => {
      if (this.props.squad && this.props.squad.players[currentPlayer]) {
        const playerId: UUID = this.props.squad.players[currentPlayer].playerId;

        services.playerStatsService
          .getPlayerDefaultStats(
            services.currentGameService.currentMatchId,
            playerId
          )
          .then((playerStats: PlayerStats) => {
            if (!!playerStats) {
              this.updateWithNewPlayerStats(currentPlayer, playerStats);
            } else {
              this.setState({ loading: false });
              showMessage(`No defaults saved`, "error");
            }
          })
          .catch((reason) => {
            this.setState({ loading: false });
            showMessage(
              `Could not get player default stats: ${reason}`,
              "error"
            );
          });
      }
    });
  }

  private savePlayerDefaults(currentPlayer: number) {
    if (this.props.squad && this.props.squad.players[currentPlayer]) {
      const playerId: UUID = this.props.squad.players[currentPlayer].playerId;

      services.playerStatsService.savePlayerDefaultStats(
        services.currentGameService.currentMatch.matchType,
        playerId,
        this.state.currentStats.playerStats
      );
    }
  }

  private updateWithNewPlayerStats(
    currentPlayer: number,
    playerStats: PlayerStats
  ) {
    const currentStats = new PlayerStatsWrapper(
      this.state.currentStats.playerStatsId,
      this.state.currentStats.createdBy,
      this.state.currentStats.createdAt,
      this.state.currentStats.name,
      playerStats,
      this.state.currentStats.matchType
    );

    this.setState({
      ...PlayerStatsModal.DEFAULT_STATE,
      loading: false,
      currentPlayer,
      currentStats,
      comparedStats: this.state.comparedStats,
      playerStats: this.state.playerStats,
      originalPlayerStats: this.state.originalPlayerStats,
      comparedPlayerStats: this.state.comparedPlayerStats,
      recentPlayerStats: this.state.recentPlayerStats,
      selectedTab: this.state.selectedTab,
      formExplanation: null,
    });
  }

  private invalid() {
    return !this.state.nameValid;
  }

  private onProceed() {
    this.savePlayerStats();
    this.props.onProceed();
  }

  private onCancel() {
    this.setState({
      playerStats: PlayerStatsWrapper.clonePlayerStatsWrapperMap(
        this.state.originalPlayerStats
      ),
    });
    this.props.onCancel();
  }

  private savePlayerStats() {
    services.playerStatsService.updatePlayerStats(this.state.currentStats);
  }

  private onStatsUpdate(value: PlayerStatsWrapper) {
    this.setState({
      currentStats: value,
    });
  }

  private onPropertyChangeValid(property: string, value) {
    this.setState({
      currentStats: new PlayerStatsWrapper(
        this.state.currentStats.playerStatsId,
        this.state.currentStats.createdBy,
        this.state.currentStats.createdAt,
        this.state.currentStats.name,
        {
          ...this.state.currentStats.playerStats,
          [property]: value,
        },
        this.state.currentStats.matchType
      ),
    });
  }

  private onArrayPropertyChangeValid(property: string, index: number, value) {
    const newValueArray = this.state.currentStats.playerStats[
      property
    ] as number[];
    newValueArray[index] = value;

    this.setState({
      currentStats: new PlayerStatsWrapper(
        this.state.currentStats.playerStatsId,
        this.state.currentStats.createdBy,
        this.state.currentStats.createdAt,
        this.state.currentStats.name,
        {
          ...this.state.currentStats.playerStats,
          [property]: newValueArray,
        },
        this.state.currentStats.matchType
      ),
    });
  }

  private refreshSim() {
    const playerId =
      this.props.squad.players[this.state.currentPlayer].playerId;
    services.simulationService.removeSimulationResults();

    services.simulationService
      .simulateWithStats(playerId, this.state.currentStats)
      .catch((reason) =>
        showMessage(`Failed to run simulator: ${reason}`, "error")
      );
  }

  private cannotGoRight(): boolean {
    const squadInOrder = this.props.gameState.squads[
      this.props.teamNumber - 1
    ].sort((a: TeamPlayer, b: TeamPlayer) => a.battingOrder - b.battingOrder);
    return (
      this.state.currentPlayer >= this.props.squad.players.length - 1 ||
      !activeMatchRoles.find(
        (role) => role === squadInOrder[this.state.currentPlayer + 1].matchRole
      )
    );
  }

  private updatePreferences(settings: UserPreferences) {
    services.userService
      .updateUserPreferences(settings)
      .then(() => this.setState({ formSettingsModalOpen: false }));
  }

  private onStealHandler = () => {
    batchUpdate(
      this.state.currentStats,
      this.state.comparedStats,
      PlayerStatsWrapper.getAllProperties(
        this.state.selectedTab === 0
          ? StealAllPage.PLAYER_BATTING_STATS
          : StealAllPage.PLAYER_BOWLING_STATS
      ),
      (updatedStats: PlayerStatsWrapper) => {
        this.setState({ currentStats: updatedStats }, () => {
          this.savePlayerStats();
        });
      }
    );
  };

  public render() {
    return (
      <CreationDialog
        open={this.props.open}
        classes="player-stats-modal"
        label={"Player Stats"}
        invalid={this.invalid()}
        disabled={false}
        loading={this.state.loading || this.state.simulationLoading}
        onCancel={() => this.onCancel()}
        onProceed={() => this.onProceed()}
        proceedText="OK"
        autofocus={false}
        colour="#f04343"
        fullScreen={true}
        element={
          <TooltipIconButton
            title="Form Calculation Settings"
            disabled={false}
            onClick={() => this.setState({ formSettingsModalOpen: true })}
            icon="settings"
            colour="#ffffff"
          />
        }
      >
        <LoadingOverlay
          active={this.state.loading || this.state.simulationLoading}
          spinner
          text={this.state.simulationLoading ? "Simulating..." : "Loading..."}
        >
          <div className="player-stats-modal-head">
            {!!this.state.currentStats &&
              this.state.currentPlayer != null &&
              !!this.props.squad &&
              !!this.props.squad.players[this.state.currentPlayer] && (
                <PlayerStatsTitle
                  onClickPrevious={() => {
                    this.savePlayerStats();
                    this.setState(
                      { currentPlayer: this.state.currentPlayer - 1 },
                      () => this.updatePlayers(this.state.currentPlayer)
                    );
                  }}
                  previousDisabled={
                    this.state.currentPlayer === 0 || this.invalid()
                  }
                  onClickNext={() => {
                    this.savePlayerStats();
                    this.setState(
                      { currentPlayer: this.state.currentPlayer + 1 },
                      () => this.updatePlayers(this.state.currentPlayer)
                    );
                  }}
                  nextDisabled={this.cannotGoRight() || this.invalid()}
                  player={this.props.squad.players[this.state.currentPlayer]}
                />
              )}

            {this.state.currentStats !== null &&
              this.state.currentPlayer !== null &&
              !!this.state.recentPlayerStats &&
              !!this.props.squad && (
                <div>
                  <div className="stats-recalculation-buttons">
                    <div className="stats-recalculation-buttons-grid">
                      <Button
                        onClick={() =>
                          showConfirmationDialog(
                            () =>
                              this.regeneratePlayerStats(
                                this.state.currentPlayer
                              ),
                            "Regenerate Player Form",
                            "Are you sure?"
                          )
                        }
                        variant="contained"
                        color="secondary"
                      >
                        Regenerate Player Form
                      </Button>
                      <Button
                        onClick={() =>
                          this.explainPlayerStats(this.state.currentPlayer)
                        }
                        variant="contained"
                        color="secondary"
                      >
                        Explain Player Form
                      </Button>
                      <Button
                        onClick={() =>
                          showConfirmationDialog(
                            () =>
                              this.getPositionalDefaults(
                                this.state.currentPlayer
                              ),
                            "Default for Position",
                            "Are you sure?"
                          )
                        }
                        variant="contained"
                        color="primary"
                      >
                        Default for Position
                      </Button>
                      <Button
                        onClick={() =>
                          showConfirmationDialog(
                            () =>
                              this.getPlayerDefaults(this.state.currentPlayer),
                            "Default for Player",
                            "Are you sure?"
                          )
                        }
                        variant="contained"
                        style={{ color: "#ffffff", backgroundColor: "#3fd166" }}
                      >
                        Default for Player
                      </Button>
                      <Button
                        onClick={() =>
                          this.savePlayerDefaults(this.state.currentPlayer)
                        }
                        variant="contained"
                        color="primary"
                        style={{ color: "#ffffff", backgroundColor: "#b63fd1" }}
                      >
                        Update Player Defaults
                      </Button>
                    </div>
                    <RunSimulatorButton
                      isDisabled={
                        !this.props.squad.players[this.state.currentPlayer] ||
                        !this.state.currentStats ||
                        !services.currentGameService.currentMatchId ||
                        noSimulationsAllowed(
                          this.props.match,
                          this.props.userPreferences
                        )
                      }
                      onClick={() => this.refreshSim()}
                      colourOverride={{
                        trueColour: "#aaaaaa",
                        falseColour: "#222222",
                      }}
                    />
                  </div>

                  {!this.state.renaming && (
                    <div className="stats-name">
                      <div className="stats-name-textfield">
                        <EntityAutoSelector
                          value={this.state.currentStats}
                          label={null}
                          canEdit={false}
                          canClear={false}
                          options={this.state.recentPlayerStats}
                          optionService={services.playerStatsService}
                          renderer={(props, option) => (
                            <div {...props}>{option.object.name}</div>
                          )}
                          classes=""
                          maxDropdownSize={5}
                          onSelect={(entity) => {
                            services.playerStatsService
                              .usePlayerStats(
                                this.props.squad.players[
                                  this.state.currentPlayer
                                ].playerId,
                                entity as PlayerStatsWrapper
                              )
                              .then(() =>
                                this.setState({
                                  currentStats: entity as PlayerStatsWrapper,
                                })
                              )
                              .catch((reason) =>
                                showMessage(
                                  `Could not change player stats: ${reason}`,
                                  "error"
                                )
                              );
                          }}
                          newEntityRequiresModal={false}
                        />
                      </div>
                      <TooltipIconButton
                        title="Rename"
                        disabled={false}
                        onClick={() => this.setState({ renaming: true })}
                        icon="edit"
                      />
                    </div>
                  )}
                  {this.state.renaming && (
                    <div className="stats-name">
                      <div className="stats-name-textfield">
                        <TextField
                          value={this.state.currentStats.name}
                          onChange={(event) =>
                            this.setState({
                              nameValid:
                                event.target.value.trimEnd().length > 0,
                              currentStats: new PlayerStatsWrapper(
                                this.state.currentStats.playerStatsId,
                                this.state.currentStats.createdBy,
                                this.state.currentStats.createdAt,
                                event.target.value,
                                this.state.currentStats.playerStats,
                                this.state.currentStats.matchType
                              ),
                            })
                          }
                          placeholder={"Name"}
                          variant="outlined"
                          disabled={false}
                        />
                      </div>
                      <TooltipIconButton
                        title="Done renaming"
                        disabled={!this.state.nameValid}
                        onClick={() => this.setState({ renaming: false })}
                        icon="done"
                      />
                    </div>
                  )}
                </div>
              )}

            {this.props.simulationResult &&
              !!this.props.squad.players[this.state.currentPlayer] && (
                <SimulatorRoute>
                  <div className="player-stats-modal-sim-stats">
                    {this.state.selectedTab === 0 && (
                      <PlayerBattingSimulationDisplay
                        player={
                          this.props.squad.players[this.state.currentPlayer]
                        }
                        simulationResult={this.props.simulationResult}
                        comparedResult={this.props.comparedResult}
                        matchFormat={this.props.matchFormat}
                        gameState={this.props.gameState}
                        team={this.props.teamNumber}
                        fromThisPoint={this.props.fromThisPoint}
                      />
                    )}
                    {this.state.selectedTab === 1 && (
                      <PlayerBowlingSimulationDisplay
                        player={
                          this.props.squad.players[this.state.currentPlayer]
                        }
                        simulationResult={this.props.simulationResult}
                        comparedResult={this.props.comparedResult}
                        matchFormat={this.props.matchFormat}
                        gameState={this.props.gameState}
                        team={this.props.teamNumber}
                        fromThisPoint={this.props.fromThisPoint}
                      />
                    )}
                  </div>
                </SimulatorRoute>
              )}

            {!this.props.simulationResult && (
              <SimulatorRoute>
                {!this.state.simulationLoading && (
                  <div className="centered italic">No Simulation Data</div>
                )}
              </SimulatorRoute>
            )}
          </div>

          {this.state.currentStats !== null &&
            this.state.currentPlayer !== null &&
            !!this.state.recentPlayerStats &&
            !!this.props.squad && (
              <div className="stats-body">
                <Box className="scorecard-top-buttons">
                  <Tabs
                    value={this.state.selectedTab}
                    onChange={(value, newValue) =>
                      this.setState({ selectedTab: newValue })
                    }
                    aria-label="Stats Tabs"
                  >
                    <Tab
                      label="Batting"
                      id="stats-tab-0"
                      aria-controls="stats-tabpanel-0"
                      style={{ padding: "0px" }}
                    />
                    <Tab
                      label="Bowling"
                      id="stats-tab-1"
                      aria-controls="stats-tabpanel-1"
                      style={{ padding: "0px" }}
                    />
                  </Tabs>

                  <div className="compared-selector-and-steal-button">
                    <ComparedUserSelector />
                    {this.state.comparedStats && (
                      <StealStatsButton
                        comparedUserName={this.props.comparedUserName}
                        disabled={areStatsEqual(
                          this.state.currentStats,
                          this.state.comparedStats,
                          PlayerStatsWrapper.getAllProperties(
                            this.state.selectedTab === 0
                              ? StealAllPage.PLAYER_BATTING_STATS
                              : StealAllPage.PLAYER_BOWLING_STATS
                          ),
                          "playerStats"
                        )}
                        tooltipMessage={getToolTipMessage(
                          this.state.currentStats,
                          this.state.comparedStats,
                          PlayerStatsWrapper.getAllProperties(
                            this.state.selectedTab === 0
                              ? StealAllPage.PLAYER_BATTING_STATS
                              : StealAllPage.PLAYER_BOWLING_STATS
                          ),
                          this.props.comparedUserName,
                          "playerStats"
                        )}
                        onClickHandler={this.onStealHandler}
                      />
                    )}
                  </div>
                </Box>

                {this.state.formExplanation !== null && (
                  <PlayerFormExplanationDisplay
                    formExplanation={this.state.formExplanation}
                    selectedTab={this.state.selectedTab}
                  />
                )}

                <TabPanel value={this.state.selectedTab} index={0}>
                  <PlayerBattingStatsDisplay
                    onUpdate={(currentStats) =>
                      this.onStatsUpdate(currentStats)
                    }
                    onValid={(property, value) =>
                      this.onPropertyChangeValid(property, value)
                    }
                    onArrayElementValid={(property, index, value) =>
                      this.onArrayPropertyChangeValid(property, index, value)
                    }
                    currentStats={this.state.currentStats}
                    comparedStats={this.state.comparedStats}
                    comparedUserName={this.props.comparedUserName}
                    userPreferences={this.props.userPreferences}
                  />
                </TabPanel>

                <TabPanel value={this.state.selectedTab} index={1}>
                  <PlayerBowlingStatsDisplay
                    onUpdate={(currentStats) =>
                      this.onStatsUpdate(currentStats)
                    }
                    onValid={(property, value) =>
                      this.onPropertyChangeValid(property, value)
                    }
                    onArrayElementValid={(property, index, value) =>
                      this.onArrayPropertyChangeValid(property, index, value)
                    }
                    currentStats={this.state.currentStats}
                    comparedStats={this.state.comparedStats}
                    comparedUserName={this.props.comparedUserName}
                  />
                </TabPanel>
              </div>
            )}

          <FormSettingsModal
            open={this.state.formSettingsModalOpen}
            onCancel={() => {
              this.setState({ formSettingsModalOpen: false });
            }}
            onProceed={(settings) => this.updatePreferences(settings)}
          />
        </LoadingOverlay>
      </CreationDialog>
    );
  }
}
