import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Component, createRef, ReactNode } from "react";
import LoadingOverlay from "@ronchalant/react-loading-overlay";
import { SimulationResult } from "../../types/simulator/simulation-result";
import { GameState } from "../../types/entities/game-state";
import { Team } from "../../types/entities/team";
import { MatchFormat } from "../../types/entities/match-format";
import { format, getHeaders, sortResults } from "./simulator-utils";
import {
  getTruePriceResults,
  SimulatorScenario,
  UserPreferences,
} from "../../types/preferences/preferences";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import { CalculatedStatisticType } from "../../types/enums/statistic-type";
import {
  compareValues,
  comparisonCellStyle,
  findScenarioResultsFromMap,
  getHalfTimeOddsConfidenceColour,
} from "../component-utils";

interface Props {
  gameState: GameState;
  latestResults: Map<string, [SimulatorScenario, SimulationResult]>;
  comparedResults: Map<string, [SimulatorScenario, SimulationResult]>;
  comparedUserName: string;
  matchFormat: MatchFormat;
  team1: Team;
  team2: Team;
  loading: boolean;
  collapsed: boolean;
  userPreferences: UserPreferences;
  toggleCollapse: () => void;
}

interface State {
  scrollTop: number;
}

export class SimulatorRunsLineDisplay extends Component<Props, State> {
  private tableRef;

  constructor(props) {
    super(props);
    this.state = {
      scrollTop: 0,
    };
    this.tableRef = createRef();
  }

  componentDidUpdate(): void {
    if (!!this.tableRef && !!this.tableRef.current) {
      this.tableRef.current.scrollTop = this.state.scrollTop;
    }
  }

  private getProbability(
    innings: number,
    simulationResult: SimulationResult,
    runsLine: number
  ): number {
    if (!!simulationResult) {
      const occurrences =
        !!simulationResult.runsLines.get(innings) &&
        simulationResult.runsLines.get(innings).get(runsLine);
      return 1 / (occurrences / simulationResult.numberOfSimulations);
    } else {
      return NaN;
    }
  }

  private buildTableCell(
    scenario: SimulatorScenario,
    runsLine: number,
    probability: number,
    comparedProbability: number
  ): ReactNode {
    const style = !!comparedProbability
      ? {
          ...comparisonCellStyle,
          color: compareValues(1 / comparedProbability, 1 / probability),
        }
      : { backgroundColor: scenario.colour };

    return (
      <TableCell
        key={`runs-line-${runsLine}-${scenario.name}`}
        align="right"
        style={style}
        id="simulation-table-cell"
      >
        {format(probability)}
      </TableCell>
    );
  }

  private handleScroll(event) {
    if (
      this.props.latestResults &&
      getTruePriceResults(this.props.latestResults)
    ) {
      this.setState({ scrollTop: event.currentTarget.scrollTop });
    }
  }

  public render() {
    const rows: ReactNode[] = [];
    const headers: ReactNode[] = [];
    const innings = this.props.gameState && this.props.gameState.innings;
    const truePriceResults =
      this.props.latestResults && getTruePriceResults(this.props.latestResults);
    const comparedTruePriceResults =
      this.props.comparedResults &&
      getTruePriceResults(this.props.comparedResults);
    let median = null;

    if (!!truePriceResults) {
      const sortedResults: Map<string, [SimulatorScenario, SimulationResult]> =
        sortResults(this.props.latestResults);
      headers.push(
        ...getHeaders(
          sortedResults,
          truePriceResults[1],
          this.props.team1,
          this.props.team2,
          "runs-line",
          false
        )
      );
      median = truePriceResults[1].inningsMedians.get(
        innings === 1
          ? CalculatedStatisticType.TEAM_1_RUNS
          : CalculatedStatisticType.TEAM_2_RUNS
      );
      const runsLines = truePriceResults[1].runsLines.get(innings);
      const halfTimeOdds: Map<number, number[]> =
        truePriceResults[1].halfTimeOddsForRunsLines;
      const comparedHalfTimeOdds: Map<number, number[]> =
        comparedTruePriceResults &&
        comparedTruePriceResults[1].halfTimeOddsForRunsLines;
      const meanScore = truePriceResults[1].inningsMeans.get(
        innings === 1
          ? CalculatedStatisticType.TEAM_1_RUNS
          : CalculatedStatisticType.TEAM_2_RUNS
      );
      const minRunsLine = !!this.props.userPreferences
        ? Math.floor(meanScore - this.props.userPreferences.runsLineMargin)
        : 0;
      const maxRunsLine = !!this.props.userPreferences
        ? Math.floor(meanScore + this.props.userPreferences.runsLineMargin)
        : 0;

      runsLines.forEach((occurrences, runsLine) => {
        if (runsLine >= minRunsLine && runsLine <= maxRunsLine) {
          const halfTimeOddsAtRuns = format(1 / halfTimeOdds.get(runsLine)[0]);
          const halfTimeOddsConfidenceColour = getHalfTimeOddsConfidenceColour(
            halfTimeOdds.get(runsLine)[1]
          );
          const comparedHalfTimeOddsAtRuns =
            comparedHalfTimeOdds &&
            comparedHalfTimeOdds.get(runsLine) &&
            format(1 / comparedHalfTimeOdds.get(runsLine)[0]);
          const comparedHalfTimeOddsConfidenceColour =
            comparedHalfTimeOdds &&
            comparedHalfTimeOdds.get(runsLine) &&
            getHalfTimeOddsConfidenceColour(
              comparedHalfTimeOdds.get(runsLine)[1]
            );
          const scenarioValues: ReactNode[] = [];
          const comparedValues: ReactNode[] = [];
          sortedResults.forEach(([scenario, simulationResult], scenarioId) => {
            const currentProbability = this.getProbability(
              innings,
              simulationResult,
              runsLine
            );
            scenarioValues.push(
              this.buildTableCell(scenario, runsLine, currentProbability, null)
            );

            const comparedResults =
              !!this.props.comparedResults &&
              findScenarioResultsFromMap(
                scenario,
                this.props.comparedResults
              )[1];
            const comparedProbability = this.getProbability(
              innings,
              comparedResults,
              runsLine
            );
            comparedValues.push(
              this.buildTableCell(
                scenario,
                runsLine,
                comparedProbability,
                currentProbability
              )
            );
          });

          rows.push(
            <TableRow key={`runs-line-${runsLine}`}>
              <TableCell scope="row" align="center" id="simulation-table-cell">
                {runsLine}
              </TableCell>
              {this.props.gameState.innings === 1 && (
                <TableCell
                  scope="row"
                  id="simulation-table-cell"
                  align="center"
                  style={{
                    color: halfTimeOddsConfidenceColour,
                  }}
                >
                  {halfTimeOddsAtRuns}
                </TableCell>
              )}
              {scenarioValues}
            </TableRow>
          );

          this.props.comparedResults.size > 0 &&
            rows.push(
              <TableRow key={`runs-line-${runsLine}-comparison`}>
                <TableCell
                  scope="row"
                  align="center"
                  id="simulation-table-cell"
                  style={comparisonCellStyle}
                >
                  {runsLine}
                </TableCell>
                {this.props.gameState.innings === 1 && (
                  <TableCell
                    scope="row"
                    align="center"
                    id="simulation-table-cell"
                    style={{
                      ...comparisonCellStyle,
                      color: comparedHalfTimeOddsConfidenceColour,
                    }}
                  >
                    {comparedHalfTimeOddsAtRuns}
                  </TableCell>
                )}
                {comparedValues}
              </TableRow>
            );
        }
      });
    }

    return (
      <div className="simulator-container">
        <div className="simulator-title">
          Runs Line {!!median && `(Median ${median})`}
          <TooltipIconButton
            icon={this.props.collapsed ? "expand_more" : "expand_less"}
            onClick={() => this.props.toggleCollapse()}
            title={this.props.collapsed ? "Expand" : "Collapse"}
          />
        </div>
        {!this.props.collapsed && (
          <div>
            <LoadingOverlay
              active={this.props.loading}
              spinner
              text="Simulating..."
            >
              <TableContainer
                ref={this.tableRef}
                className="runs-line-table-container"
                onScroll={(event) => this.handleScroll(event)}
              >
                <Table
                  aria-label="simulator runs line table"
                  size="small"
                  stickyHeader
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Runs</TableCell>
                      {this.props.gameState.innings === 1 && (
                        <TableCell align="center">Half time odds</TableCell>
                      )}
                      {headers}
                    </TableRow>
                  </TableHead>
                  <TableBody>{rows}</TableBody>
                </Table>
              </TableContainer>
            </LoadingOverlay>
          </div>
        )}
      </div>
    );
  }
}
