import { Component } from "react";
import {
  MatchSpecificSettings,
  cloneMatchSpecificSettings,
} from "../../types/preferences/admin-preferences";
import { NumberSelector } from "../entity-management/entity-selectors/number-selector";
import { observableFrom, propsEqual } from "../component-utils";
import { EnumSelector } from "../entity-management/entity-selectors/enum-selector";
import { MatchType, matchTypeNames } from "../../types/enums/match-type";
import { Chip, Icon, Switch, TextField } from "@mui/material";
import { EntityAutoSelector } from "../entity-management/entity-selectors/entity-auto-selector";
import { services } from "../../types/services";
import { Team } from "../../types/entities/team";
import { Subscription } from "rxjs";
import { UUID } from "../../types/uuid";
import { Series } from "../../types/entities/series";
import { MatchFormat } from "../../types/entities/match-format";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import TimeAgo from "timeago-react";
import { ConfidenceSettingTable } from "./confidence-setting-table";
import {
  PercentDistributionValue,
  PercentsDistributionBiasAdjuster,
} from "../stats-editing-components/percent-distribution-bias-adjuster";

interface Props {
  initialSettings: MatchSpecificSettings;
  onUpdate: (matchSpecificSettings: MatchSpecificSettings) => void;
  onDelete: () => void;
  onExpand: () => void;
  expanded: boolean;
}

interface State {
  currentSettings: MatchSpecificSettings;
  allTeams: Team[];
  allSeries: Series[];
  allFormats: MatchFormat[];
  renaming: boolean;
  powerplayPercentsExpanded: boolean;
}

export class MatchSpecificSettingsComponent extends Component<Props, State> {
  private subscriptions: Subscription[] = [];

  constructor(props) {
    super(props);
    this.state = {
      currentSettings: cloneMatchSpecificSettings(props.initialSettings),
      allTeams: [],
      allSeries: [],
      allFormats: [],
      renaming: false,
      powerplayPercentsExpanded: false,
    };
  }

  componentDidMount(): void {
    this.subscriptions.push(
      services.teamService
        .getAll()
        .subscribe((teams: Team[]) => this.setState({ allTeams: teams }))
    );
    this.subscriptions.push(
      services.seriesService
        .getAll()
        .subscribe((series: Series[]) => this.setState({ allSeries: series }))
    );
    this.subscriptions.push(
      services.matchFormatService
        .getAll()
        .subscribe((formats: MatchFormat[]) =>
          this.setState({ allFormats: formats })
        )
    );
  }

  componentDidUpdate(prevProps) {
    if (!propsEqual(this.props, prevProps)) {
      this.setState({
        currentSettings: cloneMatchSpecificSettings(this.props.initialSettings),
      });
    }
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }

  private update() {
    if (this.valid()) {
      this.props.onUpdate(this.state.currentSettings);
    }
  }

  private updateConfidences(type: string, field: string, value: number) {
    const toUpdate: MatchSpecificSettings = {
      ...this.state.currentSettings,
      adminConfidences: {
        ...this.state.currentSettings.adminConfidences,
        [type]: {
          ...this.state.currentSettings.adminConfidences[type],
          [field]: value,
        },
      },
    };
    this.setState({ currentSettings: toUpdate }, () => {
      this.update();
    });
  }

  private updateField(field: string, value: number | string) {
    const toUpdate = {
      ...this.state.currentSettings,
      [field]: value,
    };
    this.setState({ currentSettings: toUpdate }, () => {
      this.update();
    });
  }

  private updateBias(property: PercentDistributionValue, value: number) {
    const toUpdate = {
      ...this.state.currentSettings,
      powerplayPercentBiases: {
        ...this.state.currentSettings.powerplayPercentBiases,
        [property]: value,
      },
    };
    this.setState({ currentSettings: toUpdate }, () => {
      this.update();
    });
  }

  private toggleUseNewPowerplayModule() {
    const toUpdate = {
      ...this.state.currentSettings,
      useNewPowerplayModule: !this.state.currentSettings.useNewPowerplayModule,
    };
    this.setState({ currentSettings: toUpdate }, () => {
      this.update();
    });
  }

  private delete() {
    this.props.onDelete();
  }

  private valid(): boolean {
    return true;
  }

  private getTeamName(team: UUID): string {
    const foundTeam = this.state.allTeams.find(
      (t) => t.teamId.value === team.value
    );
    return (!!foundTeam && foundTeam.toString()) || "Unknown";
  }

  private getFormatName(format: UUID): string {
    const foundFormat = this.state.allFormats.find(
      (t) => t.matchFormatId.value === format.value
    );
    return (!!foundFormat && foundFormat.toString()) || "Unknown";
  }

  private getSeriesName(series: UUID): string {
    const foundSeries = this.state.allSeries.find(
      (t) => t.seriesId.value === series.value
    );
    return (!!foundSeries && foundSeries.toString()) || "Unknown";
  }

  private addMatchType(matchType: MatchType) {
    const settings = this.state.currentSettings;
    settings.matchTypes.push(matchType);
    this.setState({ currentSettings: settings }, () => this.update());
  }

  private removeMatchType(index: number) {
    const settings = this.state.currentSettings;
    settings.matchTypes.splice(index, 1);
    this.setState({ currentSettings: settings }, () => this.update());
  }

  private addTeam(team: UUID) {
    const settings = this.state.currentSettings;
    settings.teams.push(team);
    this.setState({ currentSettings: settings }, () => this.update());
  }

  private removeTeam(index: number) {
    const settings = this.state.currentSettings;
    settings.teams.splice(index, 1);
    this.setState({ currentSettings: settings }, () => this.update());
  }

  private addFormat(format: UUID) {
    const settings = this.state.currentSettings;
    settings.matchFormats.push(format);
    this.setState({ currentSettings: settings }, () => this.update());
  }

  private removeFormat(index: number) {
    const settings = this.state.currentSettings;
    settings.matchFormats.splice(index, 1);
    this.setState({ currentSettings: settings }, () => this.update());
  }

  private addSeries(series: UUID) {
    const settings = this.state.currentSettings;
    settings.series.push(series);
    this.setState({ currentSettings: settings }, () => this.update());
  }

  private removeSeries(index: number) {
    const settings = this.state.currentSettings;
    settings.series.splice(index, 1);
    this.setState({ currentSettings: settings }, () => this.update());
  }

  public render() {
    return (
      <div className="match-specific-content-component">
        <div
          className="match-specific-settings-summary"
          onClick={() => this.props.onExpand()}
        >
          <div className="admin-settings-match-specific-buttons">
            <TooltipIconButton
              title={"Rename"}
              onClick={() => this.setState({ renaming: true })}
              icon={"edit"}
              disabled={this.state.renaming}
            />
            {!this.state.renaming && this.state.currentSettings.name}
            {this.state.renaming && (
              <TextField
                value={
                  this.state.currentSettings === null
                    ? ""
                    : this.state.currentSettings.name
                }
                onChange={(event) =>
                  this.setState({
                    currentSettings: {
                      ...this.state.currentSettings,
                      name: event.target.value,
                    },
                  })
                }
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    this.setState({ renaming: false }, () => this.update());
                  }
                }}
                onBlur={(event) =>
                  this.setState({ renaming: false }, () =>
                    this.updateField("name", event.target.value)
                  )
                }
                variant="outlined"
                disabled={false}
              />
            )}
          </div>

          <div className="admin-settings-match-specific-buttons">
            <TimeAgo
              datetime={new Date(this.state.currentSettings.createdAt)}
            />
            <TooltipIconButton
              title={"Delete"}
              onClick={() => this.delete()}
              icon={"delete"}
            />
          </div>
        </div>
        {this.props.expanded && (
          <div className="match-specific-content-expanded">
            <div className="match-specific-parameters-content">
              <div className="match-specific-settings-section-header">
                Applies To Matches
              </div>
              <hr />
              <div className="match-specific-settings-parameters">
                <div className="match-specific-settings-parameter-selector">
                  Match Types
                  <EnumSelector
                    enumObject={MatchType}
                    value={null}
                    readableValues={matchTypeNames}
                    onSelect={(matchType) => this.addMatchType(matchType)}
                    disabled={false}
                  />
                  <div>
                    {this.state.currentSettings.matchTypes.map(
                      (matchType, index) => (
                        <Chip
                          label={matchTypeNames[matchType]}
                          key={`already-selected-matchType-${index}`}
                          variant="outlined"
                          onDelete={() => this.removeMatchType(index)}
                        />
                      )
                    )}
                  </div>
                </div>
                <div className="match-specific-settings-parameter-selector">
                  Formats
                  <EntityAutoSelector
                    value={null}
                    label=""
                    options={observableFrom(this.state.allFormats)}
                    optionService={services.matchFormatService}
                    onSelect={(entity: MatchFormat) =>
                      this.addFormat(entity.matchFormatId)
                    }
                    canAddNew={false}
                    maxDropdownSize={20}
                    canEdit={false}
                  />
                  <div>
                    {this.state.currentSettings.matchFormats.map(
                      (format, index) => (
                        <Chip
                          label={this.getFormatName(format)}
                          key={`already-selected-format-${index}`}
                          variant="outlined"
                          onDelete={() => this.removeFormat(index)}
                        />
                      )
                    )}
                  </div>
                </div>
                <div className="match-specific-settings-parameter-selector">
                  Series
                  <EntityAutoSelector
                    value={null}
                    label=""
                    options={observableFrom(this.state.allSeries)}
                    optionService={services.seriesService}
                    onSelect={(entity: Series) =>
                      this.addSeries(entity.seriesId)
                    }
                    canAddNew={false}
                    maxDropdownSize={20}
                    canEdit={false}
                  />
                  <div>
                    {this.state.currentSettings.series.map((series, index) => (
                      <Chip
                        label={this.getSeriesName(series)}
                        key={`already-selected-series-${index}`}
                        variant="outlined"
                        onDelete={() => this.removeSeries(index)}
                      />
                    ))}
                  </div>
                </div>
                <div className="match-specific-settings-parameter-selector">
                  Teams
                  <EntityAutoSelector
                    value={null}
                    label=""
                    options={observableFrom(this.state.allTeams)}
                    optionService={services.teamService}
                    onSelect={(entity: Team) => this.addTeam(entity.teamId)}
                    canAddNew={false}
                    canEdit={false}
                  />
                  <div>
                    {this.state.currentSettings.teams.map((team, index) => (
                      <Chip
                        label={this.getTeamName(team)}
                        key={`already-selected-team-${index}`}
                        variant="outlined"
                        onDelete={() => this.removeTeam(index)}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="match-specific-settings-content">
              <div className="match-specific-settings-section-header">
                Settings
              </div>
              <hr />
              <div className="admin-settings-default-content">
                <div>
                  <div className="admin-settings-default-content-section-header">
                    Thought Wicket Multiplier
                  </div>
                  <div className="thought-wicket-multiplier-settings">
                    <div className="thought-wicket-multiplier-section">
                      <div>Batting First</div>
                      <NumberSelector
                        label={"Multiplier at last ball"}
                        className="stats-modal-number-selector"
                        min={0}
                        max={10}
                        step={0.01}
                        decimalPlaces={2}
                        initial={
                          this.state.currentSettings.twmAtLastBallInnings1
                        }
                        onValid={(valid: number) =>
                          this.updateField("twmAtLastBallInnings1", valid)
                        }
                      />
                      <NumberSelector
                        label={"Addition per ball"}
                        className="stats-modal-number-selector"
                        min={0}
                        max={1}
                        step={0.0001}
                        decimalPlaces={4}
                        initial={this.state.currentSettings.twmAdditionInnings1}
                        onValid={(valid: number) =>
                          this.updateField("twmAdditionInnings1", valid)
                        }
                      />
                    </div>
                    <div className="thought-wicket-multiplier-section">
                      <div>Batting Second</div>
                      <NumberSelector
                        label={"Multiplier at last ball"}
                        className="stats-modal-number-selector"
                        min={0}
                        max={10}
                        step={0.01}
                        decimalPlaces={2}
                        initial={
                          this.state.currentSettings.twmAtLastBallInnings2
                        }
                        onValid={(valid: number) =>
                          this.updateField("twmAtLastBallInnings2", valid)
                        }
                      />
                      <NumberSelector
                        label={"Addition per ball"}
                        className="stats-modal-number-selector"
                        min={0}
                        max={1}
                        step={0.0001}
                        decimalPlaces={4}
                        initial={this.state.currentSettings.twmAdditionInnings2}
                        onValid={(valid: number) =>
                          this.updateField("twmAdditionInnings2", valid)
                        }
                      />
                    </div>
                  </div>
                </div>
                <hr />
                <div>
                  <div className="admin-settings-default-content-section-header">
                    Second Innings Push Adjust
                  </div>
                  <div className="second-innings-push-adjust-settings">
                    <NumberSelector
                      label={"Adjustment"}
                      className="stats-modal-number-selector"
                      min={0}
                      max={1}
                      step={0.01}
                      decimalPlaces={2}
                      initial={
                        this.state.currentSettings.secondInningsPushAdjustment
                      }
                      onValid={(valid: number) =>
                        this.updateField("secondInningsPushAdjustment", valid)
                      }
                    />
                    <NumberSelector
                      label={"Dilution"}
                      className="stats-modal-number-selector"
                      min={0}
                      max={1}
                      step={0.01}
                      decimalPlaces={2}
                      initial={
                        this.state.currentSettings.secondInningsPushDilution
                      }
                      onValid={(valid: number) =>
                        this.updateField("secondInningsPushDilution", valid)
                      }
                    />
                  </div>
                </div>
                <hr />
                <div>
                  <div className="admin-settings-default-content-section-header">
                    Powerplay Settings
                  </div>
                  <div className="second-innings-push-adjust-settings">
                    <div className="stats-modal-number-selector">
                      <div>Use New Powerplay Module</div>
                      <Switch
                        checked={
                          this.state.currentSettings.useNewPowerplayModule
                        }
                        onChange={() => this.toggleUseNewPowerplayModule()}
                        disabled={false}
                      />
                    </div>
                    <NumberSelector
                      label={"Powerplay Default Push Adjustment"}
                      className="stats-modal-number-selector"
                      min={-100}
                      max={100}
                      step={1}
                      decimalPlaces={0}
                      initial={this.state.currentSettings.powerplayPushAdjust}
                      onValid={(valid: number) =>
                        this.updateField("powerplayPushAdjust", valid)
                      }
                    />
                    <NumberSelector
                      label={"Powerplay Wicket Bias"}
                      className="stats-modal-number-selector"
                      min={0}
                      max={10}
                      step={0.01}
                      decimalPlaces={2}
                      initial={this.state.currentSettings.powerplayWicketBias}
                      onValid={(valid: number) =>
                        this.updateField("powerplayWicketBias", valid)
                      }
                    />
                    <div
                      className="powerplay-percents-expander"
                      onClick={() =>
                        this.setState({
                          powerplayPercentsExpanded:
                            !this.state.powerplayPercentsExpanded,
                        })
                      }
                    >
                      <div>Powerplay Percent Bias</div>
                      <Icon>
                        {this.state.powerplayPercentsExpanded
                          ? "expand_less"
                          : "expand_more"}
                      </Icon>
                    </div>
                    {this.state.powerplayPercentsExpanded && (
                      <PercentsDistributionBiasAdjuster
                        biases={
                          this.state.currentSettings.powerplayPercentBiases
                        }
                        updateValue={(
                          property: PercentDistributionValue,
                          value: number
                        ) => this.updateBias(property, value)}
                      />
                    )}
                  </div>
                </div>
                <hr />
                <div>
                  <div className="admin-settings-default-content-section-header">
                    Surge Settings
                  </div>
                  <div className="thought-wicket-multiplier-settings">
                    <div className="thought-wicket-multiplier-section">
                      Innings 1
                      <NumberSelector
                        label={"Strike Rate Bias"}
                        className="stats-modal-number-selector"
                        min={0}
                        max={10}
                        step={0.01}
                        decimalPlaces={4}
                        initial={
                          this.state.currentSettings.surgeStrikeRateBiasInnings1
                        }
                        onValid={(valid: number) =>
                          this.updateField("surgeStrikeRateBiasInnings1", valid)
                        }
                      />
                      <NumberSelector
                        label={"Wicket % Bias"}
                        className="stats-modal-number-selector"
                        min={0}
                        max={10}
                        step={0.01}
                        decimalPlaces={4}
                        initial={
                          this.state.currentSettings.surgeWicketBiasInnings1
                        }
                        onValid={(valid: number) =>
                          this.updateField("surgeWicketBiasInnings1", valid)
                        }
                      />
                    </div>
                    <div className="thought-wicket-multiplier-section">
                      Innings 2
                      <NumberSelector
                        label={"Strike Rate Bias"}
                        className="stats-modal-number-selector"
                        min={0}
                        max={10}
                        step={0.01}
                        decimalPlaces={4}
                        initial={
                          this.state.currentSettings.surgeStrikeRateBiasInnings2
                        }
                        onValid={(valid: number) =>
                          this.updateField("surgeStrikeRateBiasInnings2", valid)
                        }
                      />
                      <NumberSelector
                        label={"Wicket % Bias"}
                        className="stats-modal-number-selector"
                        min={0}
                        max={10}
                        step={0.01}
                        decimalPlaces={4}
                        initial={
                          this.state.currentSettings.surgeWicketBiasInnings2
                        }
                        onValid={(valid: number) =>
                          this.updateField("surgeWicketBiasInnings2", valid)
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              {this.state.currentSettings.teams.length === 0 && (
                <div>
                  <hr />
                  <div className="admin-settings-default-content-section-header">
                    Confidence Limits
                  </div>
                  <ConfidenceSettingTable
                    adminConfidences={
                      this.state.currentSettings.adminConfidences
                    }
                    onUpdate={(type, field, value) =>
                      this.updateConfidences(type, field, value)
                    }
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}
