import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { Component } from "react";
import { GameState } from "../../../types/entities/game-state";
import { Team } from "../../../types/entities/team";
import { SuperOverDecision } from "../../../types/enums/decision-type";
import { propsEqual } from "../../component-utils";
import { NumberSelector } from "../../entity-management/entity-selectors/number-selector";
import { CreationDialog } from "../../my-matches/match-creation-modals/creation-dialog";

interface Props {
  open: boolean;
  onCancel: () => void;
  onProceed: (decision: SuperOverDecision) => void;
  gameState: GameState;
  team1: Team;
  team2: Team;
}

interface State {
  decision: SuperOverDecision;
  ballsPerOverValid: boolean;
  maxWicketsValid: boolean;
}

export class SuperOverDecisionModal extends Component<Props, State> {
  private static readonly DEFAULT_DECISION = {
    superOverTaken: false,
    ballsPerOver: 6,
    maxWickets: 2,
    teamBattingFirst: 2,
  };

  constructor(props) {
    super(props);
    this.state = {
      decision: SuperOverDecisionModal.DEFAULT_DECISION,
      ballsPerOverValid: true,
      maxWicketsValid: true,
    };
  }

  componentDidMount(): void {
    this.setState({ decision: SuperOverDecisionModal.DEFAULT_DECISION });
  }

  componentDidUpdate(prevProps): void {
    if (!propsEqual(prevProps, this.props)) {
      this.setState({ decision: SuperOverDecisionModal.DEFAULT_DECISION });
    }
  }

  private invalid() {
    return !this.state.ballsPerOverValid || !this.state.maxWicketsValid;
  }

  private handleDecisionChange(event) {
    const decision: string = event.target.value;
    if (decision === "take_super_over") {
      this.setState({
        decision: { ...this.state.decision, superOverTaken: true },
      });
    } else {
      this.setState({
        decision: SuperOverDecisionModal.DEFAULT_DECISION,
        ballsPerOverValid: true,
        maxWicketsValid: true,
      });
    }
  }

  private handleBattingTeamChange(event) {
    const decision: string = event.target.value;
    if (decision === "team_1") {
      this.setState({
        decision: { ...this.state.decision, teamBattingFirst: 1 },
      });
    } else {
      this.setState({
        decision: { ...this.state.decision, teamBattingFirst: 2 },
      });
    }
  }

  public render() {
    return (
      <CreationDialog
        open={this.props.open}
        label={"Decision Required"}
        invalid={this.invalid()}
        disabled={false}
        onCancel={() => this.props.onCancel()}
        onProceed={() => this.props.onProceed(this.state.decision)}
        proceedText="OK"
        colour="#ebae34"
      >
        <FormControl>
          <FormLabel>
            The game has ended in a tie - will there be a super over?
          </FormLabel>
          <RadioGroup
            row
            onChange={(event) => this.handleDecisionChange(event)}
            name="decision-group"
            value={
              this.state.decision.superOverTaken
                ? "take_super_over"
                : "do_not_take_super_over"
            }
          >
            <FormControlLabel
              value={"take_super_over"}
              control={<Radio />}
              label={"Yes"}
            />
            <FormControlLabel
              value={"do_not_take_super_over"}
              control={<Radio />}
              label={"No"}
            />
          </RadioGroup>
        </FormControl>

        {this.state.decision.superOverTaken && (
          <div>
            <FormControl>
              <FormLabel>Which team bats first?</FormLabel>
              <RadioGroup
                row
                onChange={(event) => this.handleBattingTeamChange(event)}
                name="decision-group"
                value={
                  this.state.decision.teamBattingFirst === 1
                    ? "team_1"
                    : "team_2"
                }
              >
                <FormControlLabel
                  value={"team_1"}
                  control={<Radio />}
                  label={this.props.team1.name}
                />
                <FormControlLabel
                  value={"team_2"}
                  control={<Radio />}
                  label={this.props.team2.name}
                />
              </RadioGroup>
            </FormControl>

            <NumberSelector
              className="match-format-number-selector"
              label="Number of balls per over:"
              min={1}
              max={100}
              initial={this.state.decision.ballsPerOver}
              onValid={(newValue) =>
                this.setState({
                  decision: { ...this.state.decision, ballsPerOver: newValue },
                })
              }
              onInvalid={() => this.setState({ ballsPerOverValid: false })}
            />
            <NumberSelector
              className="match-format-number-selector"
              label="Max wickets each:"
              min={1}
              max={100}
              initial={this.state.decision.maxWickets}
              onValid={(newValue) =>
                this.setState({
                  decision: { ...this.state.decision, maxWickets: newValue },
                })
              }
              onInvalid={() => this.setState({ maxWicketsValid: false })}
            />
          </div>
        )}
      </CreationDialog>
    );
  }
}
