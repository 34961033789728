import { Component } from "react";
import { Chart } from "react-chartjs-2";
import "chartjs-adapter-moment";
import { services } from "../../../types/services";
import { SimulatorOversDistributionDisplay } from "../../simulator-page/simulator-overs-distribution-display";
import {
  MarketType,
  marketTypeReadableNames,
} from "../../../types/betfair/betfair-message";

interface Props {
  marketHistory: Map<string, Map<number, number>>;
  selection: string;
  marketType: MarketType;
}

export class BetfairMarketHistoryDisplay extends Component<Props> {
  private buildLabels() {
    const allLabels: number[] = [];

    if (!!this.props.marketHistory) {
      this.props.marketHistory.forEach(
        (data: Map<number, number>, selection: string) => {
          data.forEach((price, date) => {
            allLabels.push(date);
          });
        }
      );
    }

    return [...new Set(allLabels)].sort();
  }

  public render() {
    const datasets = [];
    const labels: number[] = this.buildLabels();
    if (!!this.props.marketHistory) {
      this.props.marketHistory.forEach((userMap, user) => {
        const lineData: any[] = [];
        let currentPrice = null;
        labels.forEach((time: number) => {
          const userPrice = userMap.get(time);
          if (!!userPrice) {
            lineData.push(userPrice);
            currentPrice = userPrice;
          } else {
            lineData.push(currentPrice);
          }
        });
        if (user !== "Betfair") {
          if (user !== services.keycloakService.getUserId()) {
            services.keycloakService
              .getUsers()
              .filter((u) => u.id === user)
              .forEach((u) => (user = u.name));
          } else {
            user = services.keycloakService.getUserName();
          }
        }
        datasets.push({
          type: "line" as const,
          label: user,
          fill: false,
          data: lineData,
          borderColor:
            SimulatorOversDistributionDisplay.playerColours[
              datasets.length % 11
            ],
        });
      });
    }

    return (
      <div className="betfair-display">
        <div className="separator-title">
          Market History ({marketTypeReadableNames[this.props.marketType]} -{" "}
          {this.props.selection}):
        </div>

        <div>
          <Chart
            type="line"
            height={400}
            options={{
              scales: {
                x: {
                  type: "time",
                },
              },
              responsive: true,
              maintainAspectRatio: false,
            }}
            data={{
              labels,
              datasets,
            }}
          />
        </div>
      </div>
    );
  }
}
