import { Component, ReactElement } from "react";
import { nanSafeWithDefault, round } from "../../../types/util-functions";
import { propsEqual } from "../../component-utils";
import TooltipIconButton from "../../navigation-bar/tooltip-icon-button";

interface Props {
  label: string;
  min: number;
  max: number;
  step: number;
  decimalPlaces: number;
  initial: number;
  onValid: (valid: number) => void;
  onInvalid?: (invalid: number) => void;
  className: string;
  textFieldClassName: string;
  buttonsClassName: string;
  disabled: boolean;
  testId: string;
  onManualEdit: () => void;
  onManualEditFinished: () => void;
  button: ReactElement;
  color: string;
}

interface State {
  value: number;
  valid: boolean;
  manualEditing: boolean;
  editValue: string;
}

export class NumberSelector extends Component<Props, State> {
  static defaultProps = {
    label: "",
    step: 1,
    decimalPlaces: 0,
    className: "number-selector",
    textFieldClassName: "small-number-selector",
    buttonsClassName: "number-selector-buttons",
    testId: "default-test-id",
    onManualEdit: () => {},
    onManualEditFinished: () => {},
    onInvalid: () => {},
    disabled: false,
    button: null,
    color: "white",
  };

  constructor(props) {
    super(props);
    const initial = Number(round(this.props.initial, this.props.decimalPlaces));
    this.state = {
      value: initial,
      valid: this.valid(initial),
      manualEditing: false,
      editValue: null,
    };
  }

  componentDidMount(): void {
    this.update(Number(round(this.props.initial, this.props.decimalPlaces)));
  }

  componentDidUpdate(prevProps): void {
    if (!propsEqual(prevProps, this.props)) {
      this.update(Number(round(this.props.initial, this.props.decimalPlaces)));
    }
  }

  private increment() {
    this.onChange(
      this.valid(this.state.value)
        ? Number(this.state.value) + this.props.step
        : this.props.min
    );
  }

  private decrement() {
    this.onChange(
      this.valid(this.state.value)
        ? Number(this.state.value) - this.props.step
        : this.props.max
    );
  }

  private onChange(newValue: any) {
    this.update(this.sanitize(newValue), () => this.broadcastValidity());
  }

  private sanitize(value: any): number {
    return value !== null && value !== undefined
      ? Math.max(
          this.props.min,
          Math.min(
            this.props.max,
            nanSafeWithDefault(
              Number(round(value, this.props.decimalPlaces)),
              this.props.min
            )
          )
        )
      : this.props.min;
  }

  private update(newValue, callBack?: () => void) {
    const valid = this.valid(newValue);
    if (
      !callBack &&
      ((!valid && this.state.valid) || (valid && !this.state.valid))
    ) {
      // broadcast validity when outside influences cause it through change of max/min values
      callBack = () => this.broadcastValidity();
    }
    this.setState(
      {
        value: newValue,
        valid,
      },
      callBack
    );
  }

  private valid(value: number): boolean {
    try {
      return value >= this.props.min && value <= this.props.max;
    } catch (error) {
      return false;
    }
  }

  private broadcastValidity() {
    if (this.state.valid) {
      this.props.onValid(Number(this.state.value));
    } else {
      this.props.onInvalid(this.state.value);
    }
  }

  public render() {
    return (
      <div className={this.props.className}>
        <div>{this.props.label}</div>
        {!!this.props.button && this.props.button}
        <div className={this.props.buttonsClassName}>
          <div className="number-selector-button-centered">
            <TooltipIconButton
              testId={this.props.testId + "-decrease"}
              onClick={() => this.decrement()}
              disabled={
                this.props.disabled || this.state.value <= this.props.min
              }
              icon={"remove"}
              title="Decrease"
            />
          </div>

          {!this.state.manualEditing && (
            <input
              data-testid={this.props.testId}
              className={
                "number-selector-textfield " +
                (this.state.valid ? "" : " invalid-number-selector ") +
                this.props.textFieldClassName
              }
              value={this.state.value}
              onFocusCapture={() => this.switchToManualEditing()}
              onClick={() => this.switchToManualEditing()}
              onChange={(event) => this.onChange(event.target.value)}
              disabled={this.props.disabled}
              style={{ backgroundColor: this.props.color }}
            />
          )}

          {this.state.manualEditing && (
            <input
              className={
                this.props.textFieldClassName + " number-selector-textfield"
              }
              value={this.state.editValue}
              autoFocus={true}
              onFocusCapture={(event) => this.handleFocus(event)}
              onChange={(event) =>
                this.setState({ editValue: event.target.value })
              }
              onBlur={(event) => this.finishManualEditing(event)}
              disabled={this.props.disabled}
              style={{ backgroundColor: this.props.color }}
            />
          )}
          <div className="number-selector-button-centered">
            <TooltipIconButton
              testId={this.props.testId + "-increase"}
              onClick={() => this.increment()}
              disabled={
                this.props.disabled || this.state.value >= this.props.max
              }
              icon={"add"}
              title="Increase"
            />
          </div>
        </div>
      </div>
    );
  }

  private handleFocus(event: React.FocusEvent<HTMLInputElement, Element>) {
    event.target.select();
    event.target.addEventListener(
      "keypress",
      (keyboardEvent: KeyboardEvent) => {
        if (keyboardEvent.key === "Enter") {
          keyboardEvent.preventDefault();
          event.target.blur();
        }
      }
    );
  }

  private switchToManualEditing() {
    this.props.onManualEdit();
    this.setState({ editValue: "" + this.state.value, manualEditing: true });
  }

  private finishManualEditing(
    event: React.FocusEvent<HTMLInputElement, Element>
  ) {
    this.setState({ manualEditing: false }, () =>
      this.onChange(event.target.value)
    );
    this.props.onManualEditFinished();
  }
}
