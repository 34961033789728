import React from "react";

import { GroundStatsWrapper } from "../../types/stats/ground-stats";
import { NumberSelector } from "../entity-management/entity-selectors/number-selector";
import { NumberComparison } from "../stats-editing-components/number-comparison";
import {
  areStatsEqual,
  getToolTipMessage,
} from "../stats-editing-components/stats-editing-utils";
import StealStatsButton from "../stats-editing-components/steal-stats-button";

import { batchUpdate } from "./ground-page-utils";

type Props = {
  currentStats: GroundStatsWrapper;
  comparedStats: GroundStatsWrapper;
  comparedUserName: string;
  onUpdate: (updatedStats: GroundStatsWrapper) => void;
};

export function GroundStrikeRateAndWicketPercentFlatAdjustment({
  currentStats,
  comparedStats,
  comparedUserName,
  onUpdate,
}: Props): React.JSX.Element {
  const STRIKE_RATE_PROPERTY = "flatStrikeRateAdjustment";
  const WICKET_PROPERTY = "flatWicketPercentAdjustment";

  const STATS_TO_STEAL: string[] = [STRIKE_RATE_PROPERTY, WICKET_PROPERTY];

  const FLAT_STRIKE_RATE_ADJUSTMENT_MIN = -100;
  const FLAT_STRIKE_RATE_ADJUSTMENT_MAX = 300;

  const FLAT_WICKET_PERCENT_ADJUSTMENT_MIN = -100;
  const FLAT_WICKET_PERCENT_ADJUSTMENT_MAX = 100;

  const onClickHandler = () => {
    batchUpdate(currentStats, comparedStats, STATS_TO_STEAL, onUpdate);
  };

  const callUpdateFunction = (propertyName: string, newValue: number) => {
    onUpdate(
      new GroundStatsWrapper(
        currentStats.groundStatsId,
        currentStats.createdBy,
        currentStats.createdAt,
        currentStats.name,
        {
          ...currentStats.groundStats,
          [propertyName]: newValue,
        },
        currentStats.matchType
      )
    );
  };

  return (
    <div className="stats-modal-section">
      <div className="stats-modal-section-head">Flat Adjustments</div>
      <div className="ground-stats-modal-number-selectors">
        <NumberSelector
          className="stats-modal-number-selector"
          label="Strike Rate"
          min={FLAT_STRIKE_RATE_ADJUSTMENT_MIN}
          max={FLAT_STRIKE_RATE_ADJUSTMENT_MAX}
          step={1}
          decimalPlaces={0}
          initial={currentStats.groundStats[STRIKE_RATE_PROPERTY]}
          onValid={(newValue) => {
            callUpdateFunction(STRIKE_RATE_PROPERTY, newValue);
          }}
        />
        <NumberSelector
          className="stats-modal-number-selector"
          label="Wicket Percent"
          min={FLAT_WICKET_PERCENT_ADJUSTMENT_MIN}
          max={FLAT_WICKET_PERCENT_ADJUSTMENT_MAX}
          step={0.01}
          decimalPlaces={2}
          initial={currentStats.groundStats[WICKET_PROPERTY]}
          onValid={(newValue) => {
            callUpdateFunction(WICKET_PROPERTY, newValue);
          }}
        />

        {comparedStats && (
          <div className="comparison">
            <div className="comparison-title-and-steal-button">
              <span>Compared to {comparedUserName}</span>
              <StealStatsButton
                comparedUserName={comparedUserName}
                disabled={areStatsEqual(
                  currentStats,
                  comparedStats,
                  STATS_TO_STEAL,
                  "groundStats"
                )}
                tooltipMessage={getToolTipMessage(
                  currentStats,
                  comparedStats,
                  STATS_TO_STEAL,
                  comparedUserName,
                  "groundStats"
                )}
                onClickHandler={onClickHandler}
              />
            </div>
            <NumberComparison
              label="Strike Rate"
              originalValue={currentStats.groundStats[STRIKE_RATE_PROPERTY]}
              comparedValue={comparedStats.groundStats[STRIKE_RATE_PROPERTY]}
            />
            <NumberComparison
              label="Wicket Percent"
              originalValue={currentStats.groundStats[WICKET_PROPERTY]}
              comparedValue={comparedStats.groundStats[WICKET_PROPERTY]}
            />
          </div>
        )}
      </div>
    </div>
  );
}
