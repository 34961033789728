import { ReplaySubject, Subject } from "rxjs";

import { Match } from "../types/entities/match";
import { MatchFormat } from "../types/entities/match-format";
import { services, showMessage } from "../types/services";
import { SimulationMedianQuestion } from "../types/simulator/questions/simulation-median-question";
import { SimulationOddsQuestion } from "../types/simulator/questions/simulation-odds-question";
import {
  StoredQuestion,
  deserializeStoredQuestionList,
  serializeStoredQuestion,
} from "../types/simulator/questions/stored-questions";

import { UUID } from "../types/uuid";
import { HttpService } from "./http-service";

export class QuestionService {
  private httpService: HttpService;
  public storedQuestionsSubject: Subject<StoredQuestion[]>;
  public storedQuestionsOddsSubject: Subject<Map<string, Map<string, Map<string, number>>>>;
  public storedQuestionOdds: Map<string, Map<string, Map<string, number>>>;

  constructor(httpService: HttpService) {
    this.httpService = httpService;
    this.storedQuestionsSubject = new ReplaySubject(1);
    this.storedQuestionsOddsSubject = new ReplaySubject(1);
    this.storedQuestionOdds = new Map();
  }

  public async askMedianQuestions(
    matchId: UUID,
    eventSequence: number,
    questions: SimulationMedianQuestion[],
    matchFormat: MatchFormat
  ): Promise<number[][]> {
    const medianQuestionRequest = {
      userId: services.keycloakService.getUserId(),
      matchId: !!matchId ? matchId.value : null,
      eventSequence,
      questions,
      matchFormat: MatchFormat.serialize(matchFormat),
    };
    return await this.httpService
      .post("/api/question-controller/ask-median", medianQuestionRequest)
      .then((response: number[][]) => {
        return response;
      })
      .catch((reason) => {
        showMessage(`Failed to get median answer: ${reason}`, "error");
        return null;
      });
  }

  public async askOddsQuestions(
    matchId: UUID,
    eventSequence: number,
    questions: SimulationOddsQuestion[],
    matchFormat: MatchFormat
  ): Promise<number[]> {
    const oddsQuestionRequest = {
      userId: services.keycloakService.getUserId(),
      matchId: !!matchId ? matchId.value : null,
      eventSequence,
      questions,
      matchFormat: MatchFormat.serialize(matchFormat),
    };
    return await this.httpService
      .post("/api/question-controller/ask-odds", oddsQuestionRequest)
      .then((response: number[]) => {
        return response;
      })
      .catch((reason) => {
        showMessage(`Failed to get odds answer: ${reason}`, "error");
        return null;
      });
  }

  public getStoredQuestions(match: Match) {
    if (!match) {
      this.storedQuestionsSubject.next([]);
    } else {
      this.httpService
        .get(
          `/api/question-controller/stored-questions?matchId=${match.matchId.value}`
        )
        .then((response) => {
          this.storedQuestionsSubject.next(
            deserializeStoredQuestionList(response)
          );
        })
        .catch((reason) => {
          showMessage(`Failed to get saved questions: ${reason}`, "error");
          this.storedQuestionsSubject.next([]);
        });
    }
  }

  public updateStoredQuestion(
    storedQuestion: StoredQuestion,
    simulatingUserId: string
  ) {
    if (storedQuestion && simulatingUserId) {
      this.httpService
        .post("/api/question-controller/update-stored-question", {
          simulatingUserId,
          storedQuestion: serializeStoredQuestion(storedQuestion),
        })
        .catch((reason) => {
          showMessage(`Failed to update question: ${reason}`, "error");
        });
    }
  }

  public addStoredQuestion(
    match: Match,
    question: SimulationOddsQuestion,
    simulatingUserId: string
  ) {
    if (match && question) {
      const request = {
        matchId: match.matchId.value,
        simulatingUserId,
        question,
      };
      this.httpService
        .post("/api/question-controller/add-stored-question", request)
        .catch((reason) => {
          showMessage(`Failed to save question: ${reason}`, "error");
        });
    }
  }

  public deleteStoredQuestion(questionId: UUID) {
    if (questionId) {
      this.httpService
        .get(
          `/api/question-controller/delete-stored-question?storedQuestionId=${questionId.value}`
        )
        .catch((reason) => {
          showMessage(`Failed to delete question: ${reason}`, "error");
        });
    }
  }

  public createRandomMultiQuestion(
    match: Match,
    numberOfQuestions: number
  ): void {
    if (!match) {
      showMessage("No match provided", "error");
      return;
    }

    const request = {
      matchId: match.matchId.value,
      numberOfQuestions,
    };
    this.httpService
      .post("/api/question-controller/create-random-multi-question", request)
      .catch((reason) => {
        showMessage(`Failed to create random question: ${reason}`, "error");
      });
  }

  public storedQuestionsWebsocketUpdate(msg: any): void {
    const matchId = msg.matchId;
    if (
      services.currentGameService.currentMatchId &&
      matchId &&
      matchId === services.currentGameService.currentMatchId.value
    ) {
      this.storedQuestionsSubject.next(
        deserializeStoredQuestionList(msg.storedQuestions)
      );
    }
  }

  public storedQuestionsOddsWebsocketUpdate(msg: any): void {
    const map = msg.oddsMap;
    const matchId = msg.matchId;
    const userId = msg.causingUser;
    if (!this.storedQuestionOdds.get(userId)) {
      this.storedQuestionOdds.set(userId, new Map());
    }
    this.storedQuestionOdds.get(userId).set(matchId, new Map());
    Object.keys(map).forEach((storedQuestionId: string) => {
      this.storedQuestionOdds
        .get(userId)
        .get(matchId)
        .set(storedQuestionId, map[storedQuestionId]);
    });
    this.storedQuestionsOddsSubject.next(this.storedQuestionOdds);
  }
}
