import { Component } from "react";
import { MatchStatsWrapper } from "../../types/stats/match-stats";
import { NumberSelector } from "../entity-management/entity-selectors/number-selector";
import { NumberComparison } from "../stats-editing-components/number-comparison";
import {
  areStatsEqual,
  getToolTipMessage,
} from "../stats-editing-components/stats-editing-utils";
import StealStatsButton from "../stats-editing-components/steal-stats-button";

interface Props {
  currentStats: MatchStatsWrapper;
  comparedStats: MatchStatsWrapper;
  comparedUserName: string;
  onSave: (updatedStats: MatchStatsWrapper, valid: boolean) => void;
  teamBattingFirst: number;
  team1Name: string;
  team2Name: string;
}

export class SecondInningsPushAdjustmentComponent extends Component<Props, {}> {
  TEAM_1_STATS_TO_STEAL: string[] = [
    "secondInningsPushAdjustmentTeam1",
    "secondInningsPushDilutionTeam1",
  ];
  TEAM_2_STATS_TO_STEAL: string[] = [
    "secondInningsPushAdjustmentTeam2",
    "secondInningsPushDilutionTeam2",
  ];

  private propsLoaded(): boolean {
    return !!this.props.currentStats;
  }

  private onClickHandler(updates: string[]) {
    updates.forEach((update) => {
      this.updateStats(update, this.props.comparedStats.matchStats[update]);
    });
  }

  private updateStats(property: string, value) {
    const updatedStats: MatchStatsWrapper = this.props.currentStats;
    updatedStats.matchStats[property] = value;
    this.props.onSave(updatedStats, true);
  }

  public render() {
    return (
      <div>
        {this.propsLoaded() && (
          <div className="stats-modal-section">
            <div className="stats-modal-section-head">
              Second Innings Push Adjustment
            </div>
            {(this.props.teamBattingFirst === 0 ||
              this.props.teamBattingFirst === 2) && (
              <div>
                <div className="match-stats-weights">
                  <NumberSelector
                    className="stats-modal-number-selector"
                    textFieldClassName="stats-modal-number-selector-textfield"
                    label={`${this.props.team1Name} Adjustment`}
                    min={0}
                    max={1}
                    step={0.01}
                    decimalPlaces={2}
                    initial={
                      this.props.currentStats.matchStats
                        .secondInningsPushAdjustmentTeam1
                    }
                    onValid={(newValue) =>
                      this.updateStats(
                        "secondInningsPushAdjustmentTeam1",
                        newValue
                      )
                    }
                  />
                  <NumberSelector
                    className="stats-modal-number-selector"
                    textFieldClassName="stats-modal-number-selector-textfield"
                    label={`${this.props.team1Name} Dilution`}
                    min={0}
                    max={1}
                    step={0.01}
                    decimalPlaces={2}
                    initial={
                      this.props.currentStats.matchStats
                        .secondInningsPushDilutionTeam1
                    }
                    onValid={(newValue) =>
                      this.updateStats(
                        "secondInningsPushDilutionTeam1",
                        newValue
                      )
                    }
                  />
                </div>

                {!!this.props.comparedStats && (
                  <div className="comparison">
                    <div className="comparison-title-and-steal-button">
                      <span>Compared to {this.props.comparedUserName}</span>
                      <StealStatsButton
                        comparedUserName={this.props.comparedUserName}
                        disabled={areStatsEqual(
                          this.props.currentStats,
                          this.props.comparedStats,
                          this.TEAM_1_STATS_TO_STEAL,
                          "matchStats"
                        )}
                        tooltipMessage={getToolTipMessage(
                          this.props.currentStats,
                          this.props.comparedStats,
                          this.TEAM_1_STATS_TO_STEAL,
                          this.props.comparedUserName,
                          "matchStats"
                        )}
                        onClickHandler={this.onClickHandler.bind(
                          this,
                          this.TEAM_1_STATS_TO_STEAL
                        )}
                      />
                    </div>

                    <div className="match-stats-weights">
                      <NumberComparison
                        label={`${this.props.team1Name} Adjustment`}
                        originalValue={
                          this.props.currentStats.matchStats
                            .secondInningsPushAdjustmentTeam1
                        }
                        comparedValue={
                          this.props.comparedStats.matchStats
                            .secondInningsPushAdjustmentTeam1
                        }
                      />
                      <NumberComparison
                        label={`${this.props.team1Name} Dilution`}
                        originalValue={
                          this.props.currentStats.matchStats
                            .secondInningsPushDilutionTeam1
                        }
                        comparedValue={
                          this.props.comparedStats.matchStats
                            .secondInningsPushDilutionTeam1
                        }
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
            {(this.props.teamBattingFirst === 0 ||
              this.props.teamBattingFirst === 1) && (
              <div>
                <div className="match-stats-weights-comparison">
                  <div className="match-stats-weights">
                    <NumberSelector
                      className="stats-modal-number-selector"
                      textFieldClassName="stats-modal-number-selector-textfield"
                      label={`${this.props.team2Name} Adjustment`}
                      min={0}
                      max={1}
                      step={0.01}
                      decimalPlaces={2}
                      initial={
                        this.props.currentStats.matchStats
                          .secondInningsPushAdjustmentTeam2
                      }
                      onValid={(newValue) =>
                        this.updateStats(
                          "secondInningsPushAdjustmentTeam2",
                          newValue
                        )
                      }
                    />
                    <NumberSelector
                      className="stats-modal-number-selector"
                      textFieldClassName="stats-modal-number-selector-textfield"
                      label={`${this.props.team2Name} Dilution`}
                      min={0}
                      max={1}
                      step={0.01}
                      decimalPlaces={2}
                      initial={
                        this.props.currentStats.matchStats
                          .secondInningsPushDilutionTeam2
                      }
                      onValid={(newValue) =>
                        this.updateStats(
                          "secondInningsPushDilutionTeam2",
                          newValue
                        )
                      }
                    />
                  </div>

                  {!!this.props.comparedStats && (
                    <div className="comparison">
                      <div className="comparison-title-and-steal-button">
                        <span>Compared to {this.props.comparedUserName}</span>
                        <StealStatsButton
                          comparedUserName={this.props.comparedUserName}
                          disabled={areStatsEqual(
                            this.props.currentStats,
                            this.props.comparedStats,
                            this.TEAM_2_STATS_TO_STEAL,
                            "matchStats"
                          )}
                          tooltipMessage={getToolTipMessage(
                            this.props.currentStats,
                            this.props.comparedStats,
                            this.TEAM_2_STATS_TO_STEAL,
                            this.props.comparedUserName,
                            "matchStats"
                          )}
                          onClickHandler={this.onClickHandler.bind(
                            this,
                            this.TEAM_2_STATS_TO_STEAL
                          )}
                        />
                      </div>

                      <div className="match-stats-weights">
                        <NumberComparison
                          label={`${this.props.team2Name} Adjustment`}
                          originalValue={
                            this.props.currentStats.matchStats
                              .secondInningsPushAdjustmentTeam2
                          }
                          comparedValue={
                            this.props.comparedStats.matchStats
                              .secondInningsPushAdjustmentTeam2
                          }
                        />
                        <NumberComparison
                          label={`${this.props.team2Name} Dilution`}
                          originalValue={
                            this.props.currentStats.matchStats
                              .secondInningsPushDilutionTeam2
                          }
                          comparedValue={
                            this.props.comparedStats.matchStats
                              .secondInningsPushDilutionTeam2
                          }
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
