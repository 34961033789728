import { Component } from "react";
import { observableFrom, propsEqual } from "../component-utils";
import { MatchFormat } from "../../types/entities/match-format";
import { GameState } from "../../types/entities/game-state";
import { MatchStatsWrapper } from "../../types/stats/match-stats";
import { NumberSelector } from "../entity-management/entity-selectors/number-selector";
import { Player } from "../../types/entities/player";
import { PlayerSelector } from "../entity-management/entity-selectors/player-selector";
import { CreationDialog } from "../my-matches/match-creation-modals/creation-dialog";

interface Props {
  originalStats: MatchStatsWrapper;
  gameStateAndReservationsCombined: Map<number, string[]>;
  matchFormat: MatchFormat;
  gameState: GameState;
  innings: number;
  players: Player[];
  open: boolean;
  onCancel: () => void;
  onProceed: (over: number, playerId: string) => void;
}

interface State {
  over: number;
  playerId: string;
}

export class BowlerReservationModal extends Component<Props, State> {
  private static readonly DEFAULT_STATE = {
    over: 1,
    playerId: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...BowlerReservationModal.DEFAULT_STATE,
    };
  }

  componentDidMount(): void {
    this.updateState();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (!propsEqual(prevProps, this.props)) {
      this.updateState();
    }
  }

  private updateState() {
    this.setState({
      over: this.calculateMinimumOver(),
    });
  }

  private invalid() {
    return (
      !this.state.playerId ||
      !!this.props.originalStats.matchStats.overBowlerReservations
        .get(this.props.innings)
        .get(this.state.over) ||
      this.consecutivePlayerOversReached() ||
      this.maxPlayerOversReached()
    );
  }

  private getInvalidText() {
    const messages: string[] = [];

    this.maxPlayerOversReached() &&
      messages.push(
        `Player cannot bowl more than ${this.getMaxOversPerBowler()} overs in an innings.`
      );
    this.consecutivePlayerOversReached() &&
      messages.push(
        `Player cannot bowl more than ${this.getConsecutiveOversPerBowler()} consecutive overs.`
      );
    !!this.props.originalStats &&
      !!this.props.originalStats.matchStats &&
      !!this.props.originalStats.matchStats.overBowlerReservations
        .get(this.props.innings)
        .get(this.state.over) &&
      messages.push(`Over already reserved.`);
    !this.state.playerId && messages.push(`Player not selected.`);

    return messages.length > 0 ? messages[0] : ``;
  }

  private consecutivePlayerOversReached(): boolean {
    return this.consecutiveOversBowled() > this.getConsecutiveOversPerBowler();
  }

  private maxPlayerOversReached(): boolean {
    return this.playerOversBowledOrReserved() >= this.getMaxOversPerBowler();
  }

  private playerOversBowledOrReserved(): number {
    let oversBowledOrReserved: number = 0;
    this.props.gameStateAndReservationsCombined &&
      this.props.gameStateAndReservationsCombined.forEach((playerArr, over) => {
        if (
          !!this.state.playerId &&
          playerArr.find((p) => p === this.state.playerId) !== undefined
        ) {
          oversBowledOrReserved++;
        }
      });
    return oversBowledOrReserved;
  }

  private consecutiveOversBowled(): number {
    let consecutiveOvers: number = 0;
    let consecutiveOversThatContainedCurrentOver: number = 0;
    let containedCurrentOver: boolean = false;
    let lastOver: number = -1;

    if (!!this.props.gameStateAndReservationsCombined) {
      for (
        let over = 1;
        over <=
        this.props.matchFormat.overConfiguration[this.props.innings - 1].length;
        over++
      ) {
        const playerArr: string[] =
          this.props.gameStateAndReservationsCombined.get(over) || [];
        if (over === this.state.over) {
          containedCurrentOver = true;
        }
        if (
          over === this.state.over ||
          (!!this.state.playerId &&
            playerArr.find((p) => p === this.state.playerId) !== undefined)
        ) {
          consecutiveOvers++;
        } else if (!!this.state.playerId && !(over === this.state.over)) {
          if (containedCurrentOver) {
            consecutiveOversThatContainedCurrentOver = consecutiveOvers;
          }
          containedCurrentOver = false;
          consecutiveOvers = 0;
        }
        lastOver = over;
      }
    }

    if (containedCurrentOver) {
      return consecutiveOvers;
    } else if (lastOver + 1 === this.state.over) {
      return consecutiveOvers + 1;
    } else {
      return consecutiveOversThatContainedCurrentOver;
    }
  }

  private getConsecutiveOversPerBowler(): number {
    return this.props.matchFormat && this.props.matchFormat.maxConsecutiveOvers;
  }

  private getMaxOversPerBowler(): number {
    return (
      this.props.matchFormat &&
      this.props.matchFormat.inningsConfiguration[this.props.innings - 1]
        .oversPerBowler
    );
  }

  private calculateMinimumOver(): number {
    if (this.props.gameState.innings > this.props.innings) {
      return this.props.matchFormat.overConfiguration[this.props.innings - 1]
        .length;
    } else if (this.props.gameState.innings === this.props.innings) {
      return (
        this.props.gameState.calculateOverParts(
          this.props.gameState.ballNumber,
          this.props.innings
        )[0] + (!!this.props.gameState.bowler ? 2 : 1)
      );
    } else {
      return 1;
    }
  }

  public render() {
    const player: Player = !!this.state.playerId
      ? this.props.players &&
        this.props.players.find((p) => p.playerId.value === this.state.playerId)
      : null;
    return (
      <CreationDialog
        open={this.props.open}
        label={"Add Bowler Reservation"}
        invalid={this.invalid()}
        disabled={false}
        invalidText={this.getInvalidText()}
        onCancel={() => this.props.onCancel()}
        onProceed={() =>
          this.props.onProceed(this.state.over, this.state.playerId)
        }
        proceedText="OK"
        colour="#34ebb4"
      >
        <div className="bowler-reservation-over-component">
          <NumberSelector
            className="simulator-number-selector"
            label="Over:"
            textFieldClassName="small-number-selector"
            min={this.calculateMinimumOver()}
            max={
              this.props.matchFormat.overConfiguration[this.props.innings - 1]
                .length
            }
            initial={this.state.over}
            onValid={(over) => this.setState({ over })}
          />
          <PlayerSelector
            classes="bowler-reservation-over-component-selector"
            value={player}
            onSelect={(player: Player) =>
              this.setState({
                playerId: !!player ? player.playerId.value : null,
              })
            }
            options={observableFrom(this.props.players)}
          />
        </div>
      </CreationDialog>
    );
  }
}
