import { Component } from "react";
import { CreationDialog } from "../../my-matches/match-creation-modals/creation-dialog";
import { propsEqual } from "../../component-utils";
import { Team } from "../../../types/entities/team";
import { NumberSelector } from "../../entity-management/entity-selectors/number-selector";
import { MatchFormat } from "../../../types/entities/match-format";
import { Button, Checkbox } from "@mui/material";
import { EditMatchFormatComponent } from "../../entity-management/entity-editing-dialogs/edit-match-format-component";
import { UUID } from "../../../types/uuid";

interface Props {
  open: boolean;
  onCancel: () => void;
  onProceed: (
    duckworthTarget: number,
    duckworthTargetInnings: number,
    newMatchFormat: MatchFormat
  ) => void;
  team1: Team;
  team2: Team;
  currentMatchFormat: MatchFormat;
}

interface State {
  hasDuckworthTarget: boolean;
  duckworthTarget: number;
  duckworthTargetInnings: number;
  duckworthTargetValid: boolean;
  duckworthTargetInningsValid: boolean;
  newMatchFormat: MatchFormat;
  matchFormatEditModalOpen: boolean;
}

export class FormatChangeModal extends Component<Props, State> {
  private static defaultStateForProps(props: Props): State {
    return {
      hasDuckworthTarget: false,
      duckworthTarget: null,
      duckworthTargetInnings: null,
      duckworthTargetValid: true,
      duckworthTargetInningsValid: true,
      newMatchFormat: !!props.currentMatchFormat && {
        ...MatchFormat.clone(props.currentMatchFormat),
        parentId:
          props.currentMatchFormat.parentId ||
          props.currentMatchFormat.matchFormatId,
        name:
          props.currentMatchFormat.name +
          " - altered format " +
          new Date().toLocaleString(),
        entityId: null,
        matchFormatId: UUID.randomUUID(),
        createdBy: null,
        createdAt: null,
      },
      matchFormatEditModalOpen: false,
    };
  }

  constructor(props) {
    super(props);
    this.state = FormatChangeModal.defaultStateForProps(props);
  }

  componentDidUpdate(prevProps): void {
    if (!propsEqual(prevProps, this.props)) {
      this.setState(FormatChangeModal.defaultStateForProps(this.props));
    }
  }

  private invalid(): boolean {
    return (
      !this.state.newMatchFormat ||
      !this.state.duckworthTargetValid ||
      !this.state.duckworthTargetInningsValid
    );
  }

  private handleDuckworthCheckboxChange(event) {
    const hasDuckworthTarget = event.target.checked;

    if (hasDuckworthTarget) {
      this.setState({
        hasDuckworthTarget,
        duckworthTarget: 0,
        duckworthTargetInnings:
          this.state.newMatchFormat &&
          this.state.newMatchFormat.inningsConfiguration.length,
      });
    } else {
      this.setState({
        hasDuckworthTarget,
        duckworthTarget: null,
        duckworthTargetInnings: null,
      });
    }
  }

  private onCancel() {
    this.props.onCancel();
  }

  public render() {
    return (
      <CreationDialog
        open={this.props.open}
        label={"Change Format"}
        invalid={this.invalid()}
        disabled={false}
        onCancel={() => this.onCancel()}
        onProceed={() =>
          this.props.onProceed(
            this.state.duckworthTarget,
            this.state.duckworthTargetInnings,
            this.state.newMatchFormat
          )
        }
        proceedText="OK"
        colour="#3486eb"
      >
        <div className="change-format-modal-content">
          <div>{this.state.newMatchFormat.name}</div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.setState({ matchFormatEditModalOpen: true })}
          >
            EDIT FORMAT
          </Button>
          <EditMatchFormatComponent
            initialValue={this.state.newMatchFormat}
            open={this.state.matchFormatEditModalOpen}
            label={"Edit Match Format"}
            buttonText={"UPDATE"}
            isNew={true}
            onProceed={(entity) =>
              this.setState({
                newMatchFormat: entity,
                matchFormatEditModalOpen: false,
              })
            }
            onCancel={() => this.setState({ matchFormatEditModalOpen: false })}
          />

          <div className="checkbox-and-label">
            <div>Duckworth-Lewis Target Set?</div>
            <Checkbox
              data-testid={"customise-by-innings"}
              checked={this.state.hasDuckworthTarget}
              onChange={(event) => this.handleDuckworthCheckboxChange(event)}
              disabled={false}
            />
          </div>

          {this.state.hasDuckworthTarget && (
            <div>
              <NumberSelector
                className="match-format-number-selector"
                label="New Target"
                textFieldClassName="small-number-selector"
                min={0}
                max={1000}
                initial={this.state.duckworthTarget}
                onValid={(duckworthTarget) =>
                  this.setState({ duckworthTargetValid: true, duckworthTarget })
                }
                onInvalid={() => this.setState({ duckworthTargetValid: false })}
              />

              <NumberSelector
                className="match-format-number-selector"
                label="Target Innings"
                textFieldClassName="small-number-selector"
                min={1}
                max={
                  this.state.newMatchFormat &&
                  this.state.newMatchFormat.inningsConfiguration.length
                }
                initial={this.state.duckworthTargetInnings}
                onValid={(duckworthTargetInnings) =>
                  this.setState({
                    duckworthTargetInningsValid: true,
                    duckworthTargetInnings,
                  })
                }
                onInvalid={() =>
                  this.setState({ duckworthTargetInningsValid: false })
                }
              />
            </div>
          )}
        </div>
      </CreationDialog>
    );
  }
}
