import {
  AppBar,
  Badge,
  Icon,
  ListItemIcon,
  ListSubheader,
  Menu,
  MenuItem,
  Toolbar,
} from "@mui/material";
import { Component } from "react";

import { Subscription } from "rxjs";
import { ReactComponent as BetfairArrowSVG } from "../../img/betfair/betfair-arrow.svg";
import { ReactComponent as FullPushSVG } from "../../img/full-push.svg";
import { ReactComponent as MatchSVG } from "../../img/toolbar/match.svg";
import {
  BetBuilderRoute,
  BetfairRoute,
  EmployeeRoute,
  InvestorAdminRoute,
  InvestorMenuRoute,
  InvestorRoute,
  SimulatorRoute,
  UserRoute,
} from "../../types/route-helpers";
import { services } from "../../types/services";
import { BetSource } from "../../types/stored-bets/bet-source";
import { getHomepageRoute } from "../component-utils";
import TooltipIconButton from "./tooltip-icon-button";

interface State {
  accountMenuOpen: boolean;
  accountMenuAnchorElement: null | HTMLElement;
  investmentsMenuOpen: boolean;
  investmentsMenuAnchorElement: null | HTMLElement;
  settingsMenuOpen: boolean;
  settingsMenuAnchorElement: null | HTMLElement;
  requiredScrapes: BetSource[];
}

export class NavigationBar extends Component<{}, State> {
  private subscriptions: Subscription[] = [];
  private history = services.history;

  public constructor(props) {
    super(props);
    this.state = {
      accountMenuOpen: false,
      accountMenuAnchorElement: null,
      settingsMenuOpen: false,
      settingsMenuAnchorElement: null,
      investmentsMenuOpen: false,
      investmentsMenuAnchorElement: null,
      requiredScrapes: [],
    };
  }

  componentDidMount(): void {
    this.subscriptions.push(
      services.betScraperService.lastStoredBetScrapeSubject.subscribe(
        (lastStoredBetScrape) => {
          this.setState({
            requiredScrapes:
              services.betScraperService.getRequiredScrapes(
                lastStoredBetScrape
              ),
          });
        }
      )
    );
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }

  private route(
    route: string,
    stripParameters: boolean = false,
    openNewWindow: boolean = false
  ): void {
    if (openNewWindow) {
      window.open(`${route}${this.history.location.search}`, "_blank");
    } else {
      const path = stripParameters
        ? route
        : { pathname: route, search: this.history.location.search };
      this.history.push(path);
      this.history.goForward();
    }
  }

  public render() {
    return (
      <div>
        <AppBar position="static">
          <Toolbar className="toolbar">
            <div className="toolbar-buttons">
              <div
                className="tooltip-logo"
                onClick={() => this.route("/" + getHomepageRoute(), true)}
              >
                <FullPushSVG />
              </div>
              <UserRoute>
                <TooltipIconButton
                  title="Edit Match"
                  onClick={() => this.route("/match")}
                  onContextMenu={() => this.route("/match", false, true)}
                  icon="edit_circle"
                />
              </UserRoute>

              <UserRoute>
                <TooltipIconButton
                  title="Settings"
                  onClick={(event) =>
                    this.setState({
                      settingsMenuOpen: true,
                      settingsMenuAnchorElement: event.target,
                    })
                  }
                  icon="tune"
                />
                <Menu
                  anchorEl={this.state.settingsMenuAnchorElement}
                  anchorOrigin={{ vertical: 30, horizontal: "left" }}
                  anchorReference="anchorEl"
                  open={this.state.settingsMenuOpen}
                  onClose={() =>
                    this.setState({
                      settingsMenuOpen: false,
                      settingsMenuAnchorElement: null,
                    })
                  }
                  onClick={() =>
                    this.setState({
                      settingsMenuOpen: false,
                      settingsMenuAnchorElement: null,
                    })
                  }
                >
                  <MenuItem
                    onClick={() => this.route("/squads")}
                    onContextMenu={() => this.route("/squads", false, true)}
                  >
                    <ListItemIcon>
                      <Icon>groups</Icon>
                    </ListItemIcon>
                    Players
                  </MenuItem>
                  <SimulatorRoute>
                    <MenuItem
                      onClick={() => this.route("/ground")}
                      onContextMenu={() => this.route("/ground", false, true)}
                    >
                      <ListItemIcon>
                        <Icon>stadium</Icon>
                      </ListItemIcon>
                      Ground
                    </MenuItem>
                    <MenuItem
                      onClick={() => this.route("/match-stats")}
                      onContextMenu={() =>
                        this.route("/match-stats", false, true)
                      }
                    >
                      <ListItemIcon>
                        <MatchSVG />
                      </ListItemIcon>
                      Match
                    </MenuItem>
                    <MenuItem onClick={() => this.route("/nodes")}>
                      <ListItemIcon>
                        <Icon>hub</Icon>
                      </ListItemIcon>
                      Worker Nodes
                    </MenuItem>
                  </SimulatorRoute>
                  <EmployeeRoute>
                    <ListSubheader>Admin</ListSubheader>
                  </EmployeeRoute>
                  <EmployeeRoute>
                    <MenuItem onClick={() => this.route("/admin-settings")}>
                      <ListItemIcon>
                        <Icon>admin_panel_settings</Icon>
                      </ListItemIcon>
                      Admin Settings
                    </MenuItem>
                  </EmployeeRoute>
                  <EmployeeRoute>
                    <MenuItem
                      onClick={() => this.route("/default-player-stats")}
                    >
                      <ListItemIcon>
                        <Icon>groups</Icon>
                      </ListItemIcon>
                      Default Player Stats
                    </MenuItem>
                  </EmployeeRoute>
                  <EmployeeRoute>
                    <MenuItem onClick={() => this.route("/scraped-matches")}>
                      <ListItemIcon>
                        <Icon>file_download</Icon>
                      </ListItemIcon>
                      Scraped Matches
                    </MenuItem>
                  </EmployeeRoute>
                  <EmployeeRoute>
                    <MenuItem
                      onClick={() => this.route("/historic-ground-stats")}
                    >
                      <ListItemIcon>
                        <Icon>history</Icon>
                      </ListItemIcon>
                      Historic Ground Stats
                    </MenuItem>
                  </EmployeeRoute>
                  <EmployeeRoute>
                    <MenuItem
                      onClick={() => this.route("/historic-player-stats")}
                    >
                      <ListItemIcon>
                        <Icon>history</Icon>
                      </ListItemIcon>
                      Historic Player Stats
                    </MenuItem>
                  </EmployeeRoute>
                </Menu>
              </UserRoute>
              <SimulatorRoute>
                <TooltipIconButton
                  title="Simulator"
                  onClick={() => this.route("/simulator")}
                  onContextMenu={() => this.route("/simulator", false, true)}
                  icon="bar_chart"
                />
              </SimulatorRoute>
              <EmployeeRoute>
                <TooltipIconButton
                  title="Database"
                  onClick={() =>
                    window.open(process.env.REACT_APP_REDASH_URL, "_blank")
                  }
                  icon="storage"
                />
              </EmployeeRoute>
              <BetfairRoute>
                <TooltipIconButton
                  title="Betfair"
                  onClick={() => this.route("/betfair")}
                  onContextMenu={() => this.route("/betfair", false, true)}
                  icon={<BetfairArrowSVG className="tooltip-icon-button" />}
                />
              </BetfairRoute>
              <BetBuilderRoute>
                <TooltipIconButton
                  title="Bet Builder"
                  onClick={() => this.route("/bet-builder")}
                  onContextMenu={() => this.route("/bet-builder", false, true)}
                  icon="casino"
                />
              </BetBuilderRoute>
              <InvestorMenuRoute>
                <TooltipIconButton
                  title="Investments"
                  onClick={(event) =>
                    this.setState({
                      investmentsMenuOpen: true,
                      investmentsMenuAnchorElement: event.target,
                    })
                  }
                  icon="currency_pound"
                />
                <Menu
                  anchorEl={this.state.investmentsMenuAnchorElement}
                  anchorOrigin={{ vertical: 30, horizontal: "left" }}
                  anchorReference="anchorEl"
                  open={this.state.investmentsMenuOpen}
                  onClose={() =>
                    this.setState({
                      investmentsMenuOpen: false,
                      investmentsMenuAnchorElement: null,
                    })
                  }
                  onClick={() =>
                    this.setState({
                      investmentsMenuOpen: false,
                      investmentsMenuAnchorElement: null,
                    })
                  }
                >
                  <InvestorAdminRoute>
                    <MenuItem onClick={() => this.route("/investment-admin")}>
                      <ListItemIcon>
                        <Icon>groups</Icon>
                      </ListItemIcon>
                      Investment Admin
                    </MenuItem>
                  </InvestorAdminRoute>
                  <InvestorRoute>
                    <MenuItem onClick={() => this.route("/investments")}>
                      <ListItemIcon>
                        <Icon>currency_pound</Icon>
                      </ListItemIcon>
                      My Investments
                    </MenuItem>
                  </InvestorRoute>
                </Menu>
              </InvestorMenuRoute>
              <EmployeeRoute>
                <TooltipIconButton
                  title="Ticket Tracking"
                  onClick={() =>
                    window.open(process.env.REACT_APP_TRACK_URL, "_blank")
                  }
                  icon="checklist"
                />
              </EmployeeRoute>
              <Badge
                badgeContent={this.state.requiredScrapes.length}
                color="secondary"
                invisible={this.state.requiredScrapes.length === 0}
                overlap="circular"
              >
                <TooltipIconButton
                  title="Bets"
                  onClick={() => this.route("/bets")}
                  icon="savings"
                />
              </Badge>
            </div>
            <div>
              <TooltipIconButton
                title="Account"
                onClick={(event) =>
                  this.setState({
                    accountMenuOpen: true,
                    accountMenuAnchorElement: event.target,
                  })
                }
                icon="person"
              />
              <Menu
                anchorEl={this.state.accountMenuAnchorElement}
                anchorOrigin={{ vertical: 30, horizontal: "left" }}
                anchorReference="anchorEl"
                open={this.state.accountMenuOpen}
                onClose={() =>
                  this.setState({
                    accountMenuOpen: false,
                    accountMenuAnchorElement: null,
                  })
                }
                onClick={() =>
                  this.setState({
                    accountMenuOpen: false,
                    accountMenuAnchorElement: null,
                  })
                }
              >
                <a
                  href={services.keycloakService.accountPageLink()}
                  className="no-link-decoration"
                >
                  <MenuItem disabled={false}>
                    <ListItemIcon>
                      <Icon>account_circle</Icon>
                    </ListItemIcon>
                    Account Settings
                  </MenuItem>
                </a>
                <MenuItem
                  disabled={false}
                  onClick={() => services.keycloakService.logout()}
                >
                  <ListItemIcon>
                    <Icon>logout</Icon>
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}
