import { Team } from "../../types/entities/team";
import { EntityService } from "./entity-service";
import { UUID } from "../../types/uuid";
import { countryFlags } from "../../types/enums/flags";
import { ReactNode } from "react";

export class TeamService extends EntityService<Team> {
  public findFlagForNationalTeam(teamId: UUID): ReactNode {
    if (!!teamId) {
      const team: Team = this.allEntities.find(
        (team) => team.teamId.value === teamId.value
      );
      if (team !== undefined) {
        return countryFlags[team.country];
      }
    }
    return null;
  }
}
