import { ReactNode, Component } from "react";
import { NumberSelector } from "../entity-management/entity-selectors/number-selector";
import { EnumSelector } from "../entity-management/entity-selectors/enum-selector";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import { Country } from "../../types/enums/country";

interface Props {
  heading: string;
  subHeading: string;
  label1: string;
  label2: string;
  emptyMessage: string;
  allWeights: number;
  allWeightsName: string;
  individualWeights: Map<Country, number>;
  individualWeightsName: string;
  readableNames: Record<Country, string>;
  images: Record<Country, ReactNode>;
  unavailableKeys: Country[];
  handleAllWeightsChange: (value: number) => void;
  handleEntrySelected: (key: Country) => void;
  handleEntryChanged: (key: Country, value: number) => void;
  handleEntryRemoved: (key: Country) => void;
}

export class CountryCriteriaSelector extends Component<Props, {}> {
  public render() {
    return (
      <div className="">
        <div className="form-settings-subheading">{this.props.heading}</div>
        <div className="edit-default-squads-criteria-box">
          <NumberSelector
            className="stats-modal-number-selector"
            label={this.props.label1}
            min={0}
            max={100}
            step={0.1}
            decimalPlaces={1}
            initial={this.props.allWeights}
            onValid={(newValue) => this.props.handleAllWeightsChange(newValue)}
            onInvalid={(newValue) =>
              this.props.handleAllWeightsChange(newValue)
            }
          />

          <div className="individual-weights-box">
            <div className="individual-weights-selection-panel">
              {this.props.subHeading}

              <EnumSelector
                classes="dialog-content"
                enumObject={Country}
                label={this.props.label2}
                showLabel={false}
                value={null}
                images={this.props.images}
                readableValues={this.props.readableNames}
                onSelect={(value) => this.props.handleEntrySelected(value)}
                unavailable={this.props.unavailableKeys}
                disabled={
                  this.props.unavailableKeys.length ===
                  Object.keys(Country).length
                }
              />
            </div>
            {this.props.unavailableKeys.map((key: Country) => (
              <div
                className="individual-weightings-map-item"
                key={`used-key-${key.toString()}`}
              >
                <div className="small-image">{this.props.images[key]}</div>
                <NumberSelector
                  className="stats-modal-number-selector"
                  label={this.props.readableNames[key]}
                  min={0}
                  max={100}
                  step={0.1}
                  decimalPlaces={1}
                  initial={this.props.individualWeights.get(key)}
                  onValid={(newValue) =>
                    this.props.handleEntryChanged(key, newValue)
                  }
                  onInvalid={(newValue) =>
                    this.props.handleEntryChanged(key, newValue)
                  }
                />
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <TooltipIconButton
                    title="Delete"
                    icon="delete"
                    onClick={() => this.props.handleEntryRemoved(key)}
                  />
                </div>
              </div>
            ))}

            {this.props.individualWeights.size === 0 && (
              <div className="weightings-empty-message italic">
                {this.props.emptyMessage}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
