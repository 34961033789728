import { DecisionType } from "../enums/decision-type";
import { MatchRole } from "../enums/match-role";
import { MatchType } from "../enums/match-type";
import { InningsStatisticType, StatisticType } from "../enums/statistic-type";
import { services } from "../services";
import { OverSummary } from "../simulator/simulation-result";
import { UUID } from "../uuid";
import { Entity } from "./entity";
import { Match } from "./match";
import { MatchFormat } from "./match-format";
import { TeamPlayer } from "./team-player";

export class GameState implements Entity {
  public entityId: UUID;
  public createdAt: number;
  public createdBy: UUID;
  public matchId: UUID;
  public matchFormatId: UUID;
  public matchType: MatchType;
  public innings: number;
  public ballNumber: number;
  public ballAttempt: number;
  public bowler: UUID;
  public batsman1: UUID;
  public batsman2: UUID;
  public inningsStats: Record<InningsStatisticType, any[]>;
  public squads: TeamPlayer[][];
  public stats: Record<StatisticType, Map<string, any>[]>;
  public thisBall: number[];
  public nextBall: number[];
  public started: boolean;
  public ended: boolean;
  public freeHit: boolean;
  public initialStatsLoaded: boolean;
  public inSurge: boolean;
  public requiredDecisions: DecisionType[];
  public superOverDecision: boolean;
  public eventSequence: number;

  constructor(
    entityId: UUID,
    createdBy: UUID,
    createdAt: number,
    matchId: UUID,
    matchFormatId: UUID,
    matchType: MatchType,
    innings: number,
    ballNumber: number,
    ballAttempt: number,
    bowler: UUID,
    batsman1: UUID,
    batsman2: UUID,
    thisBall: number[],
    nextBall: number[],
    started: boolean,
    ended: boolean,
    freeHit: boolean,
    initialStatsLoaded: boolean,
    inSurge: boolean,
    inningsStats: Record<InningsStatisticType, any[]>,
    squads: TeamPlayer[][],
    stats: Record<StatisticType, Map<string, number>[]>,
    requiredDecisions: DecisionType[],
    superOverDecision: boolean,
    eventSequence: number
  ) {
    this.entityId = entityId;
    this.createdBy = createdBy;
    this.createdAt = createdAt;
    this.matchId = matchId;
    this.matchFormatId = matchFormatId;
    this.matchType = matchType;
    this.innings = innings;
    this.ballNumber = ballNumber;
    this.ballAttempt = ballAttempt;
    this.bowler = bowler;
    this.batsman1 = batsman1;
    this.batsman2 = batsman2;
    this.thisBall = thisBall;
    this.nextBall = nextBall;
    this.started = started;
    this.ended = ended;
    this.freeHit = freeHit;
    this.initialStatsLoaded = initialStatsLoaded;
    this.inSurge = inSurge;
    this.inningsStats = inningsStats;
    this.squads = squads;
    this.stats = stats;
    this.requiredDecisions = requiredDecisions;
    this.superOverDecision = superOverDecision;
    this.eventSequence = eventSequence;
  }

  public getTeamId(team: number) {
    return (
      this.squads &&
      this.squads.length >= team &&
      this.squads[team - 1].length >= 1 &&
      this.squads[team - 1][0].teamId
    );
  }

  public findFirstBattingInningsForTeam(
    team: number,
    matchFormat: MatchFormat
  ) {
    for (let i = 0; i < matchFormat.inningsConfiguration.length; i++) {
      if (this.getBattingTeamForInnings(i + 1, matchFormat) === team) {
        return i;
      }
    }
    return -1;
  }

  public findFirstBowlingInningsForTeam(
    team: number,
    matchFormat: MatchFormat
  ) {
    for (let i = 0; i < matchFormat.inningsConfiguration.length; i++) {
      if (this.getBowlingTeamForInnings(i + 1, matchFormat) === team) {
        return i;
      }
    }
    return -1;
  }

  public getPowerplayStatForInnings(
    innings: number,
    matchFormat: MatchFormat,
    cumulativeByOverType: InningsStatisticType,
    totalType: InningsStatisticType
  ): number {
    if (
      !matchFormat ||
      matchFormat.matchFormatId.value !== this.matchFormatId.value
    ) {
      return 0;
    }

    const overMap: Map<number, number> =
      this.inningsStats[cumulativeByOverType].length > 0 &&
      this.inningsStats[cumulativeByOverType].length >= innings
        ? this.inningsStats[cumulativeByOverType][innings - 1]
        : new Map();

    const inningsTotal = this.getInningsStatForInnings(innings, totalType, 0);
    const powerplayStart =
      matchFormat.inningsConfiguration[innings - 1].powerplayOversStart;
    const powerplayEnd =
      matchFormat.inningsConfiguration[innings - 1].powerplayOversEnd;

    const scoreAtStartOfPowerplay =
      powerplayStart === 0
        ? 0
        : overMap.get(powerplayStart - 1) === undefined
        ? inningsTotal
        : overMap.get(powerplayStart - 1);
    const scoreAtEndOfPowerplay =
      powerplayEnd === 0
        ? 0
        : overMap.get(powerplayEnd - 1) === undefined
        ? inningsTotal
        : overMap.get(powerplayEnd - 1);

    return scoreAtEndOfPowerplay - scoreAtStartOfPowerplay;
  }

  public getPowerplayRunsForInnings(
    innings: number,
    matchFormat: MatchFormat
  ): number {
    return this.getPowerplayStatForInnings(
      innings,
      matchFormat,
      InningsStatisticType.OVER_RUNS,
      InningsStatisticType.TOTAL_RUNS
    );
  }

  public getPowerplayWicketsForInnings(
    innings: number,
    matchFormat: MatchFormat
  ): number {
    return this.getPowerplayStatForInnings(
      innings,
      matchFormat,
      InningsStatisticType.OVER_WICKETS,
      InningsStatisticType.WICKETS
    );
  }

  public getBattingTeam(matchFormat: MatchFormat) {
    return this.getBattingTeamForInnings(this.innings, matchFormat);
  }

  public getBowlingTeam(matchFormat: MatchFormat) {
    return this.getBowlingTeamForInnings(this.innings, matchFormat);
  }

  public getBattingTeamForInnings(innings: number, matchFormat: MatchFormat) {
    if (
      !matchFormat ||
      matchFormat.matchFormatId.value !== this.matchFormatId.value
    ) {
      return 1;
    }
    return matchFormat.inningsConfiguration[innings - 1].battingTeam;
  }

  public getBowlingTeamForInnings(innings: number, matchFormat: MatchFormat) {
    return this.getBattingTeamForInnings(innings, matchFormat) === 1 ? 2 : 1;
  }

  public getScore(): string {
    return (
      this.inningsStats[InningsStatisticType.TOTAL_RUNS][this.innings - 1] +
      "/" +
      this.inningsStats[InningsStatisticType.WICKETS][this.innings - 1]
    );
  }

  public getOverText(): string {
    return this.getOverTextForBalls(this.ballNumber);
  }

  public getOverSummary(
    innings: number,
    over: number,
    matchFormat: MatchFormat
  ): OverSummary {
    const overs =
      this.inningsStats[InningsStatisticType.OVER_RUNS][innings - 1];
    const totalRuns: number =
      this.inningsStats[InningsStatisticType.TOTAL_RUNS][innings - 1];
    let overSummary: string;
    if (!!overs) {
      const thisOverRuns = overs[over];
      const previousOverRuns = overs[over - 1];
      let overRuns;
      if (!!thisOverRuns) {
        overRuns = over === 0 ? thisOverRuns : thisOverRuns - previousOverRuns;
      } else if (!!previousOverRuns) {
        overRuns = totalRuns - previousOverRuns;
      } else {
        overRuns = totalRuns;
      }
      overSummary =
        "Innings " +
        innings +
        " - Over " +
        (over + 1) +
        " - " +
        overRuns +
        (overRuns === 1 ? " run" : " runs");
    } else {
      overSummary = "Innings " + innings + " - Over " + (over + 1);
    }

    const surgeOver = this.getInningsStatForInnings(
      innings,
      InningsStatisticType.SURGE_OVER,
      -1
    );
    const inningsConfiguration =
      !!matchFormat && matchFormat.inningsConfiguration[innings - 1];
    const surge: boolean =
      !!inningsConfiguration &&
      surgeOver !== -1 &&
      over + 1 >= surgeOver &&
      over + 1 < surgeOver + inningsConfiguration.surgeOvers;

    return {
      overSummary,
      surge,
    };
  }

  public getOverTextForBalls(balls: number): string {
    if (!!services.currentGameService.currentMatchFormat) {
      const [over, ballInOver] = this.calculateOverParts(balls, this.innings);
      return over + "." + ballInOver;
    } else {
      return "";
    }
  }

  public calculateOverParts(ballNumber: number, innings: number): number[] {
    if (
      !services.currentGameService.currentMatchFormat ||
      services.currentGameService.currentMatchFormat.matchFormatId.value !==
        this.matchFormatId.value
    ) {
      return [0, 0];
    }

    let ballsRemaining: number = ballNumber;
    let over: number = 0;
    let lastOver: number = 0;
    let lastBallsPerOver: number = 0;
    let overFound: boolean = false;
    const overConfig =
      services.currentGameService.currentMatchFormat.overConfiguration[
        innings - 1
      ];
    for (let i = 0; i < overConfig.length; i++) {
      const ballsInOver = overConfig[i];
      lastOver = i;
      lastBallsPerOver = ballsInOver;
      if (ballsInOver > ballsRemaining) {
        over = i;
        overFound = true;
        break;
      } else {
        ballsRemaining = ballsRemaining - ballsInOver;
      }
    }
    if (!overFound) {
      return [lastOver, lastBallsPerOver + 1];
    } else {
      return [over, ballsRemaining];
    }
  }

  public calculateWinningText(
    team1Name: string,
    team2Name: string,
    matchFormat: MatchFormat
  ): string {
    if (this.ended) {
      const numberOfInnings =
        this.inningsStats[InningsStatisticType.TOTAL_RUNS].length;
      const teamBattingLast: number = this.getBattingTeamForInnings(
        numberOfInnings,
        matchFormat
      );
      const duckworthTarget =
        this.inningsStats[InningsStatisticType.DUCKWORTH_TARGET][
          numberOfInnings - 1
        ];

      let teamBattingLastCombinedRuns: number =
        duckworthTarget === -1
          ? this.calculateCombinedRuns(teamBattingLast, matchFormat)
          : this.inningsStats[InningsStatisticType.TOTAL_RUNS][
              numberOfInnings - 1
            ];
      let otherTeamCombinedRuns: number =
        duckworthTarget === -1
          ? this.calculateCombinedRuns(
              teamBattingLast === 1 ? 2 : 1,
              matchFormat
            )
          : duckworthTarget;

      if (teamBattingLastCombinedRuns === otherTeamCombinedRuns) {
        return "Match Drawn";
      } else if (teamBattingLastCombinedRuns > otherTeamCombinedRuns) {
        const wicketMargin =
          this.getTotalWicketsForInnings(numberOfInnings, matchFormat) -
          this.inningsStats[InningsStatisticType.WICKETS][
            this.inningsStats[InningsStatisticType.WICKETS].length - 1
          ];
        return `${teamBattingLast === 1 ? team1Name : team2Name} won by
                ${
                  duckworthTarget === -1
                    ? `${wicketMargin} ${
                        wicketMargin === 1 ? "wicket" : "wickets"
                      }`
                    : `Duckworth-Lewis method`
                }`;
      } else {
        const victoryMargin =
          otherTeamCombinedRuns - teamBattingLastCombinedRuns;
        return `${teamBattingLast === 1 ? team2Name : team1Name} won by
                ${
                  duckworthTarget === -1
                    ? `${victoryMargin} ${victoryMargin === 1 ? "run" : "runs"}`
                    : `Duckworth-Lewis method`
                }`;
      }
    } else {
      return "";
    }
  }

  private calculateCombinedRuns(
    team: number,
    matchFormat: MatchFormat
  ): number {
    let combinedTeamRuns: number = 0;
    for (let i = 1; i <= this.innings; i++) {
      if (this.getBattingTeamForInnings(i, matchFormat) === team) {
        combinedTeamRuns =
          combinedTeamRuns +
          this.inningsStats[InningsStatisticType.TOTAL_RUNS][i - 1];
      }
    }
    return combinedTeamRuns;
  }

  public calculateInningsSummary(
    team: number,
    matchFormat: MatchFormat
  ): number[][] {
    let results: number[][] = [];
    if (!!this.squads[0] && !!this.squads[1]) {
      for (let i = 1; i <= this.innings; i++) {
        if (this.getBattingTeamForInnings(i, matchFormat) === team) {
          results.push([
            this.inningsStats[InningsStatisticType.TOTAL_RUNS][i - 1],
            this.inningsStats[InningsStatisticType.WICKETS][i - 1],
            this.getTotalWicketsForInnings(i, matchFormat),
            this.inningsStats[InningsStatisticType.DECLARATION][i - 1],
          ]);
        }
      }
    }
    return results;
  }

  private getTotalWicketsForInnings(innings: number, matchFormat: MatchFormat) {
    if (
      !matchFormat ||
      matchFormat.matchFormatId.value !== this.matchFormatId.value
    ) {
      return 0;
    }
    const teamBatting: number = this.getBattingTeamForInnings(
      innings,
      matchFormat
    );
    const matchFormatMaxWickets =
      matchFormat.inningsConfiguration[innings - 1].maxWickets;
    const totalPlayers =
      (teamBatting === 1 ? this.squads[0] : this.squads[1]).filter(
        (teamPlayer) =>
          teamPlayer.matchRole !== MatchRole.SUBSTITUTE &&
          teamPlayer.matchRole !== MatchRole.REPLACED
      ).length - 1;
    return Math.min(matchFormatMaxWickets, totalPlayers);
  }

  public calculateMatchStatusText(
    team1Name: string,
    team2Name: string,
    totalNumberOfInnings: number,
    matchFormat: MatchFormat
  ): string {
    if (!this.ended && this.innings !== 1) {
      const teamBattingLast: number = this.getBattingTeamForInnings(
        this.innings,
        matchFormat
      );
      const battingTeamName: string =
        teamBattingLast === 1 ? team1Name : team2Name;
      const battingTeamTotalWickets: number = this.getTotalWicketsForInnings(
        this.innings,
        matchFormat
      );
      const battingTeamWickets: number =
        this.inningsStats[InningsStatisticType.WICKETS][this.innings - 1];
      const duckworthTarget =
        this.inningsStats[InningsStatisticType.DUCKWORTH_TARGET][
          this.innings - 1
        ];

      if (duckworthTarget !== -1 && totalNumberOfInnings === this.innings) {
        const teamBattingLastCombinedRuns: number =
          this.inningsStats[InningsStatisticType.TOTAL_RUNS][this.innings - 1];
        const runMargin = duckworthTarget - teamBattingLastCombinedRuns + 1;
        const wicketsInHand = battingTeamTotalWickets - battingTeamWickets;
        return `${battingTeamName} require ${runMargin} ${
          runMargin === 1 ? "run" : "runs"
        }
                to reach their Duckworth-Lewis target of ${duckworthTarget + 1}
                with ${wicketsInHand} ${
          wicketsInHand === 1 ? "wicket" : "wickets"
        } remaining`;
      } else {
        const teamBattingLastCombinedRuns: number = this.calculateCombinedRuns(
          teamBattingLast,
          matchFormat
        );
        const otherTeamCombinedRuns: number = this.calculateCombinedRuns(
          teamBattingLast === 1 ? 2 : 1,
          matchFormat
        );
        if (teamBattingLastCombinedRuns === otherTeamCombinedRuns) {
          return "Scores are level";
        } else if (teamBattingLastCombinedRuns > otherTeamCombinedRuns) {
          const runMargin = teamBattingLastCombinedRuns - otherTeamCombinedRuns;
          return `${battingTeamName} lead by ${runMargin} ${
            runMargin === 1 ? "run" : "runs"
          }`;
        } else {
          if (totalNumberOfInnings === this.innings) {
            const runMargin =
              otherTeamCombinedRuns - teamBattingLastCombinedRuns + 1;
            const wicketsInHand = battingTeamTotalWickets - battingTeamWickets;
            return `${battingTeamName} require ${runMargin} ${
              runMargin === 1 ? "run" : "runs"
            }
                        to win with ${wicketsInHand} ${
              wicketsInHand === 1 ? "wicket" : "wickets"
            } remaining`;
          } else {
            const runMargin =
              otherTeamCombinedRuns - teamBattingLastCombinedRuns;
            return `${battingTeamName} trail by ${runMargin} ${
              runMargin === 1 ? "run" : "runs"
            }`;
          }
        }
      }
    } else {
      return "";
    }
  }

  public batsmanCanBatThisInnings(batsman: UUID) {
    const dismissalType: string = this.getPlayerStringStat(
      StatisticType.BATSMAN_DISMISSAL_METHOD,
      batsman
    );
    return (
      dismissalType === "" ||
      dismissalType === "Not Out" ||
      dismissalType === "Retired Hurt"
    );
  }

  public bowlerCanBowlThisOver(bowler: UUID) {
    if (
      !services.currentGameService.currentMatchFormat ||
      services.currentGameService.currentMatchFormat.matchFormatId.value !==
        this.matchFormatId.value
    ) {
      return false;
    }

    const maxOvers =
      services.currentGameService.currentMatchFormat.inningsConfiguration[
        this.innings - 1
      ].oversPerBowler;
    const thisOver: number = this.calculateOverParts(
      this.ballNumber,
      this.innings
    )[0];

    let consecutiveOversBowled: number = 0;
    let overCount: number = 0;

    if (
      this.inningsStats[InningsStatisticType.OVER_BOWLERS].length > 0 &&
      this.inningsStats[InningsStatisticType.OVER_BOWLERS].length >=
        this.innings
    ) {
      const inningsOvers: UUID[][] =
        this.inningsStats[InningsStatisticType.OVER_BOWLERS][this.innings - 1];
      for (let over = 0; over < thisOver; over++) {
        if (
          inningsOvers[over].find((playerId) => playerId.value === bowler.value)
        ) {
          consecutiveOversBowled++;
          overCount++;
        } else {
          consecutiveOversBowled = 0;
        }
      }
    }

    return (
      consecutiveOversBowled <
        services.currentGameService.currentMatchFormat.maxConsecutiveOvers &&
      (maxOvers === 0 || overCount < maxOvers)
    );
  }

  public getPlayerNumberStatForInnings(
    innings: number,
    statisticType: StatisticType,
    playerId: UUID,
    defaultReturnValue: number = 0
  ): number {
    if (!playerId) return null;
    return this.stats[statisticType].length > 0 &&
      this.stats[statisticType].length >= innings &&
      this.stats[statisticType][innings - 1].has(playerId.value)
      ? this.stats[statisticType][innings - 1].get(playerId.value)
      : defaultReturnValue;
  }

  public getPlayerStringStatForInnings(
    innings: number,
    statisticType: StatisticType,
    playerId: UUID
  ): string {
    if (!playerId) return null;
    return this.stats[statisticType].length > 0 &&
      this.stats[statisticType].length >= innings &&
      this.stats[statisticType][innings - 1].has(playerId.value)
      ? this.stats[statisticType][innings - 1].get(playerId.value)
      : "";
  }

  public getPlayerIntegerStat(
    statisticType: StatisticType,
    playerId: UUID,
    defaultReturnValue: number = 0
  ): number {
    return this.getPlayerNumberStatForInnings(
      this.innings,
      statisticType,
      playerId,
      defaultReturnValue
    );
  }

  public getPlayerIntegerStatAllInnings(
    statisticType: StatisticType,
    playerId: UUID,
    defaultReturnValue: number = 0
  ): number {
    let total = 0;
    for (let innings = 1; innings <= this.innings; innings++) {
      total += this.getPlayerNumberStatForInnings(
        innings,
        statisticType,
        playerId,
        defaultReturnValue
      );
    }
    return total;
  }

  public getPlayerStringStat(
    statisticType: StatisticType,
    playerId: UUID
  ): string {
    return this.getPlayerStringStatForInnings(
      this.innings,
      statisticType,
      playerId
    );
  }

  public getInningsStat(
    inningsStatisticType: InningsStatisticType,
    defaultReturnValue: number = null
  ): number {
    return this.getInningsStatForInnings(
      this.innings,
      inningsStatisticType,
      defaultReturnValue
    );
  }

  public getInningsStatForInnings(
    innings: number,
    inningsStatisticType: InningsStatisticType,
    defaultReturnValue: number = null
  ): number {
    return this.inningsStats[inningsStatisticType].length > 0 &&
      this.inningsStats[inningsStatisticType].length >= innings
      ? this.inningsStats[inningsStatisticType][innings - 1]
      : defaultReturnValue;
  }

  public getBattingTeamIntegerStatAllInnings(
    teamId: UUID,
    matchFormat: MatchFormat,
    match: Match,
    inningsStatisticType: InningsStatisticType,
    defaultReturnValue: number = 0
  ): number {
    const team1Id = match && match.team1Id;
    const teamNumber = team1Id && teamId.value === team1Id.value ? 1 : 2;
    let total = 0;
    for (let innings = 1; innings <= this.innings; innings++) {
      const battingTeamThisInnings = this.getBattingTeamForInnings(
        innings,
        matchFormat
      );
      if (battingTeamThisInnings === teamNumber) {
        total += this.getInningsStatForInnings(
          innings,
          inningsStatisticType,
          defaultReturnValue
        );
      }
    }
    return total;
  }

  public getOverBowlers(innings: number): UUID[][] {
    return this.inningsStats[InningsStatisticType.OVER_BOWLERS].length > 0 &&
      this.inningsStats[InningsStatisticType.OVER_BOWLERS].length >= innings
      ? this.inningsStats[InningsStatisticType.OVER_BOWLERS][innings - 1]
      : null;
  }

  public toString(): string {
    return `gamestate`;
  }

  public static deserializeList(json: any) {
    const gameStates: GameState[] = [];
    json.forEach((element) => {
      gameStates.push(this.deserializeOne(element));
    });
    return gameStates;
  }

  public static deserializeOne(responseJSON: any): GameState {
    return new GameState(
      UUID.fromString(responseJSON.entityId),
      UUID.fromString(responseJSON.createdBy),
      responseJSON.createdAt,
      UUID.fromString(responseJSON.matchId),
      UUID.fromString(responseJSON.matchFormatId),
      MatchType[responseJSON.matchType],
      responseJSON.innings,
      responseJSON.ballNumber,
      responseJSON.ballAttempt,
      UUID.fromString(responseJSON.bowler),
      UUID.fromString(responseJSON.batsman1),
      UUID.fromString(responseJSON.batsman2),
      responseJSON.thisBall,
      responseJSON.nextBall,
      responseJSON.started,
      responseJSON.ended,
      responseJSON.freeHit,
      responseJSON.initialStatsLoaded,
      responseJSON.inSurge,
      GameState.deserializeInningsStatsMap(responseJSON.inningsStats),
      GameState.deserializeSquads(responseJSON.squads),
      GameState.deserializeStatsMap(responseJSON.stats),
      responseJSON.requiredDecisions,
      responseJSON.superOverDecision,
      responseJSON.eventSequence
    );
  }

  public static emptyWithName(name: string): GameState {
    return new GameState(
      null,
      null,
      null,
      null,
      null,
      null,
      0,
      0,
      0,
      null,
      null,
      null,
      [0, 0],
      [0, 1],
      false,
      false,
      false,
      false,
      false,
      null,
      [],
      null,
      [],
      null,
      null
    );
  }

  public static getTypeName(): string {
    return "game state";
  }

  public static deserializeSquads(responseJSON: any[]): TeamPlayer[][] {
    const result: TeamPlayer[][] = [];

    responseJSON.forEach((squadJson: any) => {
      const squad: TeamPlayer[] = TeamPlayer.deserializeList(squadJson);
      result.push(squad);
    });

    return result;
  }

  public static deserializeStatsMap(
    responseJSON: any
  ): Record<StatisticType, Map<string, any>[]> {
    const result: Record<string, Map<string, any>[]> = {};

    Object.keys(responseJSON).forEach((statistic: string) => {
      const statisticsByInnings: Map<string, any>[] = [];
      responseJSON[statistic].forEach((inningsMap: any) => {
        const map: Map<string, any> = new Map();
        for (const [key, value] of Object.entries(inningsMap)) {
          map.set(key, value);
        }
        statisticsByInnings.push(map);
      });
      const statisticType: StatisticType = StatisticType[statistic];
      result[statisticType] = statisticsByInnings;
    });

    return result;
  }

  public static deserializeInningsStatsMap(
    responseJSON: any
  ): Record<InningsStatisticType, any[]> {
    const result: Record<string, any[]> = {};

    Object.keys(responseJSON).forEach((statistic: string) => {
      const statisticType: InningsStatisticType =
        InningsStatisticType[statistic];
      result[statisticType] = GameState.deserializeInningsStatistic(
        statisticType,
        responseJSON[statistic]
      );
    });

    return result;
  }

  private static deserializeInningsStatistic(
    statisticType: InningsStatisticType,
    responseJson: any[]
  ): any[] {
    switch (statisticType) {
      case InningsStatisticType.RUNS:
      case InningsStatisticType.WICKETS:
      case InningsStatisticType.EXTRAS:
      case InningsStatisticType.POWERPLAY_RUNS:
      case InningsStatisticType.POWERPLAY_WICKETS:
      case InningsStatisticType.POWERPLAY_BALLS:
      case InningsStatisticType.SURGE_RUNS:
      case InningsStatisticType.SURGE_WICKETS:
      case InningsStatisticType.SURGE_BALLS:
      case InningsStatisticType.TOTAL_RUNS:
      case InningsStatisticType.TOTAL_BYES:
      case InningsStatisticType.TOTAL_LEG_BYES:
      case InningsStatisticType.TOTAL_WIDES:
      case InningsStatisticType.TOTAL_NO_BALLS:
      case InningsStatisticType.DUCKWORTH_TARGET:
      case InningsStatisticType.SURGE_OVER:
        return GameState.toNumberArray(responseJson);
      case InningsStatisticType.DECLARATION:
        return responseJson as boolean[];
      case InningsStatisticType.OVER_RUNS:
      case InningsStatisticType.OVER_WICKETS:
      case InningsStatisticType.OVER_LEG_BYES:
      case InningsStatisticType.OVER_BYES:
      case InningsStatisticType.WICKET_FALLS:
        return GameState.deserializeOverRuns(responseJson);
      case InningsStatisticType.OVER_BOWLERS:
        return GameState.deserializeOverBowlers(responseJson);
      default:
        throw new Error("Unhandled innings statistic type: " + statisticType);
    }
  }

  private static toNumberArray(json: any): number[] {
    const asNumbers: number[] = [];
    json.forEach((asString: string) => {
      asNumbers.push(Number(asString));
    });
    return asNumbers;
  }

  public static deserializeOverRuns(responseJSON: any[]): number[][] {
    const overs: number[][] = [];
    responseJSON.forEach((inningsOvers: any) => {
      const thisInnings: number[] = [];
      inningsOvers.forEach((value) => thisInnings.push(Number(value)));
      overs.push(thisInnings);
    });

    return overs;
  }

  public static deserializeOverBowlers(responseJSON: any[]): UUID[][][] {
    const overBowlers: UUID[][][] = [];
    responseJSON.forEach((inningsOvers: any) => {
      const thisOverBowlers: UUID[][] = [];
      inningsOvers.forEach((value) =>
        thisOverBowlers.push(
          (value as any[]).map((val) => UUID.fromString(val))
        )
      );
      overBowlers.push(thisOverBowlers);
    });

    return overBowlers;
  }

  public static serialize(gameState: GameState): any {
    return {
      entityId: gameState.entityId === null ? null : gameState.entityId.value,
      createdBy:
        gameState.createdBy === null ? null : gameState.createdBy.value,
      createdAt: gameState.createdAt,
      matchId: gameState.matchId === null ? null : gameState.matchId.value,
      matchFormatId:
        gameState.matchFormatId === null ? null : gameState.matchFormatId.value,
      innings: gameState.innings,
      ballNumber: gameState.ballNumber,
      ballAttempt: gameState.ballAttempt,
      bowler: gameState.bowler === null ? null : gameState.bowler.value,
      batsman1: gameState.batsman1 === null ? null : gameState.batsman1.value,
      batsman2: gameState.batsman2 === null ? null : gameState.batsman2.value,
      thisBall: gameState.thisBall,
      nextBall: gameState.nextBall,
      started: gameState.started,
      ended: gameState.ended,
      freeHit: gameState.freeHit,
      initialStatsLoaded: gameState.initialStatsLoaded,
      inSurge: gameState.inSurge,
      inningsStats: gameState.inningsStats,
      squads: gameState.squads,
      stats: gameState.stats,
      requiredDecisions: gameState.requiredDecisions,
      superOverDecision: gameState.superOverDecision,
      eventSequence: gameState.eventSequence,
    };
  }
}
