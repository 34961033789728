import { Slider } from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Subscription } from "rxjs";
import { OutcomeByPhaseLine } from "../../services/percent-distribution-service";
import { BowlOutcome, bowlOutcomeNames } from "../../types/enums/bowl-outcome";
import {
  PushBracket,
  humanReadablePushBrackets,
} from "../../types/enums/push-bracket";
import { UserPreferences } from "../../types/preferences/preferences";
import { services } from "../../types/services";
import {
  PercentAdjustModuleType,
  PercentBiasAdjustData,
} from "../../types/simulator/modules/percent-adjust-modules";
import {
  PlayerStats,
  PlayerStatsWrapper,
} from "../../types/stats/player-stats";
import { buildChartOptions } from "../component-utils";
import { CreationDialog } from "../my-matches/match-creation-modals/creation-dialog";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import { NumberArcSlider } from "./number-arc-slider";

const chartOptions = buildChartOptions("top", 1);

interface Props {
  open: boolean;
  onCancel: () => void;
  onProceed: (playerStats: PlayerStats) => void;
  initialStats: PlayerStats;
  comparedStats: PlayerStats;
  comparedUserName: string;
  percentDistributionBiasProperty: string;
  percentDistributionConfidence: number;
  percentDistributionBiasPropertyName: string;
  userPreferences: UserPreferences;
}

type PercentDistributionValue =
  | "boundaryToRunsBias"
  | "fourToSixBias"
  | "oneToTwoBias"
  | "oneAndTwoToThreeBias";

export default function PlayerPercentsDistributionModal({
  open,
  onCancel,
  onProceed,
  initialStats,
  comparedStats,
  comparedUserName,
  percentDistributionBiasProperty,
  percentDistributionConfidence,
  percentDistributionBiasPropertyName,
  userPreferences,
}: Props): React.JSX.Element {
  const [stats, setStats] = useState<PlayerStats>(null);
  const [pushBracketIndex, setPushBracketIndex] = useState<number>(2);
  const [strikeRate, setStrikeRate] = useState<number>(120);
  const [outcomesByPhase, setOutcomesByPhase] =
    useState<Map<PushBracket, Map<BowlOutcome, OutcomeByPhaseLine>>>(null);

  useEffect(() => {
    const subscriptions: Subscription[] = [
      services.percentDistributionService.outcomesByPhaseSubject.subscribe(
        (
          outcomesByPhase: Map<
            PushBracket,
            Map<BowlOutcome, OutcomeByPhaseLine>
          >
        ) => setOutcomesByPhase(outcomesByPhase)
      ),
    ];

    return () =>
      subscriptions.forEach((subscription) => subscription.unsubscribe());
  }, []);

  useEffect(() => {
    const stats: PlayerStats = PlayerStatsWrapper.clone(initialStats);
    setStats(stats);
  }, [initialStats]);

  const invalid = () => {
    return (
      !stats ||
      !stats[percentDistributionBiasProperty] ||
      (!stats[percentDistributionBiasProperty].boundaryToRunsBias &&
        stats[percentDistributionBiasProperty].boundaryToRunsBias !== 0) ||
      (!stats[percentDistributionBiasProperty].fourToSixBias &&
        stats[percentDistributionBiasProperty].fourToSixBias !== 0) ||
      (!stats[percentDistributionBiasProperty].oneToTwoBias &&
        stats[percentDistributionBiasProperty].oneToTwoBias !== 0) ||
      (!stats[percentDistributionBiasProperty].oneAndTwoToThreeBias &&
        stats[percentDistributionBiasProperty].oneAndTwoToThreeBias !== 0)
    );
  };

  const updateValue = (property: PercentDistributionValue, value: number) => {
    const percentDistributionBiasData = stats[percentDistributionBiasProperty];
    percentDistributionBiasData[property] = Math.round(value * 100) / 100;

    setStats({
      ...stats,
      [percentDistributionBiasProperty]: percentDistributionBiasData,
    });
  };

  const buildChartData = () => {
    const percents: number[] = services.percentDistributionService.getPercents(
      outcomesByPhase,
      Object.values(PushBracket)[pushBracketIndex],
      strikeRate / 100,
      stats[percentDistributionBiasProperty]
    );
    const chartData = {
      labels: Object.values(BowlOutcome).map((v) => bowlOutcomeNames[v]),
      datasets: [
        {
          yAxisID: "yAxis",
          label: "Percents Distriubtion",
          data: percents,
          backgroundColor: "#348feb",
          borderColor: "#36A2EB",
        },
      ],
    };

    if (percentDistributionBiasPropertyName !== "Global" && !!userPreferences) {
      const moduleData = userPreferences.percentAdjustModules.get(
        PercentAdjustModuleType.PLAYER_PERCENT_BIAS
      ) as PercentBiasAdjustData;
      const percentsWithGlobal: number[] =
        services.percentDistributionService.getPercents(
          outcomesByPhase,
          Object.values(PushBracket)[pushBracketIndex],
          strikeRate / 100,
          services.percentDistributionService.multiplyPercentDistributionBiases(
            moduleData.confidenceLimit,
            stats.globalPercentDistributionBiasData,
            stats.battingGlobalConfidence,
            stats[percentDistributionBiasProperty],
            percentDistributionConfidence
          )
        );
      chartData.datasets.push({
        yAxisID: "yAxis",
        label: "Multiplied by Global and Weighted by Confidence",
        data: percentsWithGlobal,
        backgroundColor: "#d742f5",
        borderColor: "#d742f5",
      });
    }

    if (comparedStats) {
      const comparedPercents: number[] =
        services.percentDistributionService.getPercents(
          outcomesByPhase,
          Object.values(PushBracket)[pushBracketIndex],
          strikeRate / 100,
          comparedStats[percentDistributionBiasProperty]
        );
      chartData.datasets.push({
        yAxisID: "yAxis",
        label: `${comparedUserName}'s Distribution`,
        data: comparedPercents,
        backgroundColor: "#82e060",
        borderColor: "#82e060",
      });
    }

    return chartData;
  };

  return (
    <CreationDialog
      open={open}
      label={`${percentDistributionBiasPropertyName} Strike Rate Distribution`}
      invalid={invalid()}
      disabled={false}
      onCancel={onCancel}
      onProceed={() => onProceed(stats)}
      proceedText="OK"
      colour="#34ebb4"
    >
      <div className="percent-distribution-modal-buttons">
        <TooltipIconButton
          title="Previous"
          disabled={pushBracketIndex === 0}
          onClick={() => setPushBracketIndex(pushBracketIndex - 1)}
          icon="chevron_left"
        />
        {
          humanReadablePushBrackets[
            Object.values(PushBracket)[pushBracketIndex]
          ]
        }
        <TooltipIconButton
          title="Next"
          disabled={pushBracketIndex === Object.keys(PushBracket).length - 1}
          onClick={() => setPushBracketIndex(pushBracketIndex + 1)}
          icon="navigate_next"
        />
      </div>
      {!!stats && (
        <div className="percent-distribution-modal-content">
          <div className="bias-adjustment-knobs">
            <NumberArcSlider
              initialValue={
                stats[percentDistributionBiasProperty].boundaryToRunsBias
              }
              onChange={(value: number) =>
                updateValue("boundaryToRunsBias", value)
              }
              propertyDescription="Boundaries to Runs Bias"
            />
            <NumberArcSlider
              initialValue={
                stats[percentDistributionBiasProperty].fourToSixBias
              }
              onChange={(value: number) => updateValue("fourToSixBias", value)}
              propertyDescription="Fours to Sixes Bias"
            />
            <NumberArcSlider
              initialValue={stats[percentDistributionBiasProperty].oneToTwoBias}
              onChange={(value: number) => updateValue("oneToTwoBias", value)}
              propertyDescription="Ones to Twos Bias"
            />
            <NumberArcSlider
              initialValue={
                stats[percentDistributionBiasProperty].oneAndTwoToThreeBias
              }
              onChange={(value: number) =>
                updateValue("oneAndTwoToThreeBias", value)
              }
              propertyDescription="Ones and Twos to Threes Bias"
            />
          </div>
          <div>
            Distribution Preview
            <hr />
            <Bar
              width="500px"
              height="400px"
              options={chartOptions}
              data={buildChartData()}
            />
            <div className="strike-rate-slider-container">
              <div>Strike Rate</div>
              <div className="weighting-slider">
                <Slider
                  aria-label="Strike Rate"
                  step={1}
                  min={0}
                  max={300}
                  valueLabelDisplay="auto"
                  value={strikeRate}
                  onChange={(evt: Event, newValue: number | number[]) =>
                    setStrikeRate(newValue as number)
                  }
                  onChangeCommitted={(
                    evt: ChangeEvent,
                    newValue: number | number[]
                  ) => setStrikeRate(newValue as number)}
                />
                <div className="weighting-slider-label">{strikeRate}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </CreationDialog>
  );
}
