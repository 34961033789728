import { ExtrasType } from "../../types/enums/extras-type";
import { PlayerStatsWrapper } from "../../types/stats/player-stats";
import { NumberSelector } from "../entity-management/entity-selectors/number-selector";
import { NumberComparison } from "../stats-editing-components/number-comparison";
import {
  areStatsEqual,
  getToolTipMessage,
} from "../stats-editing-components/stats-editing-utils";
import StealStatsButton from "../stats-editing-components/steal-stats-button";
import { batchUpdate } from "./squad-page-utils";

type Props = {
  currentStats: PlayerStatsWrapper;
  comparedStats: PlayerStatsWrapper;
  comparedUserName: string;
  onUpdate: (updatedStats: PlayerStatsWrapper) => void;
};

export function PlayerExtrasBiasesByTypeComponent({
  currentStats,
  comparedStats,
  comparedUserName,
  onUpdate,
}: Props): React.JSX.Element {
  const STATS_TO_STEAL: string[] = ["bowlerExtrasBiasesByType"];

  const onClickHandler = () => {
    batchUpdate(currentStats, comparedStats, STATS_TO_STEAL, onUpdate);
  };

  const callUpdateFunction = (extrasType: ExtrasType, newValue: number) => {
    const bowlerExtrasBiasesByType = new Map(
      currentStats.playerStats.bowlerExtrasBiasesByType
    );
    bowlerExtrasBiasesByType.set(extrasType, newValue);

    onUpdate(
      new PlayerStatsWrapper(
        currentStats.playerStatsId,
        currentStats.createdBy,
        currentStats.createdAt,
        currentStats.name,
        { ...currentStats.playerStats, bowlerExtrasBiasesByType },
        currentStats.matchType
      )
    );
  };

  const bowlerExtrasBiasesByType =
    currentStats.playerStats.bowlerExtrasBiasesByType;
  let comparedBowlerExtrasBiasesByType;
  if (comparedStats) {
    comparedBowlerExtrasBiasesByType =
      comparedStats.playerStats.bowlerExtrasBiasesByType;
  }

  return (
    <div className="stats-modal-section">
      <div className="stats-modal-section-head">Extras Type Biases</div>
      <div>
        <NumberSelector
          className="stats-modal-number-selector"
          textFieldClassName="stats-modal-number-selector-textfield"
          label="Wide:"
          min={0}
          max={1000000}
          step={0.01}
          decimalPlaces={2}
          initial={bowlerExtrasBiasesByType.get(ExtrasType.WIDE)}
          onValid={(newValue) => callUpdateFunction(ExtrasType.WIDE, newValue)}
        />
        <NumberSelector
          className="stats-modal-number-selector"
          textFieldClassName="stats-modal-number-selector-textfield"
          label="No Ball:"
          min={0}
          max={1000000}
          step={0.01}
          decimalPlaces={2}
          initial={bowlerExtrasBiasesByType.get(ExtrasType.NO_BALL)}
          onValid={(newValue) =>
            callUpdateFunction(ExtrasType.NO_BALL, newValue)
          }
        />
        <NumberSelector
          className="stats-modal-number-selector"
          textFieldClassName="stats-modal-number-selector-textfield"
          label="Bye:"
          min={0}
          max={1000000}
          step={0.01}
          decimalPlaces={2}
          initial={bowlerExtrasBiasesByType.get(ExtrasType.BYE)}
          onValid={(newValue) => callUpdateFunction(ExtrasType.BYE, newValue)}
        />
        <NumberSelector
          className="stats-modal-number-selector"
          textFieldClassName="stats-modal-number-selector-textfield"
          label="Leg Bye:"
          min={0}
          max={1000000}
          step={0.01}
          decimalPlaces={2}
          initial={bowlerExtrasBiasesByType.get(ExtrasType.LEG_BYE)}
          onValid={(newValue) =>
            callUpdateFunction(ExtrasType.LEG_BYE, newValue)
          }
        />

        {!!comparedStats && (
          <div className="comparison">
            <div className="comparison-title-and-steal-button">
              <span>Compared to {comparedUserName}</span>
              <StealStatsButton
                comparedUserName={comparedUserName}
                disabled={areStatsEqual(
                  currentStats,
                  comparedStats,
                  STATS_TO_STEAL,
                  "playerStats"
                )}
                tooltipMessage={getToolTipMessage(
                  currentStats,
                  comparedStats,
                  STATS_TO_STEAL,
                  comparedUserName,
                  "playerStats"
                )}
                onClickHandler={onClickHandler}
              />
            </div>
            <NumberComparison
              label="Wide"
              originalValue={bowlerExtrasBiasesByType.get(ExtrasType.WIDE)}
              comparedValue={comparedBowlerExtrasBiasesByType.get(
                ExtrasType.WIDE
              )}
            />
            <NumberComparison
              label="No Ball"
              originalValue={bowlerExtrasBiasesByType.get(ExtrasType.NO_BALL)}
              comparedValue={comparedBowlerExtrasBiasesByType.get(
                ExtrasType.NO_BALL
              )}
            />
            <NumberComparison
              label="Bye"
              originalValue={bowlerExtrasBiasesByType.get(ExtrasType.BYE)}
              comparedValue={comparedBowlerExtrasBiasesByType.get(
                ExtrasType.BYE
              )}
            />
            <NumberComparison
              label="Leg Bye"
              originalValue={bowlerExtrasBiasesByType.get(ExtrasType.LEG_BYE)}
              comparedValue={comparedBowlerExtrasBiasesByType.get(
                ExtrasType.LEG_BYE
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
}
