import { Ball } from "../entities/ball";
import { GroundStats } from "./ground-stats";
import { PlayerStats, PlayerStatsWrapper } from "./player-stats";

export interface BowlWithExpectation {
  bowl: Ball;
  push: number;
  wicketPercent: number;
  strikeRate: number;
  extras: number;
  wides: number;
  noBalls: number;
  byes: number;
  legByes: number;
  date: number;
  score: number;
  wickets: number;
  bowlerName: string;
  batsmanName: string;
  groundName: string;
  bowlerStats: PlayerStats;
  batsmanStats: PlayerStats;
  groundStats: GroundStats;
  batsmanWeighting: number;
  bowlerWeighting: number;
  groundWeighting: number;
}

export function deserializeBowlWithExpectation(json: any): BowlWithExpectation {
  return {
    bowl: Ball.deserializeOne(json.bowl),
    push: json.push,
    wicketPercent: json.wicketPercent,
    strikeRate: json.strikeRate,
    extras: json.extras,
    wides: json.wides,
    noBalls: json.noBalls,
    byes: json.byes,
    legByes: json.legByes,
    date: json.date,
    score: json.score,
    wickets: json.wickets,
    bowlerName: json.bowlerName,
    batsmanName: json.batsmanName,
    groundName: json.groundName,
    bowlerStats: PlayerStatsWrapper.deserializePlayerStats(json.bowlerStats),
    batsmanStats: PlayerStatsWrapper.deserializePlayerStats(json.batsmanStats),
    groundStats: json.groundStats,
    batsmanWeighting: json.batsmanWeighting,
    bowlerWeighting: json.bowlerWeighting,
    groundWeighting: json.groundWeighting,
  };
}
