export enum BetType {
  MATCH_ODDS = "MATCH_ODDS",
  FALL_OF_WICKET = "FALL_OF_WICKET",
  SESSION = "SESSION",
  INNINGS_RUNS = "INNINGS_RUNS",
  BATSMAN_RUNS = "BATSMAN_RUNS",
  BATSMAN_BOUNDARIES = "BATSMAN_BOUNDARIES",
  BATSMAN_BALLS_FACED = "BATSMAN_BALLS_FACED",
  MATCH_RUNS = "MATCH_RUNS",
  TIED_MATCH = "TIED_MATCH",
  TOP_BAT = "TOP_BAT",
  UNKNOWN = "UNKNOWN",
}
