import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useState } from "react";
import { GameState } from "../../types/entities/game-state";
import { MatchFormat } from "../../types/entities/match-format";
import { Team } from "../../types/entities/team";
import {
  InningsSimulationQuestionStatistic,
  inningsSimulationQuestionStatisticNames,
  inningsSimulationQuestionStatisticRequiresInputs,
  inningsSimulationQuestionStatisticWithoutNumbers,
} from "../../types/enums/question-statistic";
import {
  SimulatorOddsQuestionOperator,
  operatorNames,
} from "../../types/enums/questions-operator";
import { InningsSimulationOddsQuestion } from "../../types/simulator/questions/simulation-odds-question";
import { EnumSelector } from "../entity-management/entity-selectors/enum-selector";
import { NumberSelector } from "../entity-management/entity-selectors/number-selector";
import { getTeamNameForId } from "../simulator-page/simulator-utils";

interface Props {
  matchFormat: MatchFormat;
  gameState: GameState;
  team1: Team;
  team2: Team;
  onChange: (question: InningsSimulationOddsQuestion) => void;
  question: InningsSimulationOddsQuestion;
}

export default function InningsSimulatorOddsQuestionEditor({
  matchFormat,
  gameState,
  team1,
  team2,
  onChange,
  question,
}: Props): React.JSX.Element {
  const [byInnings, setByInnings] = useState<boolean>(!question.teamId);

  return (
    <div>
      <div
        className="stored-question-editor"
        style={{
          gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr",
        }}
      >
        <EnumSelector
          classes="dialog-content"
          label="Statistic"
          enumObject={InningsSimulationQuestionStatistic}
          value={question.questionStatistic}
          readableValues={inningsSimulationQuestionStatisticNames}
          onSelect={(value) =>
            onChange({
              ...question,
              questionStatistic: value,
              operator:
                inningsSimulationQuestionStatisticWithoutNumbers.includes(value)
                  ? SimulatorOddsQuestionOperator.EQUALS
                  : question.operator,
              operand:
                inningsSimulationQuestionStatisticWithoutNumbers.includes(value)
                  ? 1
                  : question.operand,
            })
          }
          disabled={false}
          canClear={false}
        />

        <div className="median-radio">
          <RadioGroup
            row
            onChange={(event) => {
              setByInnings(event.target.value === "by innings");
              onChange({
                ...question,
                teamId:
                  event.target.value === "by innings"
                    ? null
                    : team1.teamId.value,
              });
            }}
            name="by-innings-radio-group"
            value={byInnings ? "by innings" : "by team"}
          >
            <FormControlLabel
              className="median-radio-form"
              value={"by innings"}
              control={<Radio />}
              label={<div className="median-radio-form">By Innings</div>}
            />
            <FormControlLabel
              className="median-radio-form"
              value={"by team"}
              control={<Radio />}
              label={<div className="median-radio-form">By Team</div>}
            />
          </RadioGroup>
        </div>
        {byInnings && (
          <NumberSelector
            label={"Innings: "}
            className="dialog-content"
            textFieldClassName="tall-number-selector small-number-selector"
            min={1}
            max={!!matchFormat ? matchFormat.inningsConfiguration.length : 1}
            step={1}
            decimalPlaces={0}
            initial={question.innings}
            onValid={(valid: number) =>
              onChange({
                ...question,
                innings: valid,
                teamId: null,
              })
            }
            disabled={!byInnings}
          />
        )}

        {!byInnings && (
          <div className="median-radio">
            <RadioGroup
              row
              onChange={(event) =>
                onChange({
                  ...question,
                  teamId: event.target.value,
                })
              }
              name="team-radio-group"
              value={question.teamId}
            >
              <FormControlLabel
                value={gameState.getTeamId(1).value}
                className="median-radio-form"
                control={<Radio />}
                label={
                  <div className="median-radio-form">
                    {getTeamNameForId(
                      gameState.getTeamId(1).value,
                      team1,
                      team2
                    )}
                  </div>
                }
                disabled={byInnings}
              />
              <FormControlLabel
                value={gameState.getTeamId(2).value}
                className="median-radio-form"
                control={<Radio />}
                label={
                  <div className="median-radio-form">
                    {getTeamNameForId(
                      gameState.getTeamId(2).value,
                      team1,
                      team2
                    )}
                  </div>
                }
                disabled={byInnings}
              />
            </RadioGroup>
          </div>
        )}
        <div className="median-question-editor-number-selectors">
          {!!inningsSimulationQuestionStatisticRequiresInputs[
            question.questionStatistic
          ] &&
            inningsSimulationQuestionStatisticRequiresInputs[
              question.questionStatistic
            ][1] && (
              <NumberSelector
                label={"Wickets: "}
                className="dialog-content"
                textFieldClassName="tall-number-selector"
                min={0}
                max={
                  !!matchFormat &&
                  !!matchFormat.inningsConfiguration &&
                  !!matchFormat.inningsConfiguration[question.innings - 1]
                    ? matchFormat.inningsConfiguration[question.innings - 1]
                        .maxWickets
                    : 0
                }
                step={1}
                decimalPlaces={0}
                initial={question.wicketsFallen}
                onValid={(valid: number) =>
                  onChange({
                    ...question,
                    wicketsFallen: valid,
                  })
                }
              />
            )}
          {!!inningsSimulationQuestionStatisticRequiresInputs[
            question.questionStatistic
          ] &&
            inningsSimulationQuestionStatisticRequiresInputs[
              question.questionStatistic
            ][0] && (
              <NumberSelector
                label={"Overs: "}
                className="dialog-content"
                textFieldClassName="tall-number-selector"
                min={0}
                max={
                  !!matchFormat &&
                  !!matchFormat.overConfiguration &&
                  !!matchFormat.overConfiguration[question.innings - 1]
                    ? matchFormat.overConfiguration[question.innings - 1].length
                    : 1
                }
                step={1}
                decimalPlaces={0}
                initial={question.over}
                onValid={(valid: number) =>
                  onChange({
                    ...question,
                    over: valid,
                  })
                }
              />
            )}
        </div>
        {!inningsSimulationQuestionStatisticWithoutNumbers.includes(
          question.questionStatistic
        ) && (
          <>
            <div>
              <EnumSelector
                classes="dialog-content"
                enumObject={SimulatorOddsQuestionOperator}
                label="Operator"
                value={question.operator}
                readableValues={operatorNames}
                onSelect={(value) =>
                  onChange({
                    ...question,
                    operator: value,
                  })
                }
                disabled={false}
                canClear={false}
              />
            </div>
            <div>
              <NumberSelector
                label={"Value:"}
                className="dialog-content"
                textFieldClassName="tall-number-selector"
                min={0}
                max={10000}
                step={1}
                decimalPlaces={0}
                initial={question.operand}
                onValid={(valid: number) =>
                  onChange({
                    ...question,
                    operand: valid,
                  })
                }
                disabled={false}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
