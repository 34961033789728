import { FC } from "react";
import { NumberSelector } from "../../entity-management/entity-selectors/number-selector";
import { formatToDpWithDash } from "../simulator-utils";

interface Props {
  odds: number;
  value: number;
  onChange: (operand: number) => void;
}

const SimulatorPlayerOddsQuestionEditor: FC<Props> = ({
  odds,
  value,
  onChange,
}) => {
  return (
    <div className="player-odds-selector">
      <div className="odds-selector-and-answer">
        <NumberSelector
          label="Odds of: "
          className="median-number-selector"
          min={-1000}
          max={1000}
          step={1}
          decimalPlaces={1}
          initial={value}
          onValid={(valid: number) => onChange(valid)}
        />
        <div className="median-answer">{formatToDpWithDash(odds, 2)}</div>
      </div>
    </div>
  );
};

export default SimulatorPlayerOddsQuestionEditor;
