import { Component } from "react";

import { MatchStatsWrapper } from "../../types/stats/match-stats";
import { NumberSelector } from "../entity-management/entity-selectors/number-selector";
import { NumberComparison } from "../stats-editing-components/number-comparison";
import {
  areStatsEqual,
  getToolTipMessage,
} from "../stats-editing-components/stats-editing-utils";
import StealStatsButton from "../stats-editing-components/steal-stats-button";

interface Props {
  currentStats: MatchStatsWrapper;
  comparedStats: MatchStatsWrapper;
  comparedUserName: string;
  onSave: (updatedStats: MatchStatsWrapper, valid: boolean) => void;
  teamBattingFirst: number;
  team1Name: string;
  team2Name: string;
}

export class ThoughtWicketMultiplierComponent extends Component<Props, {}> {
  INNINGS_1_TEAM_1_STATS_TO_STEAL: string[] = [
    "twmAtLastBallInnings1Team1",
    "twmAdditionInnings1Team1",
  ];
  INNINGS_2_TEAM_1_STATS_TO_STEAL: string[] = [
    "twmAtLastBallInnings2Team1",
    "twmAdditionInnings2Team1",
  ];
  INNINGS_1_TEAM_2_STATS_TO_STEAL: string[] = [
    "twmAtLastBallInnings1Team2",
    "twmAdditionInnings1Team2",
  ];
  INNINGS_2_TEAM_2_STATS_TO_STEAL: string[] = [
    "twmAtLastBallInnings2Team2",
    "twmAdditionInnings2Team2",
  ];

  private propsLoaded(): boolean {
    return !!this.props.currentStats;
  }

  private onClickHandler(updates: string[]) {
    updates.forEach((update) => {
      this.updateStats(update, this.props.comparedStats.matchStats[update]);
    });
  }

  private updateStats(property: string, value) {
    const updatedStats: MatchStatsWrapper = this.props.currentStats;
    updatedStats.matchStats[property] = value;
    this.props.onSave(updatedStats, true);
  }

  public render() {
    return (
      <div>
        {this.propsLoaded() && (
          <div className="stats-modal-section">
            <div className="stats-modal-section-head">
              Thought Wicket Multiplier
            </div>

            <div className="home-advantage-batting-and-bowling">
              <div className="home-advantage-section">
                <div className="home-advantage-subtitle">
                  {this.props.team1Name}
                </div>
                {(this.props.teamBattingFirst === 0 ||
                  this.props.teamBattingFirst === 1) && (
                  <div>
                    {this.props.teamBattingFirst === 0 && (
                      <div>Batting First</div>
                    )}
                    <div>
                      <div className="home-advantage-input">
                        <NumberSelector
                          className="stats-modal-number-selector"
                          textFieldClassName="stats-modal-number-selector-textfield"
                          label="At last ball"
                          min={0}
                          max={10}
                          step={0.01}
                          decimalPlaces={2}
                          initial={
                            this.props.currentStats.matchStats
                              .twmAtLastBallInnings1Team1
                          }
                          onValid={(newValue) =>
                            this.updateStats(
                              "twmAtLastBallInnings1Team1",
                              newValue
                            )
                          }
                        />
                        <NumberSelector
                          className="stats-modal-number-selector"
                          textFieldClassName="stats-modal-number-selector-textfield"
                          label="Addition per ball remaining"
                          min={0}
                          max={1}
                          step={0.0001}
                          decimalPlaces={4}
                          initial={
                            this.props.currentStats.matchStats
                              .twmAdditionInnings1Team1
                          }
                          onValid={(newValue) =>
                            this.updateStats(
                              "twmAdditionInnings1Team1",
                              newValue
                            )
                          }
                        />
                      </div>

                      {!!this.props.comparedStats && (
                        <div className="comparison">
                          <div className="comparison-title-and-steal-button">
                            <span>
                              Compared to {this.props.comparedUserName}
                            </span>
                            <StealStatsButton
                              comparedUserName={this.props.comparedUserName}
                              disabled={areStatsEqual(
                                this.props.currentStats,
                                this.props.comparedStats,
                                this.INNINGS_1_TEAM_1_STATS_TO_STEAL,
                                "matchStats"
                              )}
                              tooltipMessage={getToolTipMessage(
                                this.props.currentStats,
                                this.props.comparedStats,
                                this.INNINGS_1_TEAM_1_STATS_TO_STEAL,
                                this.props.comparedUserName,
                                "matchStats"
                              )}
                              onClickHandler={this.onClickHandler.bind(
                                this,
                                this.INNINGS_1_TEAM_1_STATS_TO_STEAL
                              )}
                            />
                          </div>

                          <div className="match-stats-weights">
                            <NumberComparison
                              label="At last ball"
                              originalValue={
                                this.props.currentStats.matchStats
                                  .twmAtLastBallInnings1Team1
                              }
                              comparedValue={
                                this.props.comparedStats.matchStats
                                  .twmAtLastBallInnings1Team1
                              }
                            />
                            <NumberComparison
                              label="Addition per ball remaining"
                              originalValue={
                                this.props.currentStats.matchStats
                                  .twmAdditionInnings1Team1
                              }
                              comparedValue={
                                this.props.comparedStats.matchStats
                                  .twmAdditionInnings1Team1
                              }
                              decimalPlaces={4}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {(this.props.teamBattingFirst === 0 ||
                  this.props.teamBattingFirst === 2) && (
                  <div>
                    {this.props.teamBattingFirst === 0 && (
                      <div>Batting Second</div>
                    )}
                    <div>
                      <div className="home-advantage-input">
                        <NumberSelector
                          className="stats-modal-number-selector"
                          textFieldClassName="stats-modal-number-selector-textfield"
                          label="At last ball"
                          min={0}
                          max={10}
                          step={0.01}
                          decimalPlaces={2}
                          initial={
                            this.props.currentStats.matchStats
                              .twmAtLastBallInnings2Team1
                          }
                          onValid={(newValue) =>
                            this.updateStats(
                              "twmAtLastBallInnings2Team1",
                              newValue
                            )
                          }
                        />
                        <NumberSelector
                          className="stats-modal-number-selector"
                          textFieldClassName="stats-modal-number-selector-textfield"
                          label="Addition per ball remaining"
                          min={0}
                          max={1}
                          step={0.0001}
                          decimalPlaces={4}
                          initial={
                            this.props.currentStats.matchStats
                              .twmAdditionInnings2Team1
                          }
                          onValid={(newValue) =>
                            this.updateStats(
                              "twmAdditionInnings2Team1",
                              newValue
                            )
                          }
                        />
                      </div>

                      {!!this.props.comparedStats && (
                        <div className="comparison">
                          <div className="comparison-title-and-steal-button">
                            <span>
                              Compared to {this.props.comparedUserName}
                            </span>
                            <StealStatsButton
                              comparedUserName={this.props.comparedUserName}
                              disabled={areStatsEqual(
                                this.props.currentStats,
                                this.props.comparedStats,
                                this.INNINGS_2_TEAM_1_STATS_TO_STEAL,
                                "matchStats"
                              )}
                              tooltipMessage={getToolTipMessage(
                                this.props.currentStats,
                                this.props.comparedStats,
                                this.INNINGS_2_TEAM_1_STATS_TO_STEAL,
                                this.props.comparedUserName,
                                "matchStats"
                              )}
                              onClickHandler={this.onClickHandler.bind(
                                this,
                                this.INNINGS_2_TEAM_1_STATS_TO_STEAL
                              )}
                            />
                          </div>

                          <div className="match-stats-weights">
                            <NumberComparison
                              label="At last ball"
                              originalValue={
                                this.props.currentStats.matchStats
                                  .twmAtLastBallInnings2Team1
                              }
                              comparedValue={
                                this.props.comparedStats.matchStats
                                  .twmAtLastBallInnings2Team1
                              }
                            />
                            <NumberComparison
                              label="Addition per ball remaining"
                              originalValue={
                                this.props.currentStats.matchStats
                                  .twmAdditionInnings2Team1
                              }
                              comparedValue={
                                this.props.comparedStats.matchStats
                                  .twmAdditionInnings2Team1
                              }
                              decimalPlaces={4}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className="home-advantage-section">
                <div className="home-advantage-subtitle">
                  {this.props.team2Name}
                </div>
                {(this.props.teamBattingFirst === 0 ||
                  this.props.teamBattingFirst === 2) && (
                  <div>
                    {this.props.teamBattingFirst === 0 && (
                      <div>Batting First</div>
                    )}
                    <div>
                      <div className="home-advantage-input">
                        <NumberSelector
                          className="stats-modal-number-selector"
                          textFieldClassName="stats-modal-number-selector-textfield"
                          label="At last ball"
                          min={0}
                          max={10}
                          step={0.01}
                          decimalPlaces={2}
                          initial={
                            this.props.currentStats.matchStats
                              .twmAtLastBallInnings1Team2
                          }
                          onValid={(newValue) =>
                            this.updateStats(
                              "twmAtLastBallInnings1Team2",
                              newValue
                            )
                          }
                        />
                        <NumberSelector
                          className="stats-modal-number-selector"
                          textFieldClassName="stats-modal-number-selector-textfield"
                          label="Addition per ball remaining"
                          min={0}
                          max={1}
                          step={0.0001}
                          decimalPlaces={4}
                          initial={
                            this.props.currentStats.matchStats
                              .twmAdditionInnings1Team2
                          }
                          onValid={(newValue) =>
                            this.updateStats(
                              "twmAdditionInnings1Team2",
                              newValue
                            )
                          }
                        />
                      </div>

                      {!!this.props.comparedStats && (
                        <div className="comparison">
                          <div className="comparison-title-and-steal-button">
                            <span>
                              Compared to {this.props.comparedUserName}
                            </span>
                            <StealStatsButton
                              comparedUserName={this.props.comparedUserName}
                              disabled={areStatsEqual(
                                this.props.currentStats,
                                this.props.comparedStats,
                                this.INNINGS_1_TEAM_2_STATS_TO_STEAL,
                                "matchStats"
                              )}
                              tooltipMessage={getToolTipMessage(
                                this.props.currentStats,
                                this.props.comparedStats,
                                this.INNINGS_1_TEAM_2_STATS_TO_STEAL,
                                this.props.comparedUserName,
                                "matchStats"
                              )}
                              onClickHandler={this.onClickHandler.bind(
                                this,
                                this.INNINGS_1_TEAM_2_STATS_TO_STEAL
                              )}
                            />
                          </div>

                          <div className="match-stats-weights">
                            <NumberComparison
                              label="At last ball"
                              originalValue={
                                this.props.currentStats.matchStats
                                  .twmAtLastBallInnings1Team2
                              }
                              comparedValue={
                                this.props.comparedStats.matchStats
                                  .twmAtLastBallInnings1Team2
                              }
                            />
                            <NumberComparison
                              label="Addition per ball remaining"
                              originalValue={
                                this.props.currentStats.matchStats
                                  .twmAdditionInnings1Team2
                              }
                              comparedValue={
                                this.props.comparedStats.matchStats
                                  .twmAdditionInnings1Team2
                              }
                              decimalPlaces={4}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {(this.props.teamBattingFirst === 0 ||
                  this.props.teamBattingFirst === 1) && (
                  <div>
                    {this.props.teamBattingFirst === 0 && (
                      <div>Batting Second</div>
                    )}
                    <div>
                      <div className="home-advantage-input">
                        <NumberSelector
                          className="stats-modal-number-selector"
                          textFieldClassName="stats-modal-number-selector-textfield"
                          label="At last ball"
                          min={0}
                          max={10}
                          step={0.01}
                          decimalPlaces={2}
                          initial={
                            this.props.currentStats.matchStats
                              .twmAtLastBallInnings2Team2
                          }
                          onValid={(newValue) =>
                            this.updateStats(
                              "twmAtLastBallInnings2Team2",
                              newValue
                            )
                          }
                        />
                        <NumberSelector
                          className="stats-modal-number-selector"
                          textFieldClassName="stats-modal-number-selector-textfield"
                          label="Addition per ball remaining"
                          min={0}
                          max={1}
                          step={0.0001}
                          decimalPlaces={4}
                          initial={
                            this.props.currentStats.matchStats
                              .twmAdditionInnings2Team2
                          }
                          onValid={(newValue) =>
                            this.updateStats(
                              "twmAdditionInnings2Team2",
                              newValue
                            )
                          }
                        />
                      </div>

                      {!!this.props.comparedStats && (
                        <div className="comparison">
                          <div className="comparison-title-and-steal-button">
                            <span>
                              Compared to {this.props.comparedUserName}
                            </span>
                            <StealStatsButton
                              comparedUserName={this.props.comparedUserName}
                              disabled={areStatsEqual(
                                this.props.currentStats,
                                this.props.comparedStats,
                                this.INNINGS_2_TEAM_2_STATS_TO_STEAL,
                                "matchStats"
                              )}
                              tooltipMessage={getToolTipMessage(
                                this.props.currentStats,
                                this.props.comparedStats,
                                this.INNINGS_2_TEAM_2_STATS_TO_STEAL,
                                this.props.comparedUserName,
                                "matchStats"
                              )}
                              onClickHandler={this.onClickHandler.bind(
                                this,
                                this.INNINGS_2_TEAM_2_STATS_TO_STEAL
                              )}
                            />
                          </div>

                          <div className="match-stats-weights">
                            <NumberComparison
                              label="At last ball"
                              originalValue={
                                this.props.currentStats.matchStats
                                  .twmAtLastBallInnings2Team2
                              }
                              comparedValue={
                                this.props.comparedStats.matchStats
                                  .twmAtLastBallInnings2Team2
                              }
                            />
                            <NumberComparison
                              label="Addition per ball remaining"
                              originalValue={
                                this.props.currentStats.matchStats
                                  .twmAdditionInnings2Team2
                              }
                              comparedValue={
                                this.props.comparedStats.matchStats
                                  .twmAdditionInnings2Team2
                              }
                              decimalPlaces={4}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
