import React from "react";

import { PlayerStatsWrapper } from "../../types/stats/player-stats";
import { NumberSelector } from "../entity-management/entity-selectors/number-selector";
import { NumberComparison } from "../stats-editing-components/number-comparison";
import {
  areStatsEqual,
  getToolTipMessage,
} from "../stats-editing-components/stats-editing-utils";
import StealStatsButton from "../stats-editing-components/steal-stats-button";

import { UserPreferences } from "../../types/preferences/preferences";
import BatsmanAccelerationDisplay from "./batsman-acceleration-display";
import BattingStatsByPush from "./batting-stats-by-push";
import { batchUpdate } from "./squad-page-utils";
import StrikeRateAndWicketPercentStats from "./strike-rate-and-wicket-percent-stats";

type Props = {
  currentStats: PlayerStatsWrapper;
  comparedStats: PlayerStatsWrapper;
  comparedUserName: string;
  userPreferences: UserPreferences;
  onUpdate: (updatedStats: PlayerStatsWrapper) => void;
  onValid: (property: string, value) => void;
  onArrayElementValid: (property: string, index: number, value) => void;
};

const MISCELLANEOUS_STATS_TO_STEAL: string[] = [
  "widesMultiplier",
  "averagePosition",
  "battingPosition",
];
const PUSH_STATS_TO_STEAL: string[] = [
  "pushAdjustment",
  "pushAdjustmentUpToBall",
];
const SECOND_INNINGS_STATS_TO_STEAL: string[] = ["behindLimit", "aheadLimit"];
const POWERPLAY_STATS_TO_STEAL: string[] = [
  "powerplayPushAdjustment",
  "powerplayWicketBias",
];

export function PlayerBattingStatsDisplay({
  currentStats,
  comparedStats = null,
  comparedUserName = null,
  userPreferences,
  onUpdate,
  onValid,
  onArrayElementValid,
}: Props): React.JSX.Element {
  const onClickHandler = (updates: string[]) => {
    batchUpdate(currentStats, comparedStats, updates, onUpdate);
  };

  return (
    <div className="stats-modal-content">
      <BattingStatsByPush
        currentStats={currentStats}
        comparedStats={comparedStats}
        comparedUserName={comparedUserName}
        userPreferences={userPreferences}
        onArrayElementValid={onArrayElementValid}
        onUpdate={onUpdate}
      />

      <StrikeRateAndWicketPercentStats
        currentStats={currentStats}
        comparedStats={comparedStats}
        comparedUserName={comparedUserName}
        userPreferences={userPreferences}
        onUpdate={onUpdate}
      />

      <div className="stats-modal-section-pair">
        <BatsmanAccelerationDisplay
          currentStats={currentStats}
          comparedStats={comparedStats}
          comparedUserName={comparedUserName}
          onArrayElementValid={onArrayElementValid}
          onUpdate={onUpdate}
        />

        <div className="stats-modal-section">
          <div className="stats-modal-section-head">Other Stats</div>
          <div className="batting-stats-modal-4-columns">
            <div className="stats-modal-section">
              <div className="stats-modal-section-head">Miscellaneous</div>
              <div className="input-and-comparison">
                <div>
                  <NumberSelector
                    className="stats-modal-number-selector"
                    textFieldClassName="stats-modal-number-selector-textfield"
                    label="Wides Multiplier"
                    min={0}
                    max={10000}
                    step={0.01}
                    decimalPlaces={2}
                    initial={currentStats.playerStats.widesMultiplier}
                    onValid={(newValue) => onValid("widesMultiplier", newValue)}
                  />
                  <NumberSelector
                    className="stats-modal-number-selector"
                    textFieldClassName="stats-modal-number-selector-textfield"
                    label="Average Position"
                    min={0}
                    max={10000}
                    step={0.01}
                    decimalPlaces={2}
                    initial={currentStats.playerStats.averagePosition}
                    onValid={(newValue) => onValid("averagePosition", newValue)}
                  />
                  <NumberSelector
                    className="stats-modal-number-selector"
                    textFieldClassName="stats-modal-number-selector-textfield"
                    label="Batting Experience"
                    min={0}
                    max={1}
                    step={0.01}
                    decimalPlaces={2}
                    initial={currentStats.playerStats.battingExperience}
                    onValid={(newValue) =>
                      onValid("battingExperience", newValue)
                    }
                  />
                </div>
                {!!comparedStats && (
                  <div className="comparison">
                    <div className="comparison-title-and-steal-button">
                      <span>Compared to {comparedUserName}</span>
                      <StealStatsButton
                        comparedUserName={comparedUserName}
                        disabled={areStatsEqual(
                          currentStats,
                          comparedStats,
                          MISCELLANEOUS_STATS_TO_STEAL,
                          "playerStats"
                        )}
                        tooltipMessage={getToolTipMessage(
                          currentStats,
                          comparedStats,
                          MISCELLANEOUS_STATS_TO_STEAL,
                          comparedUserName,
                          "playerStats"
                        )}
                        onClickHandler={() =>
                          onClickHandler(MISCELLANEOUS_STATS_TO_STEAL)
                        }
                      />
                    </div>
                    <NumberComparison
                      label="Wides Multiplier"
                      originalValue={currentStats.playerStats.widesMultiplier}
                      comparedValue={comparedStats.playerStats.widesMultiplier}
                    />
                    <NumberComparison
                      label="Average Position"
                      originalValue={currentStats.playerStats.averagePosition}
                      comparedValue={comparedStats.playerStats.averagePosition}
                    />
                    <NumberComparison
                      label="Batting Experience"
                      originalValue={currentStats.playerStats.battingExperience}
                      comparedValue={
                        comparedStats.playerStats.battingExperience
                      }
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="stats-modal-section">
              <div className="stats-modal-section-head">Push Settings</div>
              <div className="input-and-comparison">
                <div>
                  <NumberSelector
                    className="stats-modal-number-selector"
                    textFieldClassName="stats-modal-number-selector-textfield"
                    label="Adjust Push..."
                    min={-10000}
                    max={10000}
                    initial={currentStats.playerStats.pushAdjustment}
                    onValid={(newValue) => onValid("pushAdjustment", newValue)}
                  />
                  <NumberSelector
                    className="stats-modal-number-selector"
                    textFieldClassName="stats-modal-number-selector-textfield"
                    label="...Up to Ball"
                    min={0}
                    max={10000}
                    initial={currentStats.playerStats.pushAdjustmentUpToBall}
                    onValid={(newValue) =>
                      onValid("pushAdjustmentUpToBall", newValue)
                    }
                  />
                </div>
                {!!comparedStats && (
                  <div className="comparison">
                    <div className="comparison-title-and-steal-button">
                      <span>Compared to {comparedUserName}</span>
                      <StealStatsButton
                        comparedUserName={comparedUserName}
                        disabled={areStatsEqual(
                          currentStats,
                          comparedStats,
                          PUSH_STATS_TO_STEAL,
                          "playerStats"
                        )}
                        tooltipMessage={getToolTipMessage(
                          currentStats,
                          comparedStats,
                          PUSH_STATS_TO_STEAL,
                          comparedUserName,
                          "playerStats"
                        )}
                        onClickHandler={() =>
                          onClickHandler(PUSH_STATS_TO_STEAL)
                        }
                      />
                    </div>
                    <NumberComparison
                      label="Adjust Push..."
                      originalValue={currentStats.playerStats.pushAdjustment}
                      comparedValue={comparedStats.playerStats.pushAdjustment}
                    />
                    <NumberComparison
                      label="...Up to Ball"
                      originalValue={
                        currentStats.playerStats.pushAdjustmentUpToBall
                      }
                      comparedValue={
                        comparedStats.playerStats.pushAdjustmentUpToBall
                      }
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="stats-modal-section">
              <div className="stats-modal-section-head">Second innings</div>
              <div className="input-and-comparison">
                <div>
                  <div className="stats-modal-number-selector">
                    Adjust push when...
                  </div>
                  <NumberSelector
                    className="stats-modal-number-selector"
                    textFieldClassName="stats-modal-number-selector-textfield"
                    label="Behind by"
                    min={0}
                    max={10000}
                    step={1}
                    decimalPlaces={0}
                    initial={currentStats.playerStats.behindLimit}
                    onValid={(newValue) => onValid("behindLimit", newValue)}
                  />
                  <NumberSelector
                    className="stats-modal-number-selector"
                    textFieldClassName="stats-modal-number-selector-textfield"
                    label="Ahead by"
                    min={0}
                    max={10000}
                    step={1}
                    decimalPlaces={0}
                    initial={currentStats.playerStats.aheadLimit}
                    onValid={(newValue) => onValid("aheadLimit", newValue)}
                  />
                </div>
                {!!comparedStats && (
                  <div className="comparison">
                    <div className="comparison-title-and-steal-button">
                      <span>Compared to {comparedUserName}</span>
                      <StealStatsButton
                        comparedUserName={comparedUserName}
                        disabled={areStatsEqual(
                          currentStats,
                          comparedStats,
                          SECOND_INNINGS_STATS_TO_STEAL,
                          "playerStats"
                        )}
                        tooltipMessage={getToolTipMessage(
                          currentStats,
                          comparedStats,
                          SECOND_INNINGS_STATS_TO_STEAL,
                          comparedUserName,
                          "playerStats"
                        )}
                        onClickHandler={() =>
                          onClickHandler(SECOND_INNINGS_STATS_TO_STEAL)
                        }
                      />
                    </div>
                    <NumberComparison
                      label="Behind by"
                      originalValue={currentStats.playerStats.behindLimit}
                      comparedValue={comparedStats.playerStats.behindLimit}
                    />
                    <NumberComparison
                      label="Ahead by"
                      originalValue={currentStats.playerStats.aheadLimit}
                      comparedValue={comparedStats.playerStats.aheadLimit}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="stats-modal-section">
              <div className="stats-modal-section-head">Powerplay</div>
              <div className="input-and-comparison">
                <div>
                  <NumberSelector
                    className="stats-modal-number-selector"
                    textFieldClassName="stats-modal-number-selector-textfield"
                    label="Push Adjustment"
                    min={-100}
                    max={100}
                    step={1}
                    decimalPlaces={0}
                    initial={currentStats.playerStats.powerplayPushAdjustment}
                    onValid={(newValue) =>
                      onValid("powerplayPushAdjustment", newValue)
                    }
                  />
                  <NumberSelector
                    className="stats-modal-number-selector"
                    textFieldClassName="stats-modal-number-selector-textfield"
                    label="Wicket Bias"
                    min={0}
                    max={10}
                    step={0.01}
                    decimalPlaces={2}
                    initial={currentStats.playerStats.powerplayWicketBias}
                    onValid={(newValue) =>
                      onValid("powerplayWicketBias", newValue)
                    }
                  />
                </div>
                {!!comparedStats && (
                  <div className="comparison">
                    <div className="comparison-title-and-steal-button">
                      <span>Compared to {comparedUserName}</span>
                      <StealStatsButton
                        comparedUserName={comparedUserName}
                        disabled={areStatsEqual(
                          currentStats,
                          comparedStats,
                          POWERPLAY_STATS_TO_STEAL,
                          "playerStats"
                        )}
                        tooltipMessage={getToolTipMessage(
                          currentStats,
                          comparedStats,
                          POWERPLAY_STATS_TO_STEAL,
                          comparedUserName,
                          "playerStats"
                        )}
                        onClickHandler={() =>
                          onClickHandler(POWERPLAY_STATS_TO_STEAL)
                        }
                      />
                    </div>
                    <NumberComparison
                      label="Push Adjustment"
                      originalValue={
                        currentStats.playerStats.powerplayPushAdjustment
                      }
                      comparedValue={
                        comparedStats.playerStats.powerplayPushAdjustment
                      }
                    />
                    <NumberComparison
                      label="Wicket Bias"
                      originalValue={
                        currentStats.playerStats.powerplayWicketBias
                      }
                      comparedValue={
                        comparedStats.playerStats.powerplayWicketBias
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
