import {
  Autocomplete,
  MenuItem,
  Select,
  TextField,
  createFilterOptions,
} from "@mui/material";
import { Component, ReactNode } from "react";
import { UUID } from "../../../types/uuid";
import { propsEqual } from "../../component-utils";

interface Option<Key> {
  object: Key;
  optionText: string;
}

interface Props<Enum, Key extends keyof Enum> {
  enumObject: Enum;
  classes: string;
  label: string;
  value: Key;
  readableValues: Record<Key, string>;
  images: Record<Key, ReactNode>;
  onSelect: (value: Key) => void;
  disabled: boolean;
  canType: boolean;
  canClear: boolean;
  showLabel: boolean;
  unavailable: Key[];
}

interface State<K> {
  options: Option<K>[];
}

export abstract class EnumSelector<
  Enum,
  Key extends keyof Enum
> extends Component<Props<Enum, Key>, State<Key>> {
  private autocompleteKey: string;
  static defaultProps = {
    canType: true,
    canClear: true,
    unavailable: [],
    images: null,
    classes: "auto-select",
    label: "",
    showLabel: true,
  };

  private readonly filter = createFilterOptions();

  public constructor(props) {
    super(props);
    this.state = {
      options: [],
    };
    this.updateAutocompleteKey();
  }

  componentDidMount(): void {
    this.updateOptions();
    this.updateAutocompleteKey();
  }

  componentDidUpdate(prevProps): void {
    if (!propsEqual(prevProps, this.props)) {
      this.updateOptions();
      this.updateAutocompleteKey();
    }
  }

  private updateOptions() {
    const options: Option<Key>[] = [];
    Object.keys(this.props.enumObject).forEach((value) => {
      if (this.props.unavailable.find((e) => e === value) === undefined) {
        const option = {
          object: this.props.enumObject[value],
          optionText: this.props.readableValues[value],
        };
        options.push(option);
      }
    });
    this.setState({ options });
  }

  public render() {
    return (
      <div className={this.props.classes}>
        {!!this.props.label && this.props.showLabel && (
          <span>{this.props.label + ":"}</span>
        )}
        {this.props.canType && (
          <Autocomplete
            key={this.autocompleteKey}
            value={
              !this.props.value
                ? null
                : {
                    object: this.props.value,
                    optionText: this.props.readableValues[this.props.value],
                  }
            }
            options={this.state.options}
            onChange={(event, newValue) => this.handleChange(event, newValue)}
            filterOptions={(options, params) =>
              this.filterOptions(options, params)
            }
            getOptionLabel={(option) =>
              option === null ? "" : option.optionText
            }
            disabled={this.props.disabled}
            disableClearable={!this.props.canClear}
            renderOption={(props: any, option, state) => (
              <div {...props} className="player-select-render -enum-and-image">
                {!!this.props.images && (
                  <div className="small-image">
                    {!!this.props.images && this.props.images[option.object]}
                  </div>
                )}
                {option.optionText}
              </div>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={"Select " + this.props.label}
                variant="outlined"
              />
            )}
          />
        )}
        {!this.props.canType && (
          <Select
            id="enum-select"
            className="select"
            value={this.props.value ? this.props.value.toString() : ""}
            onChange={(event, newValue) =>
              this.handleSelection(event, newValue)
            }
            disabled={this.props.disabled}
            variant="standard"
          >
            {this.state.options.map((option: Option<Key>) => {
              return (
                <MenuItem
                  value={option.object.toString()}
                  key={option.object.toString()}
                >
                  {this.props.readableValues[option.object]}
                </MenuItem>
              );
            })}
          </Select>
        )}
      </div>
    );
  }

  /**
   * AUTOCOMPLETE FUNCTIONS
   */
  private filterOptions(options, params): any[] {
    return this.filter(options, params);
  }

  private handleChange(event, newValue) {
    const value: Key = newValue !== null ? newValue.object : null;
    this.props.onSelect(value);
  }

  private handleSelection(event, newValue) {
    const entity: Option<Key> = this.state.options.find(
      (option) => option.object.toString() === newValue.props.value
    );
    this.props.onSelect(entity === undefined ? null : entity.object);
  }

  private updateAutocompleteKey() {
    this.autocompleteKey = UUID.randomUUID().value;
  }
}
