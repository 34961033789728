import { TableCell, TableSortLabel } from "@mui/material";
import { ReactNode } from "react";
import { Team } from "../../types/entities/team";
import {
  SimulatorScenario,
  scenarioOrders,
} from "../../types/preferences/preferences";
import { SimulationResult } from "../../types/simulator/simulation-result";

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "GBP",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export function cleanForCsv(data: string): string {
  return data.replaceAll(",", "-");
}

export function format(num: number): string {
  return formatToDp(num, 2);
}

export function formatToDp(num: number, decimalPlaces: number): string {
  if (Number.isNaN(num)) {
    return "";
  }
  return (
    num &&
    num.toLocaleString("en-US", {
      maximumFractionDigits: decimalPlaces,
      minimumFractionDigits: decimalPlaces,
    })
  );
}

// Override: the other implementation is used in many places.
// Don't want to break the whole app by modifying that.
export function formatToDpWithDash(num: number, decimalPlaces: number): string {
  if (num == null || Number.isNaN(num)) {
    return "-";
  }

  return num.toLocaleString("en-US", {
    maximumFractionDigits: decimalPlaces,
    minimumFractionDigits: decimalPlaces,
  });
}

export function formatCurrency(num): string {
  if (Number.isNaN(num)) {
    return "";
  }
  return (num || num === 0) && currencyFormatter.format(num);
}

export function sortResults(
  latestResults: Map<string, [SimulatorScenario, SimulationResult]>
): Map<string, [SimulatorScenario, SimulationResult]> {
  return !!latestResults
    ? new Map(
        [...latestResults.entries()].sort(
          (a, b) => scenarioOrders[a[1][0].name] - scenarioOrders[b[1][0].name]
        )
      )
    : new Map();
}

export function sortRowsByColumnNumber(
  rows: ReactNode[],
  column: number,
  direction: string
): ReactNode[] {
  return rows.sort((row1: ReactNode, row2: ReactNode) => {
    let value1Number = 0;
    let value2Number = 0;
    try {
      const value = (row1 as any).props.children[1][column].props
        .children as string;
      value1Number = value === "∞" ? Infinity : Number(value.replace(",", ""));
    } catch (err) {
      value1Number = 0;
    }
    try {
      const value = (row2 as any).props.children[1][column].props
        .children as string;
      value2Number = value === "∞" ? Infinity : Number(value.replace(",", ""));
    } catch (err) {
      value2Number = 0;
    }
    return direction === "asc"
      ? value1Number - value2Number
      : value2Number - value1Number;
  });
}

export function sortInvestorListRowsByColumnNumber(
  rows: ReactNode[],
  column: number,
  direction: string
): ReactNode[] {
  return rows.sort((row1: ReactNode, row2: ReactNode) => {
    const value1 = (row1 as any).props.children[column].props.children[0];
    const value2 = (row2 as any).props.children[column].props.children[0];
    return direction === "asc"
      ? ("" + value1).localeCompare(value2)
      : ("" + value2).localeCompare(value1);
  });
}

export function getHeaders(
  sortedResults: Map<string, [SimulatorScenario, SimulationResult]>,
  truePriceResults: SimulationResult,
  team1: Team,
  team2: Team,
  tableName: string,
  enableSort: boolean,
  sortColumn?: number,
  sortDirection?: "asc" | "desc",
  sortFunction?: (column: number, direction: "asc" | "desc") => void
): ReactNode[] {
  const headers: ReactNode[] = [];

  sortedResults.forEach(([scenario, simulationResult], scenarioId) => {
    let index = -1;
    const entries = [...sortedResults.entries()];
    for (let i = 0; i < entries.length; i++) {
      if (entries[i][0] === scenarioId) {
        index = i;
      }
    }

    headers.push(
      <TableCell
        key={`${tableName}-header-${scenario.name}`}
        id={`simulation-table-cell`}
        align="right"
      >
        {enableSort && (
          <TableSortLabel
            active={sortColumn === index}
            direction={sortColumn === index ? sortDirection : "asc"}
            onClick={() =>
              sortFunction(
                index,
                sortColumn === index && sortDirection === "asc" ? "desc" : "asc"
              )
            }
          >
            {getScenarioName(scenario, truePriceResults, team1, team2)}
          </TableSortLabel>
        )}
        {!enableSort &&
          getScenarioName(scenario, truePriceResults, team1, team2)}
      </TableCell>
    );
  });

  return headers;
}

export function getScenarioName(
  scenario: SimulatorScenario,
  simulationResult: SimulationResult,
  team1: Team,
  team2: Team
): string {
  return !!scenario.ballCreationParameters &&
    scenario.ballCreationParameters.toss !== -1
    ? getTeamName(
        scenario.ballCreationParameters.toss,
        simulationResult,
        team1,
        team2
      ) + " bat first"
    : scenario.name;
}

export function getTeamName(
  teamNumber: number,
  latestResult: SimulationResult,
  team1: Team,
  team2: Team
): string {
  if (!!latestResult && !!team1 && !!team2) {
    let id: string;
    if (teamNumber === 1) {
      id = latestResult.team1;
    } else {
      id = latestResult.team2;
    }

    return getTeamNameForId(id, team1, team2);
  } else {
    return "Team " + teamNumber;
  }
}

export function getTeamNameForId(
  teamId: string,
  team1: Team,
  team2: Team
): string {
  if (team1.teamId.value === teamId) {
    return team1.name;
  } else {
    return team2.name;
  }
}

export const recordedBallOutcomeNames: string[] = [
  "Dot",
  "One",
  "Two",
  "Three",
  "Four",
  "Six",
  "Wicket",
];
