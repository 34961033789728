import {
  GroundFormData,
  GroundFormModuleType,
  deserializeGroundFormModules,
} from "../form/ground-form-modules";
import {
  PlayerFormData,
  PlayerFormModuleType,
  deserializePlayerFormModules,
} from "../form/player-form-modules";

import {
  BowlWithExpectation,
  deserializeBowlWithExpectation,
} from "./bowl-with-expectation";

export interface FormExplanation<T, U> {
  balls: Map<T, BowlWithExpectation[]>;
  formModules: Map<T, U>;
}

export type GroundFormExplanation = FormExplanation<
  GroundFormModuleType,
  GroundFormData
>;
export type PlayerFormExplanation = FormExplanation<
  PlayerFormModuleType,
  PlayerFormData
>;

export function deserializeGroundFormExplanation(
  json: any
): GroundFormExplanation {
  return {
    balls: deserializeGroundFormBowlWithExpectationMap(json.balls),
    formModules: deserializeGroundFormModules(json.formModules),
  };
}

export function deserializePlayerFormExplanation(
  json: any
): PlayerFormExplanation {
  return {
    balls: deserializePlayerFormBowlWithExpectationMap(json.balls),
    formModules: deserializePlayerFormModules(json.formModules),
  };
}

function deserializeGroundFormBowlWithExpectationMap(
  json: any
): Map<GroundFormModuleType, BowlWithExpectation[]> {
  const map: Map<GroundFormModuleType, BowlWithExpectation[]> = new Map();

  Object.keys(json).forEach((moduleTypeName) => {
    map.set(GroundFormModuleType[moduleTypeName], []);
    json[moduleTypeName].forEach((bowlJson: any) => {
      map
        .get(GroundFormModuleType[moduleTypeName])
        .push(deserializeBowlWithExpectation(bowlJson));
    });
  });

  return map;
}

function deserializePlayerFormBowlWithExpectationMap(
  json: any
): Map<PlayerFormModuleType, BowlWithExpectation[]> {
  const map: Map<PlayerFormModuleType, BowlWithExpectation[]> = new Map();

  Object.keys(json).forEach((moduleTypeName) => {
    map.set(PlayerFormModuleType[moduleTypeName], []);
    json[moduleTypeName].forEach((bowlJson: any) => {
      map
        .get(PlayerFormModuleType[moduleTypeName])
        .push(deserializeBowlWithExpectation(bowlJson));
    });
  });

  return map;
}
