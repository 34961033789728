import { Player } from "../../types/entities/player";
import { Squad } from "../../types/entities/squad";
import {
  PlayerSimulationQuestionStatistic,
  playerSimulationQuestionStatisticNames,
} from "../../types/enums/question-statistic";
import {
  SimulatorOddsQuestionOperator,
  operatorNames,
} from "../../types/enums/questions-operator";
import { PlayerSimulationOddsQuestion } from "../../types/simulator/questions/simulation-odds-question";
import { observableFrom } from "../component-utils";
import { EnumSelector } from "../entity-management/entity-selectors/enum-selector";
import { NumberSelector } from "../entity-management/entity-selectors/number-selector";
import { PlayerSelector } from "../entity-management/entity-selectors/player-selector";

interface Props {
  squad1: Squad;
  squad2: Squad;
  onChange: (question: PlayerSimulationOddsQuestion) => void;
  question: PlayerSimulationOddsQuestion;
}

export interface State {
  question: PlayerSimulationOddsQuestion;
}

export default function PlayerSimulatorOddsQuestionEditor({
  squad1,
  squad2,
  onChange,
  question,
}: Props): React.JSX.Element {
  const getAllPlayers: () => Player[] = () => {
    return squad1.players.concat(squad2.players);
  };

  const player: Player = Squad.findPlayer(squad1, squad2, question.playerId);

  return (
    <div>
      <div
        className="stored-question-editor"
        style={{
          gridTemplateColumns: "1fr 1fr 1fr 1fr",
        }}
      >
        <PlayerSelector
          value={player}
          onSelect={(player) =>
            onChange({
              ...question,
              playerId: player ? player.playerId.value : null,
            })
          }
          options={observableFrom(getAllPlayers())}
          canAddNew={false}
          canClear={false}
          classes="dialog-content"
          title="Player:"
        />
        <EnumSelector
          classes="dialog-content"
          label="Statistic"
          enumObject={PlayerSimulationQuestionStatistic}
          value={question.questionStatistic}
          readableValues={playerSimulationQuestionStatisticNames}
          onSelect={(value) =>
            onChange({ ...question, questionStatistic: value })
          }
          disabled={false}
          canClear={false}
        />
        <div>
          <EnumSelector
            classes="dialog-content"
            enumObject={SimulatorOddsQuestionOperator}
            label="Operator"
            value={question.operator}
            readableValues={operatorNames}
            onSelect={(value) => onChange({ ...question, operator: value })}
            disabled={false}
            canClear={false}
          />
        </div>
        <div>
          <NumberSelector
            label={"Value"}
            className="dialog-content"
            textFieldClassName="tall-number-selector"
            min={0}
            max={10000}
            step={1}
            decimalPlaces={0}
            initial={question.operand}
            onValid={(valid: number) =>
              onChange({ ...question, operand: valid })
            }
            disabled={false}
          />
        </div>
      </div>
    </div>
  );
}
