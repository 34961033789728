import { Component } from "react";

import { UserPreferences } from "../../types/preferences/preferences";
import {
  GroundStats,
  GroundStatsWrapper,
} from "../../types/stats/ground-stats";
import { NumberSelector } from "../entity-management/entity-selectors/number-selector";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import { GlobalPercentsDistributionModal } from "../stats-editing-components/global-percents-distribution-modal";
import { NumberComparison } from "../stats-editing-components/number-comparison";
import {
  areStatsEqual,
  getToolTipMessage,
} from "../stats-editing-components/stats-editing-utils";
import StealStatsButton from "../stats-editing-components/steal-stats-button";

import { batchUpdate } from "./ground-page-utils";

interface Props {
  currentStats: GroundStatsWrapper;
  comparedStats: GroundStatsWrapper;
  comparedUserName: string;
  title: string;
  strikeRateProperty: string;
  wicketPercentProperty: string;
  confidenceProperty: string;
  percentDistributionBiasProperty: string;
  percentDistributionBiasPropertyName: string;
  userPreferences: UserPreferences;
  onUpdate: (updatedStats: GroundStatsWrapper) => void;
}

interface State {
  strikeRateBiasValid: boolean;
  wicketBiasValid: boolean;
  confidenceValid: boolean;
  percentDistributionModalOpen: boolean;
}

export class GroundStrikeRateAndWicketPercentStats extends Component<
  Props,
  State
> {
  public STATS_TO_STEAL = [
    this.props.strikeRateProperty,
    this.props.wicketPercentProperty,
    this.props.confidenceProperty,
    this.props.percentDistributionBiasProperty,
  ];

  private static readonly DEFAULT_STATE = {
    strikeRateBiasValid: true,
    wicketBiasValid: true,
    confidenceValid: true,
    percentDistributionModalOpen: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...GroundStrikeRateAndWicketPercentStats.DEFAULT_STATE,
    };
  }

  private onClickHandler = () => {
    batchUpdate(
      this.props.currentStats,
      this.props.comparedStats,
      this.STATS_TO_STEAL,
      this.props.onUpdate
    );
  };

  private callUpdateFunction(propertyName: string, newValue: any) {
    this.props.onUpdate(
      new GroundStatsWrapper(
        this.props.currentStats.groundStatsId,
        this.props.currentStats.createdBy,
        this.props.currentStats.createdAt,
        this.props.currentStats.name,
        {
          ...this.props.currentStats.groundStats,
          [propertyName]: newValue,
        },
        this.props.currentStats.matchType
      )
    );
  }

  private showPercentsDistributionModal(open: boolean) {
    this.setState({ percentDistributionModalOpen: open });
  }

  public render() {
    return (
      <div className="stats-modal-section">
        <div className="stats-modal-section-head">{this.props.title}</div>
        <div className="ground-stats-modal-number-selectors">
          <NumberSelector
            className="stats-modal-number-selector"
            label="Strike Rate"
            min={0}
            max={1000000}
            step={0.01}
            decimalPlaces={2}
            initial={
              this.props.currentStats.groundStats[this.props.strikeRateProperty]
            }
            onValid={(newValue) =>
              this.setState(
                {
                  strikeRateBiasValid: true,
                },
                () =>
                  this.callUpdateFunction(
                    this.props.strikeRateProperty,
                    newValue
                  )
              )
            }
            onInvalid={(newValue) =>
              this.setState(
                {
                  strikeRateBiasValid: false,
                },
                () =>
                  this.callUpdateFunction(
                    this.props.strikeRateProperty,
                    newValue
                  )
              )
            }
            button={
              <TooltipIconButton
                title={"Percent Distribution"}
                disabled={false}
                onClick={() => this.showPercentsDistributionModal(true)}
                icon={"bar_chart"}
              />
            }
          />
          <NumberSelector
            className="stats-modal-number-selector"
            label="Wicket Percent"
            min={0}
            max={1000000}
            step={0.01}
            decimalPlaces={2}
            initial={
              this.props.currentStats.groundStats[
                this.props.wicketPercentProperty
              ]
            }
            onValid={(newValue) =>
              this.setState(
                {
                  wicketBiasValid: true,
                },
                () =>
                  this.callUpdateFunction(
                    this.props.wicketPercentProperty,
                    newValue
                  )
              )
            }
            onInvalid={(newValue) =>
              this.setState(
                {
                  wicketBiasValid: false,
                },
                () =>
                  this.callUpdateFunction(
                    this.props.wicketPercentProperty,
                    newValue
                  )
              )
            }
          />
          <NumberSelector
            className="stats-modal-number-selector"
            label="Confidence"
            min={0}
            max={1000000}
            step={1}
            initial={
              this.props.currentStats.groundStats[this.props.confidenceProperty]
            }
            onValid={(newValue) =>
              this.setState(
                {
                  confidenceValid: true,
                },
                () =>
                  this.callUpdateFunction(
                    this.props.confidenceProperty,
                    newValue
                  )
              )
            }
            onInvalid={(newValue) =>
              this.setState(
                {
                  confidenceValid: false,
                },
                () =>
                  this.callUpdateFunction(
                    this.props.confidenceProperty,
                    newValue
                  )
              )
            }
          />

          {!!this.props.comparedStats && (
            <div className="comparison">
              <div className="comparison-title-and-steal-button">
                <span>Compared to {this.props.comparedUserName}</span>
                <StealStatsButton
                  comparedUserName={this.props.comparedUserName}
                  disabled={areStatsEqual(
                    this.props.currentStats,
                    this.props.comparedStats,
                    this.STATS_TO_STEAL,
                    "groundStats"
                  )}
                  tooltipMessage={getToolTipMessage(
                    this.props.currentStats,
                    this.props.comparedStats,
                    this.STATS_TO_STEAL,
                    this.props.comparedUserName,
                    "groundStats"
                  )}
                  onClickHandler={this.onClickHandler}
                />
              </div>
              <NumberComparison
                label="Strike Rate"
                originalValue={
                  this.props.currentStats.groundStats[
                    this.props.strikeRateProperty
                  ]
                }
                comparedValue={
                  this.props.comparedStats.groundStats[
                    this.props.strikeRateProperty
                  ]
                }
              />
              <NumberComparison
                label="Wicket Percent"
                originalValue={
                  this.props.currentStats.groundStats[
                    this.props.wicketPercentProperty
                  ]
                }
                comparedValue={
                  this.props.comparedStats.groundStats[
                    this.props.wicketPercentProperty
                  ]
                }
              />
              <NumberComparison
                label="Confidence"
                originalValue={
                  this.props.currentStats.groundStats[
                    this.props.confidenceProperty
                  ]
                }
                comparedValue={
                  this.props.comparedStats.groundStats[
                    this.props.confidenceProperty
                  ]
                }
              />
            </div>
          )}
          <GlobalPercentsDistributionModal
            open={this.state.percentDistributionModalOpen}
            onProceed={(stats: GroundStats) => {
              this.showPercentsDistributionModal(false);
              this.props.onUpdate(
                new GroundStatsWrapper(
                  this.props.currentStats.groundStatsId,
                  this.props.currentStats.createdBy,
                  this.props.currentStats.createdAt,
                  this.props.currentStats.name,
                  stats,
                  this.props.currentStats.matchType
                )
              );
            }}
            onCancel={() => this.showPercentsDistributionModal(false)}
            initialStats={this.props.currentStats.groundStats}
            percentDistributionBiasProperty={
              this.props.percentDistributionBiasProperty
            }
            percentDistributionBiasPropertyName={
              this.props.percentDistributionBiasPropertyName
            }
            percentDistributionConfidenceProperty={
              this.props.confidenceProperty
            }
            userPreferences={this.props.userPreferences}
            comparedStats={
              this.props.comparedStats && this.props.comparedStats.groundStats
            }
            comparedUserName={this.props.comparedUserName}
          />
        </div>
      </div>
    );
  }
}
