import { UUID } from "../uuid";

export class BetfairMessage {
  public betfairStreamMessageId: UUID;
  public eventId: string;
  public createdAt: number;
  public payload: string;

  public static deserialize(msg: any): BetfairMessage {
    return {
      betfairStreamMessageId:
        msg.betfairStreamMessageId &&
        UUID.fromString(msg.betfairStreamMessageId),
      eventId: msg.eventId,
      createdAt: msg.createdAt,
      payload: msg.payload,
    };
  }
}

export enum MarketType {
  MATCH_ODDS = "MATCH_ODDS",
  INNINGS_RUNS_1 = "INNINGS_RUNS_1",
  INNINGS_RUNS_2 = "INNINGS_RUNS_2",
  POWERPLAY_RUNS_1 = "POWERPLAY_RUNS_1",
  POWERPLAY_RUNS_2 = "POWERPLAY_RUNS_2",
}

export const marketTypeReadableNames: Record<MarketType, string> = {
  MATCH_ODDS: "Match Odds",
  INNINGS_RUNS_1: "1st Innings Runs",
  INNINGS_RUNS_2: "2nd Innings Runs",
  POWERPLAY_RUNS_1: "1st Innings Powerplay Runs",
  POWERPLAY_RUNS_2: "2nd Innings Powerplay Runs",
};

export class BetfairPrice {
  backPrice: number;
  layPrice: number;
  backSize: number;
  laySize: number;
  midPoint: number;
}

export class MarketSubscription {
  latestPrices: Map<MarketType, Map<string, BetfairPrice>>;
}

export class BetfairPriceUpdateMessage {
  public eventId: string;
  public subscription: MarketSubscription;

  public static deserialize(msg: any): BetfairPriceUpdateMessage {
    return {
      eventId: msg.eventId,
      subscription: BetfairPriceUpdateMessage.deserializeMarketSubscription(
        msg.subscription
      ),
    };
  }

  private static deserializeMarketSubscription(msg: any): MarketSubscription {
    return {
      latestPrices: BetfairPriceUpdateMessage.deserializeLatestPrices(
        msg.latestPrices
      ),
    };
  }

  private static deserializeLatestPrices(
    msg: any
  ): Map<MarketType, Map<string, BetfairPrice>> {
    const map: Map<MarketType, Map<string, BetfairPrice>> = new Map();
    for (const [key, value] of Object.entries(msg)) {
      map.set(
        MarketType[key],
        BetfairPriceUpdateMessage.deserializePriceMap(value)
      );
    }
    return map;
  }

  private static deserializePriceMap(msg: any): Map<string, BetfairPrice> {
    const map: Map<string, BetfairPrice> = new Map();
    for (const [key, value] of Object.entries(msg)) {
      map.set(key, value as BetfairPrice);
    }
    return map;
  }
}
