import { Component } from "react";
import { GameState } from "../../../types/entities/game-state";
import { Player } from "../../../types/entities/player";
import { services } from "../../../types/services";
import { Squad } from "../../../types/entities/squad";
import { UUID } from "../../../types/uuid";
import { TeamPlayerSelector } from "../../entity-management/entity-selectors/team-player-selector";
import { PlayerView } from "../../my-matches/match-creation-modals/player-view";
import { StatisticType } from "../../../types/enums/statistic-type";
import { MatchInfo } from "../../../types/entities/match-info";
import { getTitleColour } from "../game-state-display/tsbc";
import { RetireModal } from "./retire-modal";
import { MatchRole } from "../../../types/enums/match-role";
import { MatchFormat } from "../../../types/entities/match-format";

interface Props {
  batsmanNumber: number;
  gameState: GameState;
  matchInfo: MatchInfo;
  matchFormat: MatchFormat;
  squad: Squad;
  selectedBatsman: UUID;
  otherBatsman: UUID;
  primaryColour: string;
  secondaryColour: string;
  onModalOpen: () => void;
  onModalClose: () => void;
  focussed: boolean;
}

interface State {
  retireModalOpen: boolean;
}

export class BatsmanSelector extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      retireModalOpen: false,
    };
  }

  private selectBatsman(player: Player) {
    services.currentGameService.selectBatsman(
      this.props.batsmanNumber,
      player === null ? null : player.playerId
    );
  }

  private getOutBatsmen(): UUID[] {
    return this.props.squad && this.props.squad.players
      ? this.props.squad.players
          .map((player) => player.playerId)
          .filter(
            (playerId) =>
              !this.props.gameState.batsmanCanBatThisInnings(playerId)
          )
      : [];
  }

  private isRetired(player: Player) {
    const percentageRetired = this.props.gameState.getPlayerIntegerStat(
      StatisticType.BATSMAN_RETIRED,
      player.playerId,
      null
    );
    return !(percentageRetired === null || Number.isNaN(percentageRetired));
  }

  private getRetiredBatsmen(): UUID[] {
    return this.props.squad.players
      .filter((player) => this.isRetired(player))
      .map((player) => player.playerId);
  }

  private getSubstitutes(): UUID[] {
    if (
      !this.props.gameState ||
      !this.props.matchFormat ||
      this.props.gameState.matchFormatId.value !==
        this.props.matchFormat.matchFormatId.value
    ) {
      return [];
    }

    return this.props.gameState.squads[
      this.props.gameState.getBattingTeam(this.props.matchFormat) - 1
    ]
      .filter(
        (teamPlayer) =>
          teamPlayer.matchRole === MatchRole.SUBSTITUTE ||
          teamPlayer.matchRole === MatchRole.REPLACED
      )
      .map((teamPlayer) => teamPlayer.playerId);
  }

  private onRetire(percentToReturn: number, overToReturn: number) {
    services.currentGameService.retireBatsman(
      this.props.batsmanNumber,
      percentToReturn,
      overToReturn
    );
    this.setState({ retireModalOpen: false });
    this.props.onModalClose();
  }

  public render() {
    if (!this.props.gameState || !this.props.squad) return <div></div>;

    const batsmanId: UUID =
      this.props.batsmanNumber === 1
        ? this.props.gameState.batsman1
        : this.props.gameState.batsman2;
    const score: number = this.props.gameState.getPlayerIntegerStat(
      StatisticType.BATSMAN_RUNS_SCORED,
      batsmanId
    );
    const faced: number = this.props.gameState.getPlayerIntegerStat(
      StatisticType.BATSMAN_BALLS_ON_STRIKE,
      batsmanId
    );
    const fours: number = this.props.gameState.getPlayerIntegerStat(
      StatisticType.BATSMAN_FOURS,
      batsmanId
    );
    const sixes: number = this.props.gameState.getPlayerIntegerStat(
      StatisticType.BATSMAN_SIXES,
      batsmanId
    );
    const dots: number = this.props.gameState.getPlayerIntegerStat(
      StatisticType.BATSMAN_DOTS,
      batsmanId
    );
    const strikeRate: string =
      faced === 0
        ? ""
        : ((score / faced) * 100).toLocaleString("en-US", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2,
          });
    const player: Player =
      this.props.selectedBatsman &&
      this.props.squad.players.find(
        (player) => player.playerId.value === this.props.selectedBatsman.value
      );
    const name = player && player.longName;
    const tableId: string =
      "batsman-selector-table-" + this.props.batsmanNumber;
    const primaryColour: string = this.props.primaryColour || "#888888";
    const secondaryColour: string = this.props.secondaryColour || "#777777";
    const textColour: string = getTitleColour(primaryColour);
    const textColourHeader: string = getTitleColour(secondaryColour);
    const label: string =
      this.props.batsmanNumber === 1
        ? "On Strike Batsman"
        : "Off Strike Batsman";
    const strike: string =
      this.props.batsmanNumber === 1 ? "on-strike-" : "off-strike-";
    const imageHeight: number = this.props.batsmanNumber === 1 ? 125 : 80;
    const imageWidth: number = this.props.batsmanNumber === 1 ? 100 : 64;
    const disabled: boolean =
      this.props.batsmanNumber === 1
        ? this.props.gameState.batsman1 !== null
        : this.props.gameState.batsman2 !== null;

    return (
      <div>
        {this.props.selectedBatsman === null && (
          <TeamPlayerSelector
            selectedPlayer={this.props.selectedBatsman}
            squad={this.props.squad}
            notAvailable={[
              this.props.otherBatsman,
              ...this.getOutBatsmen(),
              ...this.getSubstitutes(),
            ].filter((object) => object !== null)}
            retired={this.getRetiredBatsmen()}
            label={label}
            onSelect={(player: Player) => this.selectBatsman(player)}
            disabled={disabled}
            focussed={this.props.focussed}
          />
        )}
        {this.props.selectedBatsman !== null && (
          <div className={strike + "image-and-scores"}>
            <PlayerView
              imgClasses={"scorecard-image"}
              player={
                this.props.squad &&
                this.props.squad.players &&
                this.props.squad.players.find(
                  (player) =>
                    player.playerId.value === this.props.selectedBatsman.value
                )
              }
              canEdit={true}
              removeText={"Retire Batsman"}
              onRemove={() => {
                this.setState({ retireModalOpen: true });
                this.props.onModalOpen();
              }}
              showName={false}
              imgHeight={imageHeight}
              imgWidth={imageWidth}
              width={imageWidth + "px"}
            />
            <div className="table-container">
              <table id={tableId} style={{ backgroundColor: primaryColour }}>
                <thead id={tableId}>
                  <tr
                    className="table-header"
                    id={tableId}
                    style={{
                      backgroundColor: secondaryColour,
                      color: textColourHeader,
                    }}
                  >
                    <td
                      className="table-header"
                      id={tableId}
                      align="right"
                    ></td>
                    <td className="table-header" id={tableId} align="right">
                      Runs
                    </td>
                    <td className="table-header" id={tableId} align="right">
                      Balls
                    </td>
                    <td className="table-header" id={tableId} align="right">
                      Fours
                    </td>
                    <td className="table-header" id={tableId} align="right">
                      Sixes
                    </td>
                    <td className="table-header" id={tableId} align="right">
                      Dots
                    </td>
                    <td className="table-header" id={tableId} align="right">
                      Strike Rate
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr id={tableId} style={{ color: textColour }}>
                    <td id={tableId} className={"bat-table-cell"}>
                      {name}
                    </td>
                    <td id={tableId} className={"bat-table-cell"} align="right">
                      {score}
                    </td>
                    <td id={tableId} className={"bat-table-cell"} align="right">
                      ({faced})
                    </td>
                    <td id={tableId} className={"bat-table-cell"} align="right">
                      {fours}
                    </td>
                    <td id={tableId} className={"bat-table-cell"} align="right">
                      {sixes}
                    </td>
                    <td id={tableId} className={"bat-table-cell"} align="right">
                      {dots}
                    </td>
                    <td id={tableId} className={"bat-table-cell"} align="right">
                      {strikeRate}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
        {this.state.retireModalOpen && (
          <RetireModal
            open={this.state.retireModalOpen}
            gameState={this.props.gameState}
            matchFormat={this.props.matchFormat}
            onCancel={() => {
              this.setState({ retireModalOpen: false });
              this.props.onModalClose();
            }}
            onProceed={(percentReturn: number, overToReturn: number) =>
              this.onRetire(percentReturn, overToReturn)
            }
            player={
              this.props.selectedBatsman &&
              this.props.squad.players.find(
                (player) =>
                  player.playerId.value === this.props.selectedBatsman.value
              )
            }
          />
        )}
      </div>
    );
  }
}
