import { TextField } from "@mui/material";
import { Component } from "react";
import { NodeConfiguration } from "../../types/preferences/node-configuration";
import { propsEqual } from "../component-utils";
import { NumberSelector } from "../entity-management/entity-selectors/number-selector";
import { CreationDialog } from "../my-matches/match-creation-modals/creation-dialog";

interface Props {
  initialConfiguration: NodeConfiguration;
  open: boolean;
  onCancel: () => void;
  onProceed: (configuration: NodeConfiguration) => void;
}

interface State {
  configuration: NodeConfiguration;
}

export class NodeConfigurationModal extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      configuration: null,
    };
  }

  componentDidMount(): void {
    this.updateConfiguration();
  }

  componentDidUpdate(prevProps): void {
    if (!propsEqual(prevProps, this.props)) {
      this.updateConfiguration();
    }
  }

  private handlePropertyChange(name, value) {
    this.setState({
      configuration: {
        ...this.state.configuration,
        [name]: value,
      },
    });
  }

  private updateConfiguration() {
    this.setState({ configuration: this.props.initialConfiguration });
  }

  private invalid(): boolean {
    return this.state.configuration === null;
  }

  private disabled(): boolean {
    return (
      this.props.initialConfiguration &&
      this.props.initialConfiguration.createdBy === null &&
      this.props.initialConfiguration.nodeConfigurationId !== null
    );
  }

  public render() {
    return (
      <CreationDialog
        open={this.props.open}
        label={"Node Configuration"}
        invalid={this.invalid()}
        disabled={this.disabled()}
        onCancel={() => this.props.onCancel()}
        onProceed={() => this.props.onProceed(this.state.configuration)}
        proceedText="OK"
        colour="#f04343"
        disabledText="Cannot edit default node"
      >
        <div className="dialog-content">
          <span>Name:</span>
          <TextField
            value={
              this.state.configuration === null
                ? ""
                : this.state.configuration.name
            }
            onChange={(event) =>
              this.handlePropertyChange("name", event.target.value)
            }
            placeholder={"Name"}
            variant="outlined"
            disabled={this.disabled()}
          />
        </div>

        <div className="dialog-content">
          <span>Host:</span>
          <TextField
            value={
              this.state.configuration === null
                ? ""
                : this.state.configuration.host
            }
            onChange={(event) =>
              this.handlePropertyChange("host", event.target.value)
            }
            placeholder={"Name"}
            variant="outlined"
            disabled={this.disabled()}
          />
        </div>

        <NumberSelector
          className="stats-modal-number-selector"
          label="Port:"
          min={1}
          max={100000}
          initial={
            this.state.configuration === null
              ? 1
              : this.state.configuration.port
          }
          onValid={(newValue) => {
            this.handlePropertyChange("port", newValue);
          }}
          disabled={this.disabled()}
        />

        <NumberSelector
          className="stats-modal-number-selector"
          label="Maximum Cores:"
          min={1}
          max={128}
          initial={
            this.state.configuration === null
              ? 1
              : this.state.configuration.maxCores
          }
          onValid={(newValue) => {
            this.handlePropertyChange("maxCores", newValue);
          }}
          disabled={this.disabled()}
        />
      </CreationDialog>
    );
  }
}
