import { Component } from "react";

import { GroundStatsWrapper } from "../../types/stats/ground-stats";
import { propsEqual } from "../component-utils";
import { NumberSelector } from "../entity-management/entity-selectors/number-selector";
import { NumberComparison } from "../stats-editing-components/number-comparison";
import {
  areStatsEqual,
  getToolTipMessage,
} from "../stats-editing-components/stats-editing-utils";
import StealStatsButton from "../stats-editing-components/steal-stats-button";

import { batchUpdate } from "./ground-page-utils";

interface Props {
  currentStats: GroundStatsWrapper;
  comparedStats: GroundStatsWrapper;
  comparedUserName: string;
  onUpdate: (updatedStats: GroundStatsWrapper) => void;
}

interface State {
  pushValid: boolean;
  spinPushIncreaseValid: boolean;
  pacePushIncreaseValid: boolean;
}

export class GroundPushStats extends Component<Props, State> {
  public STATS_TO_STEAL = [
    "pushIncrease",
    "pacePushIncrease",
    "spinPushIncrease",
  ];

  private static readonly DEFAULT_STATE = {
    pushValid: true,
    spinPushIncreaseValid: true,
    pacePushIncreaseValid: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...GroundPushStats.DEFAULT_STATE,
    };
  }

  componentDidMount(): void {
    this.updateState();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (!propsEqual(prevProps, this.props)) {
      this.updateState();
    }
  }

  private onClickHandler = () => {
    batchUpdate(
      this.props.currentStats,
      this.props.comparedStats,
      this.STATS_TO_STEAL,
      this.props.onUpdate
    );
  };

  private updateState() {
    this.setState({
      ...GroundPushStats.DEFAULT_STATE,
    });
  }

  private callUpdateFunction(propertyName: string, newValue: any) {
    this.props.onUpdate(
      new GroundStatsWrapper(
        this.props.currentStats.groundStatsId,
        this.props.currentStats.createdBy,
        this.props.currentStats.createdAt,
        this.props.currentStats.name,
        {
          ...this.props.currentStats.groundStats,
          [propertyName]: newValue,
        },
        this.props.currentStats.matchType
      )
    );
  }

  public render() {
    return (
      <div className="stats-modal-section">
        <div className="stats-modal-section-head">Push Adjustment</div>
        <div className="ground-stats-modal-number-selectors">
          <NumberSelector
            className="stats-modal-number-selector"
            label="Global"
            min={-100}
            max={100}
            step={1}
            initial={this.props.currentStats.groundStats.pushIncrease}
            onValid={(newValue) =>
              this.setState(
                {
                  pushValid: true,
                },
                () => this.callUpdateFunction("pushIncrease", newValue)
              )
            }
            onInvalid={(newValue) =>
              this.setState(
                {
                  pushValid: false,
                },
                () => this.callUpdateFunction("pushIncrease", newValue)
              )
            }
          />
          <NumberSelector
            className="stats-modal-number-selector"
            label="Against Pace"
            min={-100}
            max={100}
            step={1}
            initial={this.props.currentStats.groundStats.pacePushIncrease}
            onValid={(newValue) =>
              this.setState(
                {
                  pacePushIncreaseValid: true,
                },
                () => this.callUpdateFunction("pacePushIncrease", newValue)
              )
            }
            onInvalid={(newValue) =>
              this.setState(
                {
                  pacePushIncreaseValid: false,
                },
                () => this.callUpdateFunction("pacePushIncrease", newValue)
              )
            }
          />
          <NumberSelector
            className="stats-modal-number-selector"
            label="Against Spin"
            min={-100}
            max={100}
            step={1}
            initial={this.props.currentStats.groundStats.spinPushIncrease}
            onValid={(newValue) =>
              this.setState(
                {
                  spinPushIncreaseValid: true,
                },
                () => this.callUpdateFunction("spinPushIncrease", newValue)
              )
            }
            onInvalid={(newValue) =>
              this.setState(
                {
                  spinPushIncreaseValid: false,
                },
                () => this.callUpdateFunction("spinPushIncrease", newValue)
              )
            }
          />
          {!!this.props.comparedStats && (
            <div className="comparison">
              <div className="comparison-title-and-steal-button">
                <span>Compared to {this.props.comparedUserName}</span>
                <StealStatsButton
                  comparedUserName={this.props.comparedUserName}
                  disabled={areStatsEqual(
                    this.props.currentStats,
                    this.props.comparedStats,
                    this.STATS_TO_STEAL,
                    "groundStats"
                  )}
                  tooltipMessage={getToolTipMessage(
                    this.props.currentStats,
                    this.props.comparedStats,
                    this.STATS_TO_STEAL,
                    this.props.comparedUserName,
                    "groundStats"
                  )}
                  onClickHandler={this.onClickHandler}
                />
              </div>
              <NumberComparison
                label="Global"
                originalValue={this.props.currentStats.groundStats.pushIncrease}
                comparedValue={
                  this.props.comparedStats.groundStats.pushIncrease
                }
              />
              <NumberComparison
                label="Against Pace"
                originalValue={
                  this.props.currentStats.groundStats.pacePushIncrease
                }
                comparedValue={
                  this.props.comparedStats.groundStats.pacePushIncrease
                }
              />
              <NumberComparison
                label="Against Spin"
                originalValue={
                  this.props.currentStats.groundStats.spinPushIncrease
                }
                comparedValue={
                  this.props.comparedStats.groundStats.spinPushIncrease
                }
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
