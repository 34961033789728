import { Component, ReactElement } from "react";
import {
  FormPreferences,
  MatchTypeConversionPreferences,
  UserPreferences,
} from "../../types/preferences/preferences";
import { MatchType, matchTypeNames } from "../../types/enums/match-type";
import { MenuItem, Select } from "@mui/material";
import { NumberSelector } from "../entity-management/entity-selectors/number-selector";

interface Props {
  userPreferences: UserPreferences;
  onUpdate: (formPreferences: FormPreferences) => void;
}

interface State {
  selectedSource: MatchType;
  selectedDestination: MatchType;
}

export class MatchTypeConversionPreferencesComponent extends Component<
  Props,
  State
> {
  public constructor(props) {
    super(props);
    this.state = {
      selectedSource: MatchType.T20_AND_HUNDRED,
      selectedDestination: MatchType.ODI,
    };
  }

  private handleChangeConversionWeight(index: number, value: number) {
    this.props.userPreferences.formPreferences.matchTypeConversionPreferences[
      index
    ].useMatchTypeConversion = value > 0;
    this.props.userPreferences.formPreferences.matchTypeConversionPreferences[
      index
    ].matchTypeConversionWeight = value;
    this.props.onUpdate(this.props.userPreferences.formPreferences);
  }

  private handleChangeConversionPowerplayWeight(index: number, value: number) {
    this.props.userPreferences.formPreferences.matchTypeConversionPreferences[
      index
    ].matchTypeConversionPowerplayWeight = value;
    this.props.onUpdate(this.props.userPreferences.formPreferences);
  }

  private buildConversionComponentList(): ReactElement {
    const matchTypeConversionPreferences: MatchTypeConversionPreferences[] =
      this.props.userPreferences.formPreferences.matchTypeConversionPreferences;

    for (let i = 0; i < matchTypeConversionPreferences.length; i++) {
      const element: MatchTypeConversionPreferences =
        matchTypeConversionPreferences[i];
      if (
        element.source === this.state.selectedSource &&
        element.destination === this.state.selectedDestination
      ) {
        return (
          <div key={`match-type-conversion-${i}`}>
            <div>
              <NumberSelector
                label="Weight:"
                min={0}
                max={1}
                decimalPlaces={2}
                step={0.01}
                initial={element.matchTypeConversionWeight}
                onValid={(newValue) => {
                  this.handleChangeConversionWeight(i, newValue);
                }}
                className="stats-modal-number-selector"
              />
              <NumberSelector
                label="Powerplay Weight:"
                min={0}
                max={1}
                decimalPlaces={2}
                step={0.01}
                initial={element.matchTypeConversionPowerplayWeight}
                onValid={(newValue) => {
                  this.handleChangeConversionPowerplayWeight(i, newValue);
                }}
                className="stats-modal-number-selector"
              />
            </div>
          </div>
        );
      }
    }

    return <div></div>;
  }

  public render() {
    return (
      <div className="match-type-conversions-settings-container">
        {!!this.props.userPreferences && (
          <div>
            <hr />
            <div className="form-settings-subheading">
              Match Type Conversions
            </div>
            <div className="match-type-skew-settings">
              <div className="match-type-skew-selectors">
                <div className="match-type-skew-selector">
                  From:
                  <Select
                    value={this.state.selectedSource}
                    renderValue={(val: MatchType) => matchTypeNames[val]}
                    className="enum-multi-select"
                    onChange={(selection) =>
                      this.setState({
                        selectedSource: selection.target.value as MatchType,
                      })
                    }
                    disabled={false}
                    variant="standard"
                  >
                    {Object.keys(MatchType).map(
                      (matchTypeValue: string, index: number) => (
                        <MenuItem
                          key={`strike-rate-or-wicket-percent-${index}`}
                          value={MatchType[matchTypeValue]}
                        >
                          {matchTypeNames[matchTypeValue]}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </div>
                <div className="match-type-skew-selector">
                  To:
                  <Select
                    value={this.state.selectedDestination}
                    renderValue={(val: MatchType) => matchTypeNames[val]}
                    className="enum-multi-select"
                    onChange={(selection) =>
                      this.setState({
                        selectedDestination: selection.target
                          .value as MatchType,
                      })
                    }
                    disabled={false}
                    variant="standard"
                  >
                    {Object.keys(MatchType).map(
                      (matchTypeValue: string, index: number) => (
                        <MenuItem
                          key={`strike-rate-or-wicket-percent-${index}`}
                          value={MatchType[matchTypeValue]}
                        >
                          {matchTypeNames[matchTypeValue]}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </div>
              </div>
              <div>{this.buildConversionComponentList()}</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
