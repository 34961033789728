import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextField,
} from "@mui/material";
import { Component, ReactNode, SyntheticEvent } from "react";
import { BowlType, bowlTypeNames } from "../../types/enums/bowl-type";
import {
  humanReadableLongBowlTypes,
  LongBowlType,
} from "../../types/enums/long-bowl-type";
import {
  humanReadablePushBrackets,
  PushBracket,
} from "../../types/enums/push-bracket";
import {
  BowlerTypeRule,
  generateDefaultBowlerTypeRule,
} from "../../types/simulator/modules/bowling-attack-modules";
import { BowlerTypeRulesEnumMultiSelector } from "../entity-management/entity-selectors/bowler-type-rules-enum-multi-selector";
import { NumberSelector } from "../entity-management/entity-selectors/number-selector";
import { IconAndTextButton } from "../navigation-bar/icon-and-text-button";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";

interface Props {
  initial: BowlerTypeRule[];
  description: ReactNode;
  onValid: (newValue: BowlerTypeRule[]) => void;
  onInvalid: () => void;
}

interface State {
  expandedPanel: number;
  showDescription: boolean;
}

export class BowlerTypeRuleDisplay extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      expandedPanel: -1,
      showDescription: false,
    };
  }

  private deleteRule(index: number) {
    const updatedRules = this.props.initial;
    updatedRules.splice(index, 1);
    this.onUpdate(updatedRules);
  }

  private toggleDescription() {
    this.setState({ showDescription: !this.state.showDescription });
  }

  private onUpdate(rules: BowlerTypeRule[]) {
    if (this.rulesAreValid(rules)) {
      this.props.onValid(rules);
    } else {
      this.props.onInvalid();
    }
  }

  private rulesAreValid(rules: BowlerTypeRule[]): boolean {
    let valid = true;
    rules.forEach((rule: BowlerTypeRule) => {
      let thisRuleValid =
        rule.name.length > 0 &&
        rule.award >= -1000000 &&
        rule.award <= 1000000 &&
        (rule.bowlTypes.length > 0 || rule.longBowlTypes.length > 0) &&
        rule.pushBrackets.length > 0;
      valid = valid && thisRuleValid;
    });
    return valid;
  }

  private addRule() {
    const updatedRules = this.props.initial;
    updatedRules.push(generateDefaultBowlerTypeRule());
    this.onUpdate(updatedRules);
  }

  private handleRuleNameChange(value: string, index: number) {
    const updatedRules = this.props.initial;
    updatedRules[index].name = value;
    this.onUpdate(updatedRules);
  }

  private handleAccordionChange(index: number, expanded: boolean) {
    this.setState({ expandedPanel: expanded ? index : -1 });
  }

  public render() {
    return (
      <div className="bowler-type-rule-display">
        {this.props.initial.map((rule, index) => {
          return (
            <Accordion
              key={`accordion-${index}`}
              expanded={this.state.expandedPanel === index}
              onChange={(event: SyntheticEvent, newExpanded: boolean) =>
                this.handleAccordionChange(index, newExpanded)
              }
            >
              <AccordionSummary color="red">
                Rule {index + 1}: {rule.name}
              </AccordionSummary>
              <AccordionDetails className="bowler-type-rule-accordion-details">
                <div className="bowler-type-rule-name-field">
                  <div>Name:</div>
                  <TextField
                    value={rule.name === null ? "" : rule.name}
                    onChange={(e) =>
                      this.handleRuleNameChange(e.target.value, index)
                    }
                    placeholder={"Ground name"}
                    variant="standard"
                    disabled={false}
                  />
                </div>

                <BowlerTypeRulesEnumMultiSelector
                  label={"Primary Bowl Types"}
                  enumObject={BowlType}
                  readableValues={bowlTypeNames}
                  initialRules={this.props.initial}
                  ruleIndex={index}
                  attributeName={"bowlTypes"}
                  onUpdate={(rules: BowlerTypeRule[]) => this.onUpdate(rules)}
                  disabled={false}
                />

                <BowlerTypeRulesEnumMultiSelector
                  label={"Secondary Bowl Types"}
                  enumObject={LongBowlType}
                  readableValues={humanReadableLongBowlTypes}
                  initialRules={this.props.initial}
                  ruleIndex={index}
                  attributeName={"longBowlTypes"}
                  onUpdate={(rules: BowlerTypeRule[]) => this.onUpdate(rules)}
                  disabled={false}
                />

                <BowlerTypeRulesEnumMultiSelector
                  label={"Push Brackets"}
                  enumObject={PushBracket}
                  readableValues={humanReadablePushBrackets}
                  initialRules={this.props.initial}
                  ruleIndex={index}
                  attributeName={"pushBrackets"}
                  onUpdate={(rules: BowlerTypeRule[]) => this.onUpdate(rules)}
                  disabled={false}
                />

                <NumberSelector
                  label={"Award"}
                  min={-1000000}
                  max={1000000}
                  step={1}
                  decimalPlaces={0}
                  initial={rule.award}
                  onValid={(valid) => {
                    const currentRules = this.props.initial;
                    currentRules[index].award = valid;
                    this.onUpdate(currentRules);
                  }}
                  onInvalid={() => this.props.onInvalid()}
                  className="simulator-number-selector"
                />

                <IconAndTextButton
                  icon="delete_circle"
                  title={"Delete rule"}
                  disabled={false}
                  onClick={() => this.deleteRule(index)}
                />
              </AccordionDetails>
            </Accordion>
          );
        })}
        <IconAndTextButton
          icon="add_circle"
          title={"Add rule"}
          disabled={false}
          onClick={() => this.addRule()}
        />
        <TooltipIconButton
          icon="help_outline"
          title={"What is this?"}
          disabled={false}
          onClick={() => this.toggleDescription()}
        />
        {this.state.showDescription && this.props.description}
      </div>
    );
  }
}
