import React, { useState } from "react";

import { NumberSelector } from "../entity-management/entity-selectors/number-selector";
import { CreationDialog } from "../my-matches/match-creation-modals/creation-dialog";

type Props = {
  open: boolean;
  onCancel: () => void;
  onProceed: (numberOfQuestions: number) => void;
};

export default function MultiRandomQuestionModal({
  open,
  onCancel,
  onProceed,
}: Props): React.JSX.Element {
  const [numberOfQuestions, setNumberOfQuestions] = useState<number>(3);

  return (
    <CreationDialog
      open={open}
      label={"Create Random Multi Questions"}
      invalid={false}
      disabled={false}
      disabledText={""}
      onCancel={() => onCancel()}
      onProceed={() => onProceed(numberOfQuestions)}
      proceedText="OK"
      colour="#34ebb4"
    >
      <div>
        <NumberSelector
          label="Number of random questions to create"
          textFieldClassName="small-number-selector"
          min={1}
          max={100}
          initial={numberOfQuestions}
          onValid={(numberOfQuestions) =>
            setNumberOfQuestions(numberOfQuestions)
          }
          onInvalid={() => {}}
        />
      </div>
    </CreationDialog>
  );
}
