export interface StoredGameStateOdds {
  matchId: string;
  gameStateId: string;
  simulatorMatchOdds: MatchOdd[];
  simulatorRunsLineOdds: Map<number, RunsLineOdd[]>;
}

export interface RunsLineOdd {
  overs: number;
  price: number;
  numberOfSimulations: number;
}

export interface MatchOdd {
  teamId: string;
  price: number;
  numberOfSimulations: number;
}

export function deserializeGroupedStoredOdds(json: any): {
  [matchId: string]: StoredGameStateOdds[];
} {
  const groupedOdds: { [matchId: string]: StoredGameStateOdds[] } = {};

  Object.keys(json).forEach((matchId) => {
    groupedOdds[matchId] = json[matchId].map((element: any) => ({
      matchId: element.matchId,
      gameStateId: element.gameStateId,
      simulatorMatchOdds: element.simulatorMatchOdds,
      simulatorRunsLineOdds: deserializeRunsLineOddsMap(
        element.simulatorRunsLineOdds
      ),
    }));
  });

  return groupedOdds;
}

function deserializeRunsLineOddsMap(json: any): Map<number, RunsLineOdd[]> {
  if (!json) {
    return null;
  }

  const result: Map<number, RunsLineOdd[]> = new Map();

  Object.keys(json).forEach((key: string) => {
    result.set(Number(key), json[key]);
  });

  return result;
}
