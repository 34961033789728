import { ThemeProvider, createTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Router } from "react-router-dom";
import SockJsClient from "react-stomp";
import { Subscription } from "rxjs";

import {
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  TimeSeriesScale,
  Tooltip,
} from "chart.js";
import { ErrorBoundary } from "react-error-boundary";
import "./App.css";
import ComponentsWrapper from "./ComponentsWrapper";
import {
  ConfirmationDialog,
  ConfirmationDialogProps,
} from "./components/common-components/confirmation-dialog";
import ErrorPage from "./components/error-page";
import { PrivateRoute } from "./types/route-helpers";
import { services } from "./types/services";

const history = services.history;

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  Filler,
  TimeSeriesScale,
  LineController,
  BarController
);

const DEFAULT_CONFIRMATION_PROPS: ConfirmationDialogProps = {
  open: false,
  onProceed: null,
  title: null,
  text: null,
};

const theme = createTheme({
  palette: {
    primary: {
      main: "rgb(67, 80, 175)",
    },
  },
});

export default function App(): React.JSX.Element {
  const [websocketUrl, setWebsocketUrl] = useState<string>(
    `${
      services.httpService.baseUrl
    }/api/full-push-websockets?access_token=${services.keycloakService.getAuthToken()}`
  );
  const [confirmationProps, setConfirmationProps] =
    useState<ConfirmationDialogProps>(DEFAULT_CONFIRMATION_PROPS);

  useEffect(() => {
    const subscriptions: Subscription[] = [];

    subscriptions.push(
      services.webSocketService
        .getWebsocketUrlSubject()
        .subscribe((websocketUrl: string) => setWebsocketUrl(websocketUrl))
    );

    subscriptions.push(
      services.confirmationSubject.subscribe(
        (confirmationProps: ConfirmationDialogProps) =>
          setConfirmationProps(
            confirmationProps === null
              ? DEFAULT_CONFIRMATION_PROPS
              : confirmationProps
          )
      )
    );

    return () =>
      subscriptions.forEach((subscription) => subscription.unsubscribe());
  }, []);

  return (
    <div>
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <ThemeProvider theme={theme}>
          <PrivateRoute>
            <Router history={history}>
              <div className="full-height">
                <ComponentsWrapper />

                <SockJsClient
                  url={websocketUrl}
                  topics={services.webSocketService.topicList}
                  onConnect={() => {
                    console.log("Connected to websocket");
                  }}
                  onDisconnect={() =>
                    console.log("Disconnected from websocket")
                  }
                  onConnectFailure={() => services.keycloakService.refresh()}
                  onMessage={(msg) =>
                    services.webSocketService.handleMessage(msg)
                  }
                  ref={(client) => services.webSocketService.setClient(client)}
                />
                {confirmationProps && (
                  <ConfirmationDialog
                    open={confirmationProps.open}
                    onProceed={confirmationProps.onProceed}
                    title={confirmationProps.title}
                    text={confirmationProps.text}
                  />
                )}
              </div>
            </Router>
          </PrivateRoute>
        </ThemeProvider>
      </ErrorBoundary>
    </div>
  );
}
