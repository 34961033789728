import { Component } from "react";

import { UserPreferences } from "../../types/preferences/preferences";
import {
  GroundStats,
  GroundStatsWrapper,
} from "../../types/stats/ground-stats";
import { NumberSelector } from "../entity-management/entity-selectors/number-selector";
import TooltipIconButton from "../navigation-bar/tooltip-icon-button";
import { NumberComparison } from "../stats-editing-components/number-comparison";
import { PercentsDistributionModalByPush } from "../stats-editing-components/percents-distribution-modal-by-push";
import {
  areStatsEqual,
  getToolTipMessage,
} from "../stats-editing-components/stats-editing-utils";
import StealStatsButton from "../stats-editing-components/steal-stats-button";

import { batchUpdate } from "./ground-page-utils";

interface Props {
  currentStats: GroundStatsWrapper;
  comparedStats: GroundStatsWrapper;
  comparedUserName: string;
  index: number;
  title: string;
  classes: string;
  userPreferences: UserPreferences;
  onUpdate: (updatedStats: GroundStatsWrapper) => void;
}

type ArrayPropertyName =
  | "strikeRateBiasByPush"
  | "wicketBiasByPush"
  | "confidenceByPush";

interface State {
  percentDistributionModalOpen: boolean;
}

export class GroundStrikeRateAndWicketPercentByPush extends Component<
  Props,
  State
> {
  public STATS_TO_STEAL = [
    "strikeRateBiasByPush",
    "wicketBiasByPush",
    "confidenceByPush",
    "percentDistributionBiasDataByPush",
  ];

  private static readonly DEFAULT_STATE = {
    percentDistributionModalOpen: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...GroundStrikeRateAndWicketPercentByPush.DEFAULT_STATE,
    };
  }

  private onClickHandler = () => {
    batchUpdate(
      this.props.currentStats,
      this.props.comparedStats,
      this.STATS_TO_STEAL,
      this.props.onUpdate,
      this.props.index
    );
  };

  private callUpdateFunction(propertyName: ArrayPropertyName, newValue: any) {
    const newArray: number[] =
      this.props.currentStats.groundStats[propertyName];
    newArray[this.props.index] = newValue;
    this.props.onUpdate(
      new GroundStatsWrapper(
        this.props.currentStats.groundStatsId,
        this.props.currentStats.createdBy,
        this.props.currentStats.createdAt,
        this.props.currentStats.name,
        {
          ...this.props.currentStats.groundStats,
          [propertyName]: newArray,
        },
        this.props.currentStats.matchType
      )
    );
  }

  private showPercentsDistributionModal(open: boolean) {
    this.setState({ percentDistributionModalOpen: open });
  }

  public render() {
    return (
      <div className="stats-modal-section">
        <div className={this.props.classes + " stats-modal-section-head"}>
          {this.props.title}
        </div>
        <div>
          <NumberSelector
            className="stats-modal-number-selector"
            textFieldClassName="stats-modal-number-selector-textfield"
            label="Strike Rate"
            min={0}
            max={100000000}
            step={0.01}
            decimalPlaces={2}
            initial={
              this.props.currentStats.groundStats.strikeRateBiasByPush[
                this.props.index
              ]
            }
            onValid={(newValue) =>
              this.callUpdateFunction("strikeRateBiasByPush", newValue)
            }
            onInvalid={(newValue) =>
              this.callUpdateFunction("strikeRateBiasByPush", newValue)
            }
            button={
              <TooltipIconButton
                title={"Percent Distribution"}
                disabled={false}
                onClick={() => this.showPercentsDistributionModal(true)}
                icon={"bar_chart"}
              />
            }
          />
          <NumberSelector
            className="stats-modal-number-selector"
            textFieldClassName="stats-modal-number-selector-textfield"
            label="Wicket Percent"
            min={0}
            max={100000000}
            step={0.01}
            decimalPlaces={2}
            initial={
              this.props.currentStats.groundStats.wicketBiasByPush[
                this.props.index
              ]
            }
            onValid={(newValue) =>
              this.callUpdateFunction("wicketBiasByPush", newValue)
            }
          />
          <NumberSelector
            className="stats-modal-number-selector"
            textFieldClassName="stats-modal-number-selector-textfield"
            label="Confidence"
            min={0}
            max={100000000}
            step={1}
            decimalPlaces={0}
            initial={
              this.props.currentStats.groundStats.confidenceByPush[
                this.props.index
              ]
            }
            onValid={(newValue) =>
              this.callUpdateFunction("confidenceByPush", newValue)
            }
          />
          {!!this.props.comparedStats && (
            <div className="comparison">
              <div className="comparison-title-and-steal-button">
                <span>Compared to {this.props.comparedUserName}</span>
                <StealStatsButton
                  comparedUserName={this.props.comparedUserName}
                  disabled={areStatsEqual(
                    this.props.currentStats,
                    this.props.comparedStats,
                    this.STATS_TO_STEAL,
                    "groundStats",
                    this.props.index
                  )}
                  tooltipMessage={getToolTipMessage(
                    this.props.currentStats,
                    this.props.comparedStats,
                    this.STATS_TO_STEAL,
                    this.props.comparedUserName,
                    "groundStats",
                    this.props.index
                  )}
                  onClickHandler={this.onClickHandler}
                />
              </div>
              <NumberComparison
                label="Strike Rate"
                originalValue={
                  this.props.currentStats.groundStats.strikeRateBiasByPush[
                    this.props.index
                  ]
                }
                comparedValue={
                  this.props.comparedStats.groundStats.strikeRateBiasByPush[
                    this.props.index
                  ]
                }
              />
              <NumberComparison
                label="Wicket Percent"
                originalValue={
                  this.props.currentStats.groundStats.wicketBiasByPush[
                    this.props.index
                  ]
                }
                comparedValue={
                  this.props.comparedStats.groundStats.wicketBiasByPush[
                    this.props.index
                  ]
                }
              />
              <NumberComparison
                label="Confidence"
                originalValue={
                  this.props.currentStats.groundStats.confidenceByPush[
                    this.props.index
                  ]
                }
                comparedValue={
                  this.props.comparedStats.groundStats.confidenceByPush[
                    this.props.index
                  ]
                }
              />
            </div>
          )}
        </div>
        <PercentsDistributionModalByPush
          open={this.state.percentDistributionModalOpen}
          onProceed={(stats: GroundStats) => {
            this.showPercentsDistributionModal(false);
            this.props.onUpdate(
              new GroundStatsWrapper(
                this.props.currentStats.groundStatsId,
                this.props.currentStats.createdBy,
                this.props.currentStats.createdAt,
                this.props.currentStats.name,
                stats,
                this.props.currentStats.matchType
              )
            );
          }}
          onCancel={() => this.showPercentsDistributionModal(false)}
          initialStats={this.props.currentStats.groundStats}
          comparedStats={
            this.props.comparedStats && this.props.comparedStats.groundStats
          }
          comparedUserName={this.props.comparedUserName}
          pushBracketIndex={this.props.index}
          userPreferences={this.props.userPreferences}
        />
      </div>
    );
  }
}
