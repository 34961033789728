import { Component, ReactElement } from "react";
import { Subscription } from "rxjs";

import { ReactComponent as GreyBallSVG } from "../../img/events/bowler-not-selected.svg";
import { GameState } from "../../types/entities/game-state";
import { Match } from "../../types/entities/match";
import { MatchFormat } from "../../types/entities/match-format";
import { Player } from "../../types/entities/player";
import { Squad } from "../../types/entities/squad";
import { Team } from "../../types/entities/team";
import { TeamPlayer } from "../../types/entities/team-player";
import { Direction } from "../../types/enums/direction";
import { MatchRole } from "../../types/enums/match-role";
import { UserPreferences } from "../../types/preferences/preferences";
import { SimulatorRoute } from "../../types/route-helpers";
import { services } from "../../types/services";
import { SimulationResult } from "../../types/simulator/simulation-result";
import { PlayerStatsWrapper } from "../../types/stats/player-stats";
import { UUID } from "../../types/uuid";
import { getTitleColour } from "../match-page/game-state-display/tsbc";
import { IconAndTextButton } from "../navigation-bar/icon-and-text-button";

import { AddPlayerModal } from "./add-player-modal";
import { BowlerPreferenceModal } from "./bowler-preference-modal";
import { DraggableTable } from "./draggable-table";
import { PlayerBattingSimulationDisplay } from "./player-batting-simulation-display";
import { PlayerBowlingSimulationDisplay } from "./player-bowling-simulation-display";
import { PlayerInningsProgressionModal } from "./player-innings-progression-modal";
import { PlayerStatsModal } from "./player-stats-modal";
import { PlayerTableRowButtons } from "./player-table-row-buttons";
import { PlayerTableRowPlayerData } from "./player-table-row-player-data";
import { PlayerTableRowSimulationLoadingData } from "./player-table-row-simulation-loading-data";

interface Props {
  team: Team;
  teamNumber: number;
  match: Match;
  squad: Squad;
  squad1: Squad;
  squad2: Squad;
  team1: Team;
  team2: Team;
  matchFormat: MatchFormat;
  gameState: GameState;
  simulationResult: SimulationResult;
  comparedResult: SimulationResult;
  comparedUserName: string;
  playerStats: Map<string, PlayerStatsWrapper>;
  onSquadUpdate: () => Promise<void>;
  fromThisPoint: boolean;
  userPreferences: UserPreferences;
}

interface State {
  battingStatsModalOpen: boolean;
  addPlayerModalOpen: boolean;
  statsModalOpen: boolean;
  playerInningsProgressionModalOpen: boolean;
  bowlerPreferenceModalOpen: boolean;
  currentPlayerOrder: number;
  simulationLoading: boolean;
}

export class EditSquadDisplay extends Component<Props, State> {
  private subscriptions: Subscription[] = [];

  public constructor(props) {
    super(props);
    this.state = {
      battingStatsModalOpen: false,
      addPlayerModalOpen: false,
      statsModalOpen: false,
      playerInningsProgressionModalOpen: false,
      bowlerPreferenceModalOpen: false,
      currentPlayerOrder: null,
      simulationLoading: false,
    };
  }

  componentDidMount(): void {
    this.subscriptions.push(
      services.simulationService.loadingSubject.subscribe((simulationLoading) =>
        this.setState({ simulationLoading })
      )
    );
  }

  componentWillUnmount(): void {
    this.subscriptions.forEach((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }

  private movePlayer(
    playerId: UUID,
    direction: Direction,
    steps: number,
    onComplete: () => void
  ) {
    services.currentGameService
      .movePlayer(playerId, direction, steps, this.props.team.teamId)
      .then(() => this.props.onSquadUpdate().then(() => onComplete()));
  }

  private removePlayer(playerId: UUID, teamId: UUID) {
    services.currentGameService
      .removePlayer(playerId, teamId)
      .then(() => this.props.onSquadUpdate());
  }

  private addPlayer(player: Player) {
    services.currentGameService
      .addPlayer(player.playerId, this.props.team.teamId)
      .then(() => this.props.onSquadUpdate());
  }

  private toggleBowler(playerStats: PlayerStatsWrapper) {
    const newIsBowlerValue = !playerStats.playerStats.bowler;
    services.playerStatsService.updatePlayerStats({
      ...playerStats,
      playerStats: {
        ...playerStats.playerStats,
        bowler: newIsBowlerValue,
        desiredBalls: newIsBowlerValue
          ? playerStats.playerStats.desiredBalls
          : 0,
      },
    });
  }

  private showStatsModal(order: number): void {
    this.setState({ statsModalOpen: true, currentPlayerOrder: order });
  }

  private showInningsProgressionModal(order: number): void {
    this.setState({
      playerInningsProgressionModalOpen: true,
      currentPlayerOrder: order,
    });
  }

  private getTeamPlayer(player: Player) {
    return (
      !!this.props.gameState &&
      !!this.props.gameState.squads &&
      !!this.props.gameState.squads[this.props.teamNumber - 1] &&
      this.props.gameState.squads[this.props.teamNumber - 1].find(
        (tp) => tp.playerId.value === player.playerId.value
      )
    );
  }

  private getPlayerStats(teamPlayer: TeamPlayer) {
    return (
      !!this.props.playerStats &&
      !!teamPlayer &&
      this.props.playerStats.get(teamPlayer.playerId.value)
    );
  }

  private validPlayer(
    teamPlayer: TeamPlayer,
    playerStats: PlayerStatsWrapper
  ): boolean {
    return (
      !!teamPlayer &&
      !!playerStats &&
      this.props.team &&
      teamPlayer.matchRole !== MatchRole.REMOVED
    );
  }

  private buildItems(): ReactElement[] {
    return [...this.props.squad.players]
      .sort((a, b) => {
        const teamPlayerA: TeamPlayer = this.getTeamPlayer(a);
        const teamPlayerB: TeamPlayer = this.getTeamPlayer(b);
        return !!teamPlayerA && !!teamPlayerB
          ? teamPlayerA.battingOrder - teamPlayerB.battingOrder
          : -1;
      })
      .map((player, order) => {
        const teamPlayer: TeamPlayer = this.getTeamPlayer(player);
        const playerStats: PlayerStatsWrapper = this.getPlayerStats(teamPlayer);
        return (
          this.validPlayer(teamPlayer, playerStats) && (
            <div
              key={"sim-stats-" + player.playerId.value}
              className="squad-page-player-container"
              style={{
                backgroundColor: order % 2 === 0 ? "#eeeeee" : "#f8f8ff",
              }}
            >
              <PlayerTableRowPlayerData
                player={player}
                teamPlayer={teamPlayer}
              />
              <div className="squad-page-player-sim-stats-container">
                <PlayerBattingSimulationDisplay
                  player={player}
                  simulationResult={this.props.simulationResult}
                  comparedResult={this.props.comparedResult}
                  matchFormat={this.props.matchFormat}
                  gameState={this.props.gameState}
                  team={this.props.teamNumber}
                  fromThisPoint={this.props.fromThisPoint}
                />
                <PlayerBowlingSimulationDisplay
                  player={player}
                  simulationResult={this.props.simulationResult}
                  comparedResult={this.props.comparedResult}
                  matchFormat={this.props.matchFormat}
                  gameState={this.props.gameState}
                  team={this.props.teamNumber}
                  fromThisPoint={this.props.fromThisPoint}
                />
                <PlayerTableRowSimulationLoadingData
                  simulationResult={this.props.simulationResult}
                  simulationLoading={this.state.simulationLoading}
                />
              </div>
              <PlayerTableRowButtons
                gameState={this.props.gameState}
                player={player}
                teamPlayer={teamPlayer}
                order={order}
                team={this.props.team}
                squad={this.props.squad}
                playerStats={playerStats}
                removePlayer={(playerId: UUID, teamId: UUID) =>
                  this.removePlayer(playerId, teamId)
                }
                movePlayer={(
                  playerId: UUID,
                  direction: Direction,
                  steps: number
                ) => this.movePlayer(playerId, direction, steps, () => {})}
                toggleBowler={(playerStats: PlayerStatsWrapper) =>
                  this.toggleBowler(playerStats)
                }
                showStatsModal={(order: number) => this.showStatsModal(order)}
                showInningsProgressionModal={(order: number) =>
                  this.showInningsProgressionModal(order)
                }
              />
            </div>
          )
        );
      });
  }

  public render() {
    const backgroundColor = this.props.team.colour || "#888888";
    return (
      <div className="squad-container">
        <div
          style={{
            background: backgroundColor,
            color: getTitleColour(backgroundColor),
          }}
          className="squad-title"
        >
          {this.props.team && this.props.team.name}
        </div>

        <DraggableTable
          items={this.buildItems()}
          onMove={(playerId, direction, steps, onComplete) =>
            this.movePlayer(playerId, direction, steps, onComplete)
          }
        />

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            gap: "1em",
            padding: "1em",
          }}
        >
          <IconAndTextButton
            title="Add Player"
            onClick={() => this.setState({ addPlayerModalOpen: true })}
            icon="add"
          />
          <SimulatorRoute>
            <IconAndTextButton
              title="Bowler Settings"
              onClick={() => this.setState({ bowlerPreferenceModalOpen: true })}
              icon={<GreyBallSVG className="tooltip-icon-button" />}
            />
          </SimulatorRoute>
        </div>

        <AddPlayerModal
          open={this.state.addPlayerModalOpen}
          onCancel={() => this.setState({ addPlayerModalOpen: false })}
          onProceed={(player: Player) =>
            this.setState({ addPlayerModalOpen: false }, () =>
              this.addPlayer(player)
            )
          }
          squad1={this.props.squad1}
          squad2={this.props.squad2}
          gameState={this.props.gameState}
        />

        <PlayerStatsModal
          open={this.state.statsModalOpen}
          onCancel={() => this.setState({ statsModalOpen: false })}
          onProceed={() => this.setState({ statsModalOpen: false })}
          squad={this.props.squad}
          team={this.props.team}
          match={this.props.match}
          teamNumber={this.props.teamNumber}
          gameState={this.props.gameState}
          matchFormat={this.props.matchFormat}
          initialPlayer={this.state.currentPlayerOrder}
          simulationResult={this.props.simulationResult}
          comparedResult={this.props.comparedResult}
          fromThisPoint={this.props.fromThisPoint}
          comparedUserName={this.props.comparedUserName}
          userPreferences={this.props.userPreferences}
        />

        <PlayerInningsProgressionModal
          open={this.state.playerInningsProgressionModalOpen}
          onProceed={() =>
            this.setState({ playerInningsProgressionModalOpen: false })
          }
          squad={this.props.squad}
          team1={this.props.team1}
          team2={this.props.team2}
          match={this.props.match}
          teamNumber={this.props.teamNumber}
          gameState={this.props.gameState}
          matchFormat={this.props.matchFormat}
          initialPlayer={this.state.currentPlayerOrder}
          simulationResult={this.props.simulationResult}
          comparedResult={this.props.comparedResult}
        />

        <BowlerPreferenceModal
          open={this.state.bowlerPreferenceModalOpen}
          onProceed={() => this.setState({ bowlerPreferenceModalOpen: false })}
          squad={this.props.squad}
          team={this.props.team}
          match={this.props.match}
          teamNumber={this.props.teamNumber}
          gameState={this.props.gameState}
          matchFormat={this.props.matchFormat}
          simulationResult={this.props.simulationResult}
          comparedResult={this.props.comparedResult}
          comparedUserName={this.props.comparedUserName}
          playerStats={this.props.playerStats}
          userPreferences={this.props.userPreferences}
        />
      </div>
    );
  }
}
