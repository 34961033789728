import { Component } from "react";

import { GroundStatsWrapper } from "../../types/stats/ground-stats";
import { propsEqual } from "../component-utils";
import { NumberSelector } from "../entity-management/entity-selectors/number-selector";
import { NumberComparison } from "../stats-editing-components/number-comparison";
import {
  areStatsEqual,
  getToolTipMessage,
} from "../stats-editing-components/stats-editing-utils";
import StealStatsButton from "../stats-editing-components/steal-stats-button";

import { batchUpdate } from "./ground-page-utils";

interface Props {
  currentStats: GroundStatsWrapper;
  comparedStats: GroundStatsWrapper;
  comparedUserName: string;
  onUpdate: (updatedStats: GroundStatsWrapper) => void;
}

interface State {
  swingUpToBallValid: boolean;
  srValid: boolean;
  wpcValid: boolean;
}

export class GroundSwingStats extends Component<Props, State> {
  public STATS_TO_STEAL = [
    "swingUpToBall",
    "swingStrikeRateBiasUpToBall",
    "swingWicketBiasUpToBall",
  ];

  private static readonly DEFAULT_STATE = {
    swingUpToBallValid: true,
    srValid: true,
    wpcValid: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      ...GroundSwingStats.DEFAULT_STATE,
    };
  }

  componentDidMount(): void {
    this.updateState();
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (!propsEqual(prevProps, this.props)) {
      this.updateState();
    }
  }

  private onClickHandler = () => {
    batchUpdate(
      this.props.currentStats,
      this.props.comparedStats,
      this.STATS_TO_STEAL,
      this.props.onUpdate
    );
  };

  private updateState() {
    this.setState({
      ...GroundSwingStats.DEFAULT_STATE,
    });
  }

  private callUpdateFunction(propertyName: string, newValue: any) {
    this.props.onUpdate(
      new GroundStatsWrapper(
        this.props.currentStats.groundStatsId,
        this.props.currentStats.createdBy,
        this.props.currentStats.createdAt,
        this.props.currentStats.name,
        {
          ...this.props.currentStats.groundStats,
          [propertyName]: newValue,
        },
        this.props.currentStats.matchType
      )
    );
  }

  public render() {
    return (
      <div className="stats-modal-section">
        <div className="stats-modal-section-head">Swing up to Ball</div>
        <div className="ground-stats-modal-number-selectors">
          <NumberSelector
            className="stats-modal-number-selector"
            label="Up to Ball"
            min={0}
            max={100000000000000}
            step={1}
            initial={this.props.currentStats.groundStats.swingUpToBall}
            onValid={(newValue) =>
              this.setState(
                {
                  swingUpToBallValid: true,
                },
                () => this.callUpdateFunction("swingUpToBall", newValue)
              )
            }
            onInvalid={(newValue) =>
              this.setState(
                {
                  swingUpToBallValid: false,
                },
                () => this.callUpdateFunction("swingUpToBall", newValue)
              )
            }
          />
          <NumberSelector
            className="stats-modal-number-selector"
            label="Strike Rate Bias"
            min={0}
            max={100000000000000}
            step={0.01}
            decimalPlaces={2}
            initial={
              this.props.currentStats.groundStats.swingStrikeRateBiasUpToBall
            }
            onValid={(newValue) =>
              this.setState(
                {
                  srValid: true,
                },
                () =>
                  this.callUpdateFunction(
                    "swingStrikeRateBiasUpToBall",
                    newValue
                  )
              )
            }
            onInvalid={(newValue) =>
              this.setState(
                {
                  srValid: false,
                },
                () =>
                  this.callUpdateFunction(
                    "swingStrikeRateBiasUpToBall",
                    newValue
                  )
              )
            }
          />
          <NumberSelector
            className="stats-modal-number-selector"
            label="Wicket Bias"
            min={0}
            max={100000000000000}
            step={0.01}
            decimalPlaces={2}
            initial={
              this.props.currentStats.groundStats.swingWicketBiasUpToBall
            }
            onValid={(newValue) =>
              this.setState(
                {
                  wpcValid: true,
                },
                () =>
                  this.callUpdateFunction("swingWicketBiasUpToBall", newValue)
              )
            }
            onInvalid={(newValue) =>
              this.setState(
                {
                  wpcValid: false,
                },
                () =>
                  this.callUpdateFunction("swingWicketBiasUpToBall", newValue)
              )
            }
          />
          {!!this.props.comparedStats && (
            <div className="comparison">
              <div className="comparison-title-and-steal-button">
                <span>Compared to {this.props.comparedUserName}</span>
                <StealStatsButton
                  comparedUserName={this.props.comparedUserName}
                  disabled={areStatsEqual(
                    this.props.currentStats,
                    this.props.comparedStats,
                    this.STATS_TO_STEAL,
                    "groundStats"
                  )}
                  tooltipMessage={getToolTipMessage(
                    this.props.currentStats,
                    this.props.comparedStats,
                    this.STATS_TO_STEAL,
                    this.props.comparedUserName,
                    "groundStats"
                  )}
                  onClickHandler={this.onClickHandler}
                />
              </div>
              <NumberComparison
                label="Up to Ball"
                originalValue={
                  this.props.currentStats.groundStats.swingUpToBall
                }
                comparedValue={
                  this.props.comparedStats.groundStats.swingUpToBall
                }
              />
              <NumberComparison
                label="Strike Rate Bias"
                originalValue={
                  this.props.currentStats.groundStats
                    .swingStrikeRateBiasUpToBall
                }
                comparedValue={
                  this.props.comparedStats.groundStats
                    .swingStrikeRateBiasUpToBall
                }
              />
              <NumberComparison
                label="Wicket Bias"
                originalValue={
                  this.props.currentStats.groundStats.swingWicketBiasUpToBall
                }
                comparedValue={
                  this.props.comparedStats.groundStats.swingWicketBiasUpToBall
                }
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
